import * as z from 'zod';

import { LosId } from '../../BrandedIds';
import { zodBrandedString } from '../../utils';
import {
  Checkbox,
  DateString,
  InterestRate,
  NonNegativeFloat,
  NonNegativeInteger,
  NonNegativeMonetaryValue,
} from '../fields';
import { InsuranceFields } from './createLoanFields/InsuranceFields';
import { MailingAddress } from './createLoanFields/MailingAddressFields';
import { MortgageInsuranceFields } from './createLoanFields/MortgageInsuranceFields';
import { TaxFields } from './createLoanFields/TaxFields';
import { AddressFields } from './sharedFields/AddressFields';

export const ConstructionToPermanentRow = z
  .object({
    loanId: zodBrandedString<LosId>(),
    constructionLoanModificationEffectiveDate: DateString,
    loanTermMonths: NonNegativeInteger,
    maturityDate: DateString,
    firstPaymentPrincipalAndInterest: NonNegativeMonetaryValue,
    modifiedEscrowBalance: NonNegativeMonetaryValue.optional(),
    monthlyPaymentEscrow: NonNegativeMonetaryValue,
    modifiedPrincipalAmount: NonNegativeMonetaryValue.optional(),
    modifiedPrincipalPrepayment: NonNegativeMonetaryValue.optional(),
    modifiedInterestRatePercent: NonNegativeFloat.optional(),
    modifiedInterestRateDecimal: InterestRate.optional(),
    modifiedAmortizationType: z.literal('fixed').optional(),

    initialMortgageInsuranceAmount: NonNegativeMonetaryValue.optional(),
    monthlyPaymentMi: NonNegativeMonetaryValue.optional(), // TODO deprecate this from FF and the API
    isEscrowed: Checkbox.optional(),
  })
  .merge(MailingAddress)
  .merge(AddressFields.partial())
  .merge(TaxFields)
  .merge(MortgageInsuranceFields)
  .merge(InsuranceFields);

export type ConstructionToPermanentRow = z.infer<typeof ConstructionToPermanentRow>;

export const CONSTRUCTION_TO_PERMANENT_ROW_REQUIRED_FIELDS = Object.keys(ConstructionToPermanentRow.shape).filter(
  (key) => !ConstructionToPermanentRow.shape[key as keyof typeof ConstructionToPermanentRow.shape].isOptional(),
);
