import * as z from 'zod';

import { PaymentAddress } from '../Address';
import { EscrowSettingId, PropertyId } from '../BrandedIds';
import { OptionalPhoneNumber } from '../Phone';
import { DueMonthDay, zodBrandedUuid, zodDateOrString } from '../utils/Zod';
import { OptionalEmailString } from '../validations/email';
import { NonNegativeMonetaryValue } from '../validations/fields';

export const PaymentCadence = z.enum(['ANNUAL', 'MONTHLY', 'SEMI-ANNUAL', 'QUARTERLY', 'UPFRONT']);

export type PaymentCadence = z.infer<typeof PaymentCadence>;

export const PaymentCadenceEnum = z.preprocess((val) => {
  if (typeof val === 'string') {
    return val.toUpperCase();
  }
  return val;
}, PaymentCadence);

export type PaymentCadenceEnum = z.infer<typeof PaymentCadenceEnum>;

export const EscrowSettingStatus = z.enum(['ACTIVE', 'INACTIVE']);

export const BaseEscrowSetting = z.object({
  id: zodBrandedUuid<EscrowSettingId>(),
  propertyId: zodBrandedUuid<PropertyId>(),
  escrowed: z.boolean(),
  status: EscrowSettingStatus,
  amount: NonNegativeMonetaryValue,
  payee: z.object({
    name: z.string(),
    address: PaymentAddress.optional(),
  }),
  propertyIndex: z.number().optional(), // Used to pair with the right property
  updatedAt: zodDateOrString,
});

export const TaxEscrowType = z.enum(['COUNTY_TAX', 'CITY_TAX', 'SCHOOL_TAX', 'OTHER_TAX']);

export type TaxEscrowType = z.infer<typeof TaxEscrowType>;

export const TaxEscrowSetting = BaseEscrowSetting.extend({
  type: z.literal('taxEscrowSetting'),
  dueDates: z.array(DueMonthDay).optional(),
  taxType: TaxEscrowType,
  apn: z.string().optional(),
});

export type TaxEscrowSetting = z.infer<typeof TaxEscrowSetting>;

export const InsuranceEscrowSetting = BaseEscrowSetting.extend({
  type: z.literal('insuranceEscrowSetting'),
  insuranceName: z.string().optional(),
  agentName: z.string().optional(),
  agentEmail: OptionalEmailString,
  agentPhone: OptionalPhoneNumber,
  policyNumber: z.string().optional(),
  policyStartDate: zodDateOrString.optional(),
  policyEndDate: zodDateOrString,
});

export type InsuranceEscrowSetting = z.infer<typeof InsuranceEscrowSetting>;

export const TaxEscrowSettingInput = TaxEscrowSetting.omit({ propertyId: true, updatedAt: true }).extend({
  county: z.string().optional(),
});

export type TaxEscrowSettingInput = z.infer<typeof TaxEscrowSettingInput>;

export const InsuranceEscrowSettingInput = InsuranceEscrowSetting.omit({ propertyId: true, updatedAt: true });

export type InsuranceEscrowSettingInput = z.infer<typeof InsuranceEscrowSettingInput>;

export const MortgageInsuranceType = z.enum(['PMI', 'UFMIP', 'FHA']);

export type MortgageInsuranceType = z.infer<typeof MortgageInsuranceType>;

export const MortgageInsuranceTypeEnum = z.preprocess((val) => {
  if (typeof val === 'string') {
    return val.toUpperCase();
  }
  return val;
}, MortgageInsuranceType);

export type MortgageInsuranceTypeEnum = z.infer<typeof MortgageInsuranceTypeEnum>;

export const MortgageInsuranceFHAProgramType = z.enum(['203B', '203K', '234C']);

export type MortgageInsuranceFHAProgramType = z.infer<typeof MortgageInsuranceFHAProgramType>;

export const MortgageInsuranceFHAProgramTypeEnum = z.preprocess((val) => {
  if (typeof val === 'string') {
    return val.toUpperCase();
  }
  return val;
}, MortgageInsuranceFHAProgramType);

export type MortgageInsuranceFHAProgramTypeEnum = z.infer<typeof MortgageInsuranceFHAProgramTypeEnum>;

export const MortgageInsuranceRenewalType = z.enum(['CONSTANT', 'DECLINING']);

export type MortgageInsuranceRenewalType = z.infer<typeof MortgageInsuranceRenewalType>;

export const MortgageInsuranceRenewalTypeEnum = z.preprocess((val) => {
  if (typeof val === 'string') {
    return val.toUpperCase();
  }
  return val;
}, MortgageInsuranceRenewalType);

export type MortgageInsuranceRenewalTypeEnum = z.infer<typeof MortgageInsuranceRenewalTypeEnum>;

export const MortgageInsuranceEscrowSetting = BaseEscrowSetting.extend({
  type: z.literal('mortgageInsuranceEscrowSetting'),
  mortgageInsuranceType: MortgageInsuranceType,
  fhaProgramType: MortgageInsuranceFHAProgramType.optional(),
  startDate: zodDateOrString,
  midpointDate: zodDateOrString.optional(),
  paymentCadence: PaymentCadence,
  certificateId: z.string(),
  poolCertificateNumber: z.string().optional(),
  coveragePercent: z.number().nonnegative(), // Number between 0 and 1
  isRefundable: z.boolean(),
  isFinanced: z.boolean().optional(),
  renewalType: MortgageInsuranceRenewalType,
});

export type MortgageInsuranceEscrowSetting = z.infer<typeof MortgageInsuranceEscrowSetting>;

export const EscrowSetting = z.discriminatedUnion('type', [
  TaxEscrowSetting,
  InsuranceEscrowSetting,
  MortgageInsuranceEscrowSetting,
]);

export type EscrowSetting = z.infer<typeof EscrowSetting>;

export const MortgageInsuranceSettingInput = MortgageInsuranceEscrowSetting.omit({ propertyId: true, updatedAt: true });

export type MortgageInsuranceSettingInput = z.infer<typeof MortgageInsuranceSettingInput>;

export const EscrowSettingInput = z.discriminatedUnion('type', [
  TaxEscrowSettingInput,
  InsuranceEscrowSettingInput,
  MortgageInsuranceSettingInput,
]);

export type EscrowSettingInput = z.infer<typeof EscrowSettingInput>;
