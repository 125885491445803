import { camelCase } from 'lodash';
import { CamelCase } from 'type-fest';
import { z } from 'zod';

import { AmortizationType, BuydownType, LoanInvestorName, ProductType } from '../loan/Loan';
import { AutoPaymentType, ConstructionStatus, EmailStatus, LoanReportingPaymentStatus } from '../loan/LoanReporting';
import { LoanStatus } from '../loan/LoanStatus';
import { NonPerformingReportStatus } from '../loan/NonPerformance';
import { WorkoutPlanType } from '../loan/WorkoutPlan';
import { StateAbbreviation } from '../validations/fields';

// Transform the LoanReporting interface so that all the enums are enforced as camelCase
export const LoanReportingApi = z.object({
  id: z.string(),
  loanId: z.string(),
  losId: z.string(),
  companyId: z.string(),

  // Additional ids for loan
  poolId: z.string().optional(),
  previousServicerId: z.string().optional(),
  capitalPartnerId: z.string().optional(),
  fullId: z.string().optional(),
  otherId1: z.string().optional(),
  otherId2: z.string().optional(),
  otherId3: z.string().optional(),
  ownerId: z.string().optional(),

  name: z.string().describe('Primary borrower last name, first name'),
  address: z.string(),
  addressState: StateAbbreviation,
  status: LoanStatus,
  nonPerformingStatus: NonPerformingReportStatus.transform((val) => camelCase(val) as CamelCase<typeof val>).pipe(
    z.string(),
  ),
  flagged: z.boolean(),
  amortizationType: AmortizationType,
  productType: ProductType,
  productDescription: z.string(),
  interestRate: z.number().describe('Represented as a decimal. A 5% interest rate will be returned at 0.05'),
  buydownRate: z.number().optional(),
  monthlyInterestRate: z.number(),
  loanCreatedAt: z.string().datetime(),
  hasInterestReserve: z.boolean(),
  buydownType: BuydownType.optional(),
  servicer: z.string().optional().describe('Name of the servicer if loan is pendingTransfer or transferred'),
  transferDate: z.string().datetime().optional(),
  goodbyeEmailStatus: EmailStatus.transform((val) => camelCase(val) as CamelCase<typeof val>).pipe(z.string()),
  mortgageeEmailStatus: EmailStatus.transform((val) => camelCase(val) as CamelCase<typeof val>).pipe(z.string()),
  autoPaymentType: AutoPaymentType.transform((val) => camelCase(val) as CamelCase<typeof val>).pipe(z.string()),
  autoPaymentDayOfMonth: z.number(),
  paymentStatus: LoanReportingPaymentStatus.transform((val) => camelCase(val) as CamelCase<typeof val>).pipe(
    z.string(),
  ),
  paymentFrequency: z.number(),
  lastPaymentDate: z.string().datetime().optional(),
  nextPaymentDate: z.string().datetime().optional(),
  lastFulfilledPaymentDate: z.string().datetime().optional(),
  paidOffDate: z.string().datetime().optional(),
  daysPastDue: z.number(),
  daysPastGrace: z.number(),
  paystring: z.string(),
  monthlyPaymentAmount: z.number(),
  firstPaymentDueDate: z.string().datetime(),
  fundingDate: z.string().datetime(),
  originalPrincipalBalance: z.number(),
  outstandingPrincipalBalance: z.number(),
  principalPrePaymentBalance: z.number(),
  escrowBalance: z.number(),
  suspenseBalance: z.number(),
  reserveBalance: z.number(),
  maturityDate: z.string().datetime().optional(),
  totalAmountDue: z.number(),
  expectAnyPayment: z.boolean(),
  firstUnfulfilledPaymentDate: z.string().datetime().optional(),
  totalAmountCollected: z.number(),
  hasBorrowerLoggedIn: z.boolean(),
  hasBorrowerConnectedAccount: z.boolean(),
  totalCommunicationsSent: z.number(),
  constructionStatus: ConstructionStatus.transform((val) => camelCase(val) as CamelCase<typeof val>).pipe(z.string()),
  entityName: z.string().optional().describe('Name of the entity on the loan'),
  workoutPlanType: WorkoutPlanType.or(z.literal('none'))
    .transform((val) => camelCase(val) as CamelCase<typeof val>)
    .pipe(z.string()),
  drawsEnabled: z.boolean(),
  remainingCredit: z.number(),
  investorName: LoanInvestorName.transform((val) => camelCase(val) as CamelCase<typeof val>)
    .pipe(z.string())
    .optional(),
  pointOfContactId: z.string().optional(),
});
export type LoanReportingApi = z.infer<typeof LoanReportingApi>;
