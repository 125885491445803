import * as z from 'zod';

import { AuthorizedContactFields } from './AuthorizedContactFields';
import { CoborrowerFields } from './BorrowerFields';
import { InsuranceFields, MultipleAddressesInsuranceFields } from './InsuranceFields';
import { MortgageInsuranceFields } from './MortgageInsuranceFields';
import { RemittanceFields } from './RemittanceFields';
import { MultipleAddressesTaxFields, TaxFields } from './TaxFields';

// By doing the merge here and only exporting a single type
// it appears to get rid of the following TS error:
//
// Recursive type: Type instantiation is excessively deep and possibly infinite.

export const CreateLoanMultipleAddressFields = z.object({
  ...MultipleAddressesInsuranceFields.shape,
  ...MultipleAddressesTaxFields.shape,
});
export type CreateLoanMultipleAddressFields = z.infer<typeof CreateLoanMultipleAddressFields>;

export const CreateLoanFields = z.object({
  ...MortgageInsuranceFields.shape,
  ...CoborrowerFields.shape,
  ...AuthorizedContactFields.shape,
  ...InsuranceFields.shape,
  ...RemittanceFields.shape,
  ...TaxFields.shape,
});
export type CreateLoanFields = z.infer<typeof CreateLoanFields>;
