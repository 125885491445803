import { blacklist } from 'mailchecker'; // This lib is well maintained and includes huge list of known emails to throw
import * as parser from 'smtp-address-parser';
import { z } from 'zod';

const EMAIL_STRING_ERROR_MESSAGE = 'Invalid email provided.';

// use EmailString for required email field
export const EmailString = z.string().refine(
  (val?: string) => {
    if (val == null) return true;
    return isValidEmail(val);
  },
  {
    message: EMAIL_STRING_ERROR_MESSAGE,
  },
);

// StrictEmailString does not bypass any blacklists
// TODO remove this once we've cleaned up the database
export const StrictEmailString = z.string().refine(
  (val?: string) => {
    if (val == null) return true;
    return isValidEmail(val, { useLocalPartBlacklist: true });
  },
  {
    message: EMAIL_STRING_ERROR_MESSAGE,
  },
);

export const OptionalEmailString = z
  .string()
  .optional()
  .refine(
    (val?: string) => {
      if (val == null || val.length === 0) return true;
      return isValidEmail(val);
    },
    {
      message: EMAIL_STRING_ERROR_MESSAGE,
    },
  );
export type OptionalEmailString = z.infer<typeof OptionalEmailString>;

// Usio caps an email address at 39 characters
export const UsioEmailString = z
  .string()
  .max(39)
  .transform((value) => value.toLowerCase())
  .refine(
    (val?: string) => {
      if (val == null) return true;
      return isValidEmail(val);
    },
    {
      message: EMAIL_STRING_ERROR_MESSAGE,
    },
  );

interface IsValidEmailOptions {
  useLocalPartBlacklist?: boolean;
  useDomainBlacklist?: boolean;
  useWholeEmailBlacklist?: boolean;
}

/**
 * Validates an email address first by parsing according to RFC 5321 and then
 * checking against a list of known invalid email domains (from mailchecker)
 * and local parts (defined by us).
 *
 * NB using regex to validate email addresses is fraught, so we're using a real
 * parser here. We're bypassing mailchecker's regex-based validity check and
 * manually checking domains against their blacklist because their own logic for
 * testing against the blacklist doesn't properly handle quoted local parts with
 * @ symbols in them (which is within spec).
 */
export const isValidEmail = (
  email: string,
  {
    useLocalPartBlacklist = false, // TODO remove this once we've cleaned up the database
    useDomainBlacklist = true,
    useWholeEmailBlacklist = true,
  }: IsValidEmailOptions = {},
): boolean => {
  const parsed = parseEmail(email);

  if (parsed == null) return false;

  if (useDomainBlacklist && BLACKLISTED_DOMAINS.has(getEmailDomainPart(parsed))) {
    return false;
  }

  if (useLocalPartBlacklist && BLACKLISTED_LOCAL_PARTS.has(getEmailLocalPart(parsed))) {
    return false;
  }

  if (useWholeEmailBlacklist && BLACKLISTED_WHOLE_EMAILS.has(email)) {
    return false;
  }

  return true;
};

const BLACKLISTED_DOMAINS: ReadonlySet<string> = new Set(blacklist());
const BLACKLISTED_LOCAL_PARTS: ReadonlySet<string> = new Set(['test', 'noemail']);
const BLACKLISTED_WHOLE_EMAILS: ReadonlySet<string> = new Set(['noemail@gmail.com']);

export function getEmailDomainPart(email: string): string | undefined;
export function getEmailDomainPart(email: ParsedEmail): string;
export function getEmailDomainPart(email: string | ParsedEmail): string | undefined {
  const parsed = typeof email === 'string' ? parseEmail(email) : email;
  return parsed?.domainPart.DomainName;
}

export function getEmailLocalPart(email: string): string | undefined;
export function getEmailLocalPart(email: ParsedEmail): string;
export function getEmailLocalPart(email: string | ParsedEmail): string | undefined {
  const parsed = typeof email === 'string' ? parseEmail(email) : email;
  if (parsed == null) return undefined;

  if ('QuotedString' in parsed.localPart) {
    return parser.canonicalize_quoted_string(parsed.localPart.QuotedString);
  }

  return parser.normalize_dot_string(parsed.localPart.DotString);
}

interface ParsedEmail {
  localPart: { QuotedString: string } | { DotString: string };
  domainPart: { DomainName: string };
}

function parseEmail(email: string): ParsedEmail | undefined {
  try {
    return parser.parse(email);
  } catch {
    return undefined;
  }
}
