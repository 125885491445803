import { isEmpty, isUndefined } from 'lodash';
import * as z from 'zod';

import { StrictAddress } from '../../Address';
import { LosId } from '../../BrandedIds';
import { OptionalPhoneNumber } from '../../Phone';
import { typedSafeParse, zodBrandedString } from '../../utils';
import { CamelizeEnum } from '../../zod';
import { OptionalEmailString } from '../email';
import {
  AddressLine,
  Checkbox,
  DateString,
  Float,
  NonNegativeFloat,
  NonNegativeInteger,
  NonNegativeMonetaryValue,
  SocialSecurityNumber,
  StateAbbreviation,
  Zipcode,
} from '../fields';
import { unsafeCreateCastedSchema } from '../utils';
import { LoanPurposeInput, OccupancyTypeInput } from './CreateLoanRow';
import { AddressFloodFields } from './sharedFields/AddressFields';

const ProductType = z.enum(['conv', 'va', 'fha', 'usda', 'pih', 'bridge', 'businessPurpose', 'jumbo']);

export const hasPrimaryBorrowerAddressFields = (data: z.infer<typeof PrimaryBorrowerAddressSchema>) =>
  [
    data.primaryBorrowerMailingAddressLine1,
    data.primaryBorrowerMailingAddressLine2,
    data.primaryBorrowerMailingAddressLine3,
    data.primaryBorrowerMailingAddressLine4,
    data.primaryBorrowerMailingAddressLocality,
    data.primaryBorrowerMailingAddressPostcode,
    data.primaryBorrowerMailingAddressRegion,
  ].some((value) => !isEmpty(value));

const PrimaryBorrowerAddressSchema = z
  .object({
    primaryBorrowerMailingAddressLine1: AddressLine.optional(),
    primaryBorrowerMailingAddressLine2: AddressLine.optional(),
    primaryBorrowerMailingAddressLine3: AddressLine.optional(),
    primaryBorrowerMailingAddressLine4: AddressLine.optional(),
    primaryBorrowerMailingAddressLocality: AddressLine.optional(),
    primaryBorrowerMailingAddressRegion: StateAbbreviation.optional().or(z.literal('')),
    // Custom refinements with strings that could be blank are funky,
    // the author of Zod had recommendation to use `.or(z.literal(''))`
    // https://github.com/colinhacks/zod/issues/310#issuecomment-794533682
    primaryBorrowerMailingAddressPostcode: Zipcode.optional().or(z.literal('')),
  })
  // Typically refinements only run when the base schema passes validation,
  // so if we want these refinements to run in cases where other parts of
  // the base schema validation might fail it's suggested to split up your
  // schema and run these refinements on a subset of items so they are likely
  // to run AND we avoid the problem of having to cast to `any` and loose all
  // type safety
  .superRefine((data, ctx) => {
    // If any mailing address field is populated, then require
    // full valid address
    const hasAddressField = hasPrimaryBorrowerAddressFields(data);

    // No address information was provided, exit validation early
    if (!hasAddressField) return;

    const address = typedSafeParse(StrictAddress, {
      line1: data.primaryBorrowerMailingAddressLine1 ?? '',
      line2: data.primaryBorrowerMailingAddressLine2,
      line3: data.primaryBorrowerMailingAddressLine3,
      line4: data.primaryBorrowerMailingAddressLine4,
      locality: data.primaryBorrowerMailingAddressLocality ?? '',
      region: data.primaryBorrowerMailingAddressRegion ?? '',
      postcode: data.primaryBorrowerMailingAddressPostcode ?? '',
      country: 'USA',
    });

    // Has valid address, exit validation early
    if (address.success) return;

    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['primaryBorrowerMailingAddressLine1'],
      message: 'Missing full primary borrower mailing address',
    });
  });

const LoanStatusSchema = z
  .object({
    loanStatus: z.literal('active').optional(),
    firstPaymentCollectedDate: DateString.optional().describe(
      'The date of the first payment to be collected in Willow',
    ),
    incomingTransferEffectiveDate: DateString.optional(),
    principalPurchased: NonNegativeFloat.optional(),
    servicerPurchaseDate: DateString.optional(),
    servicerPurchasedEscrowBalance: Float.optional(),
    servicerPurchasedReserveBalance: NonNegativeFloat.optional(),
    initialReserveBalance: NonNegativeFloat.optional(),
    correspondentLender: z.string().optional(),
    correspondentLenderPhone: OptionalPhoneNumber,
    correspondentLenderEmail: OptionalEmailString,
  })
  .superRefine((data, ctx) => {
    if (data.loanStatus === 'active') {
      if (isUndefined(data.firstPaymentCollectedDate))
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['firstPaymentCollectedDate'],
          message: 'Required',
        });
      if (isUndefined(data.principalPurchased))
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['principalPurchased'],
          message: 'Required',
        });
      if (isUndefined(data.servicerPurchaseDate))
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['servicerPurchaseDate'],
          message: 'Required',
        });
    }
  });

const EntitySchema = z.object({
  entityCompanyId: z
    .string()
    .optional()
    .describe(
      'All entity fields are required when either field is present: entityCompanyId OR entityName. Otherwise, all fields are optional.',
    ),
  entityName: z
    .string()
    .optional()
    .describe(
      'All entity fields are required when either field is present: entityCompanyId OR entityName. Otherwise, all fields are optional.',
    ),
  entityEin: z.string().optional(),
  entityAddressLine1: AddressLine.optional(),
  entityAddressLine2: AddressLine.optional(),
  entityAddressLocality: AddressLine.optional(),
  entityAddressRegion: StateAbbreviation.optional().or(z.literal('')),
  entityAddressPostcode: Zipcode.optional().or(z.literal('')),
  entityAddressCountry: z.literal('USA').optional(),
  entityPhone: OptionalPhoneNumber,
  entityEmail: OptionalEmailString,
});

const PropertySchema = z.object({
  addressCoverageAmount: NonNegativeMonetaryValue.optional(),
  addressReplacementCostValue: NonNegativeMonetaryValue.optional(),
  addressPropertyType: z.string().optional(),
  addressPropertySubType: z.string().optional(),
  addressFloodZone: z.string().optional(), // legacy field
  addressFloodInformation: AddressFloodFields.optional(),
  addressPurchasePrice: NonNegativeMonetaryValue.optional(),
  addressNumberOfUnits: NonNegativeInteger.optional(),
  addressPropertyValuationMethodType: z.string().optional(),

  address2CoverageAmount: NonNegativeMonetaryValue.optional(),
  address2ReplacementCostValue: NonNegativeMonetaryValue.optional(),
  address2PropertyType: z.string().optional(),
  address2PropertySubType: z.string().optional(),
  address2FloodZone: z.string().optional(),
  address2FloodInformation: AddressFloodFields.optional(),
  address2PurchasePrice: NonNegativeMonetaryValue.optional(),
  address2NumberOfUnits: NonNegativeInteger.optional(),
  address2PropertyValuationMethodType: z.string().optional(),

  address3CoverageAmount: NonNegativeMonetaryValue.optional(),
  address3ReplacementCostValue: NonNegativeMonetaryValue.optional(),
  address3PropertyType: z.string().optional(),
  address3PropertySubType: z.string().optional(),
  address3FloodZone: z.string().optional(),
  address3FloodInformation: AddressFloodFields.optional(),
  address3PurchasePrice: NonNegativeMonetaryValue.optional(),
  address3NumberOfUnits: NonNegativeInteger.optional(),
  address3PropertyValuationMethodType: z.string().optional(),

  address4CoverageAmount: NonNegativeMonetaryValue.optional(),
  address4ReplacementCostValue: NonNegativeMonetaryValue.optional(),
  address4PropertyType: z.string().optional(),
  address4PropertySubType: z.string().optional(),
  address4FloodZone: z.string().optional(),
  address4FloodInformation: AddressFloodFields.optional(),
  address4PurchasePrice: NonNegativeMonetaryValue.optional(),
  address4NumberOfUnits: NonNegativeInteger.optional(),
  address4PropertyValuationMethodType: z.string().optional(),

  address5CoverageAmount: NonNegativeMonetaryValue.optional(),
  address5ReplacementCostValue: NonNegativeMonetaryValue.optional(),
  address5PropertyType: z.string().optional(),
  address5PropertySubType: z.string().optional(),
  address5FloodZone: z.string().optional(),
  address5FloodInformation: AddressFloodFields.optional(),
  address5PurchasePrice: NonNegativeMonetaryValue.optional(),
  address5NumberOfUnits: NonNegativeInteger.optional(),
  address5PropertyValuationMethodType: z.string().optional(),

  address6CoverageAmount: NonNegativeMonetaryValue.optional(),
  address6ReplacementCostValue: NonNegativeMonetaryValue.optional(),
  address6PropertyType: z.string().optional(),
  address6PropertySubType: z.string().optional(),
  address6FloodZone: z.string().optional(),
  address6FloodInformation: AddressFloodFields.optional(),
  address6PurchasePrice: NonNegativeMonetaryValue.optional(),
  address6NumberOfUnits: NonNegativeInteger.optional(),
  address6PropertyValuationMethodType: z.string().optional(),

  address7CoverageAmount: NonNegativeMonetaryValue.optional(),
  address7ReplacementCostValue: NonNegativeMonetaryValue.optional(),
  address7PropertyType: z.string().optional(),
  address7PropertySubType: z.string().optional(),
  address7FloodZone: z.string().optional(),
  address7FloodInformation: AddressFloodFields.optional(),
  address7PurchasePrice: NonNegativeMonetaryValue.optional(),
  address7NumberOfUnits: NonNegativeInteger.optional(),
  address7PropertyValuationMethodType: z.string().optional(),

  address8CoverageAmount: NonNegativeMonetaryValue.optional(),
  address8ReplacementCostValue: NonNegativeMonetaryValue.optional(),
  address8PropertyType: z.string().optional(),
  address8PropertySubType: z.string().optional(),
  address8FloodZone: z.string().optional(),
  address8FloodInformation: AddressFloodFields.optional(),
  address8PurchasePrice: NonNegativeMonetaryValue.optional(),
  address8NumberOfUnits: NonNegativeInteger.optional(),

  address9CoverageAmount: NonNegativeMonetaryValue.optional(),
  address9ReplacementCostValue: NonNegativeMonetaryValue.optional(),
  address9PropertyType: z.string().optional(),
  address9PropertySubType: z.string().optional(),
  address9FloodZone: z.string().optional(),
  address9FloodInformation: AddressFloodFields.optional(),
  address9PurchasePrice: NonNegativeMonetaryValue.optional(),
  address9NumberOfUnits: NonNegativeInteger.optional(),
  address9PropertyValuationMethodType: z.string().optional(),

  address10CoverageAmount: NonNegativeMonetaryValue.optional(),
  address10ReplacementCostValue: NonNegativeMonetaryValue.optional(),
  address10PropertyType: z.string().optional(),
  address10PropertySubType: z.string().optional(),
  address10FloodZone: z.string().optional(),
  address10FloodInformation: AddressFloodFields.optional(),
  address10PurchasePrice: NonNegativeMonetaryValue.optional(),
  address10NumberOfUnits: NonNegativeInteger.optional(),
  address10PropertyValuationMethodType: z.string().optional(),
});

// Conditional refinement approach mentioned here
// https://github.com/colinhacks/zod/issues/479#issuecomment-1536233005
export const UpdateLoanRow = z
  .object({
    loanId: zodBrandedString<LosId>(),
    primaryBorrowerSsn: SocialSecurityNumber.optional(),
    productType: CamelizeEnum(ProductType).optional(),
    loanPurpose: CamelizeEnum(LoanPurposeInput).optional(),
    occupancyType: CamelizeEnum(OccupancyTypeInput).optional(),
    mortgageInsuranceAmount: NonNegativeMonetaryValue.optional(),
    initialMortgageInsuranceAmount: NonNegativeMonetaryValue.optional(),
    initialDiscountPointsAmount: NonNegativeMonetaryValue.optional(),
    loanLegalDescription: z.string().optional(),
    numberOfProperties: NonNegativeInteger.optional(),
    currentOutstandingBalance: NonNegativeFloat.optional().describe(
      'Use this field if you are changing the first firstPaymentCollectedDate on the loan. If any payments were already collected by a previous servicer, Willow must know the current outstanding principal balance of the loan.',
    ),
    escrowBalance: Float.optional(),
    interestCollected: NonNegativeFloat.optional(),
    ytdInterestPaid: NonNegativeMonetaryValue.optional(),
    principalPrepayment: NonNegativeFloat.optional(),
    suspenseBalance: NonNegativeFloat.optional(),
    create1098Form: Checkbox.optional().openapi({
      description: 'Default: true. Set to false to prevent 1098 from generating at year end',
      default: true,
    }),
  })
  .and(PrimaryBorrowerAddressSchema)
  .and(LoanStatusSchema)
  .and(EntitySchema)
  .and(PropertySchema);

export type UpdateLoanRow = z.infer<typeof UpdateLoanRow>;

export const UpdateLoanRowRefinements = unsafeCreateCastedSchema(UpdateLoanRow);
