import * as z from 'zod';

import { NonNegativeFloat, NonNegativePercentage } from '../../fields';

export const RemittanceFields = z.object({
  investorRemittanceRate: NonNegativeFloat.optional().openapi({
    example: 5,
    description:
      'investorRemittanceRate, servicerRemittanceRate, and investor2RemittanceRate must add up to loan interest rate. ',
  }),
  investor2RemittanceRate: NonNegativeFloat.optional().openapi({
    example: 1.5,
    description:
      'investorRemittanceRate, servicerRemittanceRate, and investor2RemittanceRate must add up to loan interest rate.',
  }),
  servicerRemittanceRate: NonNegativeFloat.optional().openapi({
    example: 0.5,
    description:
      'investorRemittanceRate, servicerRemittanceRate, and investor2RemittanceRate must add up to loan interest rate.',
  }),
  investorOwnershipPercent: NonNegativePercentage.optional().openapi({
    example: 50,
    description: 'The percentage of the loan that the investor owns.',
  }),
  investor2OwnershipPercent: NonNegativePercentage.optional().openapi({
    example: 50,
    description: 'The percentage of the loan that the secondary investor owns.',
  }),
});
