import { z } from 'zod';

import { PropertyId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils';
import { NonNegativeMonetaryValue } from '../validations/fields';
import { BaseLoanEvent } from './BaseLoanEvent';

export const AddAppraisalEvent = BaseLoanEvent.extend({
  type: z.literal('addAppraisal'),
  propertyId: zodBrandedUuid<PropertyId>().optional(), // TODO migrate to required
  amount: NonNegativeMonetaryValue,
  date: zodDateOrString,
});

export type AddAppraisalEvent = z.infer<typeof AddAppraisalEvent>;
