import { z } from 'zod';

import { PaymentId } from '../BrandedIds';
import { DwollaBaseTransfer, DwollaSweepType } from '../loan/Payments';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const CreateTransferEvent = BaseLoanEvent.extend({
  type: z.literal('createTransfer'),
  transfer: DwollaBaseTransfer,
  paymentId: zodBrandedUuid<PaymentId>(),
  sweepType: DwollaSweepType.optional(), // Deprecated
});
export type CreateTransferEvent = z.infer<typeof CreateTransferEvent>;
