import { z } from 'zod';

/* VALIDATIONS */
const PHONE_ALLOWED_CHARS_REGEX = /^[0-9()\-+\s.]*$/;
const checkPhoneNumber = (val: string): boolean => {
  const regex = new RegExp(PHONE_ALLOWED_CHARS_REGEX);
  const validChars = regex.test(val);
  if (!validChars) return false;
  const trimmed = val.replace(/[(|)|\-|+|\s+|.]/g, '');
  return trimmed.length === 10 || trimmed.length === 11;
};

const PHONE_NUMBER_ERROR_MESSAGE = 'Invalid phone number';

export const PhoneNumber = z.string().refine(
  (val?: string) => {
    if (val == null) return true;
    return checkPhoneNumber(val);
  },
  {
    message: PHONE_NUMBER_ERROR_MESSAGE,
  },
);

export const OptionalPhoneNumber = z
  .string()
  .optional()
  .refine(
    (val?: string) => {
      if (val == null || val.length === 0) return true;
      return checkPhoneNumber(val);
    },
    {
      message: PHONE_NUMBER_ERROR_MESSAGE,
    },
  );

/* -------- */
export const Phone = z.object({
  home: OptionalPhoneNumber,
  work: OptionalPhoneNumber,
  cell: OptionalPhoneNumber,
  other: OptionalPhoneNumber,
});
export type Phone = z.infer<typeof Phone>;
