import * as z from 'zod';

import { DateString, NonNegativeMonetaryValue } from '../fields';

// NOTE: If parsing data with CreateTransactionRow, make sure
// you trim and strip the data beforehand with the `trimAndStrip` util

const BaseTransactionRow = z.object({
  loanId: z.string(),
});

const CreateDrawRow = BaseTransactionRow.extend({
  type: z.literal('DRAW'),
  idempotencyKey: z.string().optional(),
  amount: NonNegativeMonetaryValue,
  date: DateString,
});

// TODO: As we support more transaction types, add them to union here
export const CreateTransactionRow = CreateDrawRow;
export type CreateTransactionRow = z.infer<typeof CreateTransactionRow>;

export const CREATE_TRANSACTION_ROW_REQUIRED_FIELDS = Object.keys(BaseTransactionRow.shape).filter(
  (key) => !BaseTransactionRow.shape[key as keyof typeof BaseTransactionRow.shape].isOptional(),
);
