import * as z from 'zod';

import { CompanyReportType } from '../CompanyReport';
import { DateFilter } from '../loanTransactionFilter/LoanTransactionFilter';

export const CompanyReportTypeFilter = z.object({
  type: z.literal('enum'),
  key: z.literal('type'),
  filter: z.object({
    values: z.array(z.nativeEnum(CompanyReportType)),
  }),
});
export type CompanyReportTypeFilter = z.infer<typeof CompanyReportTypeFilter>;

const CompanyReportCreatedAtFilter = z.object({
  type: z.literal('date'),
  key: z.literal('createdAt'),
  filter: DateFilter,
});
type CompanyReportCreatedAtFilter = z.infer<typeof CompanyReportCreatedAtFilter>;

export const CompanyReportFilter = z.discriminatedUnion('key', [CompanyReportTypeFilter, CompanyReportCreatedAtFilter]);
export type CompanyReportFilter = z.infer<typeof CompanyReportFilter>;
