import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, InMemoryCacheConfig } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { pick } from 'lodash';
import { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';

import { TEAMS_NOT_PERMITTED_ERROR } from '@willow/shared-iso';

import { ButtonLink } from '../Button/ButtonLink';

export type ApolloCacheConfig = InMemoryCacheConfig;

function AuthorizedApolloProvider({
  children,
  graphqlEndpoint,
  auth0ClientId,
  apolloCacheConfig,
}: {
  children: JSX.Element;
  graphqlEndpoint: string;
  auth0ClientId: string;
  apolloCacheConfig?: ApolloCacheConfig;
}) {
  const HTTP_LINK = createHttpLink({
    uri: graphqlEndpoint,
    credentials: 'include',
  });

  const [accessToken, setAccessToken] = useState('');
  const { isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect, logout } = useAuth0();

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        if (isLoading) return;

        const token = await getAccessTokenSilently({
          authorizationParams: {
            redirect_uri: window.location.origin,
          },
        });

        setAccessToken(token);
      } catch (err) {
        await loginWithRedirect();
      }
    };

    getAccessToken();
  }, [accessToken, isLoading, getAccessTokenSilently, loginWithRedirect]);

  const authLink = setContext(() => {
    return {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
  });

  const retryLink = new RetryLink({
    // This will retry requests max 6 times with a exponentially increasing delay of 1s, 2s, 4s, 8s, 16s, 32s
    // Only for network connection errors
    delay: {
      initial: 1000,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 6,
      retryIf: (error) => {
        return !!error;
      },
    },
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const context = operation.getContext();
    const headers = context?.response?.headers;

    if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
      logout({
        clientId: auth0ClientId,
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    }

    // When there is a network error or when user is not authenticated
    // headers will be null
    if (!headers) {
      return;
    }

    const serverBuildNumber = headers.get('Build-Number');

    if (graphQLErrors) {
      const clientBuildNumber = clientBuildNum();
      // In flex/staging/production clientBuildNumber and serverBuildNumber will be populated
      // from CI.  In local dev they'll be empty.
      if (clientBuildNumber && serverBuildNumber && clientBuildNumber !== serverBuildNumber) {
        toast.error(
          <div>
            <ButtonLink
              onClick={(e) => {
                e.preventDefault();
                window.location.reload();
              }}
            >
              Willow has been updated. Click here to refresh now.
            </ButtonLink>
          </div>,
          { autoClose: false, closeOnClick: false },
        );
      }

      for (const gqlError of graphQLErrors) {
        Sentry.captureMessage(JSON.stringify(pick(gqlError, 'message', 'path')), 'error');

        if (gqlError.message.includes(TEAMS_NOT_PERMITTED_ERROR)) {
          toast.error(
            'You do not have permission to complete this action. Please contact your team administrator for access.',
          );
        }
      }
    }
  });

  function clientBuildNum() {
    const value = document.querySelector('meta[name="build-num"]')?.getAttribute('value');
    // In local development, this environment variable doesn't get set.
    return value === '' ? undefined : value;
  }

  const client = new ApolloClient({
    link: authLink.concat(retryLink).concat(errorLink).concat(HTTP_LINK),
    cache: new InMemoryCache(apolloCacheConfig || {}),
  });

  return accessToken && isAuthenticated ? (
    <ApolloProvider client={client}>
      {children}
      {/* All toast messages should be displayed using this single container instance so there aren't multiple containers in the dom */}
      <ToastContainer autoClose={10000} pauseOnHover />
    </ApolloProvider>
  ) : (
    <></>
  );
}

function AuthenticationProvider({
  children,
  auth0ClientId,
  auth0Domain,
  auth0Audience,
}: {
  children: JSX.Element;
  auth0Domain: string;
  auth0ClientId: string;
  auth0Audience: string;
}) {
  return (
    <Auth0Provider
      // useFormData={false}
      domain={auth0Domain}
      clientId={auth0ClientId}
      authorizationParams={{
        audience: auth0Audience,
        redirect_uri: window.location.origin,
      }}
    >
      {children}
    </Auth0Provider>
  );
}

export function AuthorizedProvider({
  children,
  auth0Domain,
  graphqlEndpoint,
  auth0ClientId,
  auth0Audience,
  apolloCacheConfig,
}: {
  children: JSX.Element;
  graphqlEndpoint: string;
  auth0ClientId: string;
  auth0Domain: string;
  auth0Audience: string;
  apolloCacheConfig?: ApolloCacheConfig;
}) {
  return (
    <AuthenticationProvider auth0ClientId={auth0ClientId} auth0Domain={auth0Domain} auth0Audience={auth0Audience}>
      <AuthorizedApolloProvider
        graphqlEndpoint={graphqlEndpoint}
        auth0ClientId={auth0ClientId}
        apolloCacheConfig={apolloCacheConfig}
      >
        {children}
      </AuthorizedApolloProvider>
    </AuthenticationProvider>
  );
}
