import * as z from 'zod';

import { CompanyReportDownloadId, CompanyReportId, UserId } from './BrandedIds';
import { zodBrandedUuid, zodDateOrString } from './utils';

const CompanyReportDownload = z.object({
  id: zodBrandedUuid<CompanyReportDownloadId>(),
  companyReportId: zodBrandedUuid<CompanyReportId>(),
  userId: zodBrandedUuid<UserId>(),
  userName: z.string().optional(),
  userEmail: z.string(),
  timestamp: zodDateOrString,
});
export type CompanyReportDownload = z.infer<typeof CompanyReportDownload>;
