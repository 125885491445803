/*
  Single calendar with two months visible that allows you to pick a date range
*/

import { useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';

import { NamedMemo } from '../NamedMemo';

import './DateRangePicker.scss';

interface Props {
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  onSelection: ({ startDate, endDate }: { startDate?: Date; endDate?: Date }) => void;
  monthsShown?: number;
}

type DateOrNull = Date | null;

export const DateRangePickerMultipleMonths = NamedMemo<Props>(
  'DateRangePickerMultipleMonths',
  ({ startDate: startDateProps, endDate: endDateProps, onSelection, monthsShown }) => {
    const [startDate, setStartDate] = useState<Date | undefined>(startDateProps);
    const [endDate, setEndDate] = useState<Date | undefined>(endDateProps);

    const onSetDateRange = useCallback(
      (dates: [DateOrNull, DateOrNull]) => {
        const [start, end] = dates;

        //DatePicker requires null but we use undefined
        setStartDate(start ?? undefined);
        setEndDate(end ?? undefined);
        onSelection({ startDate: start ?? undefined, endDate: end ?? undefined });
      },
      [onSelection],
    );

    return (
      <div className="date-range-picker__container date-range-picker__container--multiple">
        <DatePicker
          selected={startDate}
          onChange={onSetDateRange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          inline
          monthsShown={monthsShown || 2}
          autoComplete="off"
        />
      </div>
    );
  },
);
