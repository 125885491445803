/*
  For demo-purposes only when WHEDA feature flag is enabled
  Payment scheduling with additional principal

  Note: everything is copied over from the existing PaymentsAccepted.tsx flow
  Everything is left working as the live version EXCEPT:
  – Removed the autopayment toggle
  – Added in payment scheduling, payment frequency, and additional principal amount to the payment form

  keyword search DEMO to find code changes
*/
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { FormEvent, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Scroll from 'react-scroll';
import { toast } from 'react-toastify';

import {
  CompanyFragment,
  DwollaBank,
  InitiatePaymentDocument,
  InitiatePaymentMutationVariables,
  LoanBillSettingsType,
  PaymentMethod as TPaymentMethod,
  PortalChargeFragment,
  PortalLoanSnapshot,
  PortalPaymentDueFragment,
  PortalPaymentFragment,
} from '@willow/graphql-iso/src/portal';
import {
  add,
  convertNumberToOrdinal,
  formatDateFromDate,
  multiply,
  stripNullFromNullOrUndefined,
  subtract,
} from '@willow/shared-iso';
import {
  Button,
  ControlledCurrencyInput,
  ControlledSelect,
  ControlledSingleDatePicker,
  dateFormat,
  getOldestUnfulfilledPaymentDueDate,
  getUnfulfilledPaymentsDue,
  isGqlStatementBreakdown,
  Loader,
  NamedMemo,
  paymentAmountFormat,
  paymentDateFormat,
  useLocalStorage,
  z,
} from '@willow/shared-web';
import { Form, OverlayTrigger, Tooltip } from '@willow/shared-web/bootstrap';
import { EscrowPaymentHistoryItemId, LoanId, PortalPaymentDueSubStatus } from '@willow/types-iso';

import { PortalSelectedLoan } from '../../../App';
import { getTotalAmountDue } from '../../../pages/make-payment/utils';
import { getFeesDueSorted } from '../../../utils/fees';
import { Alert } from '../../alert/Alert';
import { DwollaLimitModal } from '../../dwolla-limit-modal/DwollaLimitModal';
import { InterestReservePayment } from '../../interest-reserve-payment/InterestReservePayment';
import { WillowModal } from '../../modal/Modal';
import { PaymentFormAlerts } from '../../payment-form/alerts/PaymentFormAlerts';
import { ConfirmPaymentModal } from '../../payment-form/confirm-payment-modal/ConfirmPaymentModal';
import { OverPaymentModal } from '../../payment-form/over-payment-modal/OverPaymentModal';
import { UnderPaymentModal } from '../../payment-form/under-payment-modal/UnderPaymentModal';
import { getPaymentFormAmountDueDisplay, paymentFormAmountDuesForDisplay } from '../../payment-form/util';
import { PaymentMethod } from '../../payment-method/PaymentMethod';
import { PaymentDetails } from './payment-details/PaymentDetails';
import { PaymentHeader } from './payment-header/PaymentHeader';
import { PaymentProcessing } from './PaymentProcessing';
import { PreviousPayment } from './previous-payment/PreviousPayment';

import '../../payment-form/PaymentForm.scss';

const SCROLLER = Scroll.scroller;

interface Props {
  company: CompanyFragment;
  loan: PortalSelectedLoan;
  refetch: (poll?: boolean) => void;
}

export const WhedaDemoPaymentsAccepted = NamedMemo<Props>('WhedaDemoPaymentsAccepted', ({ company, loan, refetch }) => {
  const {
    id: loanId,
    currentSnapshot: {
      paymentDueStatus,
      paymentDueSubStatus,
      totalAmountRemainingFromBorrower,
      escrowShortageAnalysisId,
      shortageAmount,
      chargeAmountDue,
      paymentsDue,
      paymentToPaymentDue,
      payments,
      charges,
      payoffs,
      nextStatementDate,
      transferData,
      autopaySettings,
      stopSettings,
      currentPaymentConfig: { delinquentInterestRate },
      currentBillSettingsType,
      activeWorkoutPlan,
    },
    configuration: { hasInterestReserve },
  } = loan;

  const borrowerHasAmountRemaining = totalAmountRemainingFromBorrower > 0;
  const hasPaymentDue = paymentDueStatus === 'due' || borrowerHasAmountRemaining;
  const hasInterestReserveDue = paymentDueStatus !== 'submitted' && !borrowerHasAmountRemaining && hasInterestReserve;

  /*
      Future enhancement: just copying the below over from makepayment.tsx
      this stuff should be handled by paymentform
  */
  const [displayAdditionalPayment, setDisplayAdditionalPayment] = useState(false); // Allow additional payments to be made within certain statuses
  const unfulfilledCharges = getFeesDueSorted(charges);

  // Determine if there are processing payments
  let processingPayments: PortalSelectedLoan['currentSnapshot']['payments'] = [];
  let isOutstanding: boolean = false;
  if (
    (['due', 'submitted'].includes(paymentDueStatus) || borrowerHasAmountRemaining) &&
    paymentToPaymentDue.length > 0
  ) {
    // Check if any payments have been made already associated with any of the paymentsDue items
    const unfulfilledPayments = getUnfulfilledPaymentsDue(paymentsDue);
    const paymentDueIds = unfulfilledPayments?.map((unfulfilledPayement) => unfulfilledPayement.id);
    const associatedPaymentIds = paymentToPaymentDue
      .filter((ppd) => {
        return isGqlStatementBreakdown(ppd) && paymentDueIds?.includes(ppd.paymentDueId);
      })
      .map((item) => item.paymentId);

    if (payments) {
      // Find all associated payments for this payment due
      const newAssociatedPayments = associatedPaymentIds?.length
        ? payments.filter((paymentItem) => {
            return associatedPaymentIds.includes(paymentItem.id);
          })
        : [];

      // Determine if there is an outstanding balance status
      // Outstanding if payment is still due + there are already payments associated with it
      isOutstanding = paymentDueStatus === 'due' && newAssociatedPayments.length > 0;

      // Pick out processing payments for the current paymentDue
      // if "due" status: we are only interested in unfulfilled payments (the totalAmountRemainingFromBorrower > 0 if there are outstanding charges)
      // if "submitted" status, all payments will already be marked fulfilled, so look through all payments for processing status
      const statusProcessingPayments = paymentDueStatus === 'due' ? newAssociatedPayments : payments;
      processingPayments = statusProcessingPayments.filter((paymentItem) => {
        return paymentItem.status === 'PENDING' && paymentItem.method !== TPaymentMethod.Reserve;
      });
    }
  }

  const totalAmountDue = getTotalAmountDue(loan);
  const hasActivePayoffStatement = payoffs.some((p) => ['status', 'pending'].includes(p.status));
  const exactPaymentOnly =
    stopSettings.portalPayments === 'exact' || Boolean(loan.company.adminSettings.exactPaymentsOnly);

  const handlePaymentSubmission = useCallback(() => {
    // After a payment has been successfully submitted, hide the form until the user requests it again
    setDisplayAdditionalPayment(false);
  }, []);

  return (
    <>
      <PaymentHeader loan={loan} />

      {hasPaymentDue && (
        <>
          <DemoPaymentForm
            loanId={loanId}
            company={company}
            totalAmountDue={totalAmountDue}
            shortageAmount={shortageAmount}
            escrowShortageAnalysisId={escrowShortageAnalysisId}
            chargesAmountDue={chargeAmountDue}
            paymentsDue={paymentsDue}
            processingPayments={processingPayments}
            chargesDue={unfulfilledCharges}
            subStatus={paymentDueSubStatus}
            hasOutstandingBalance={isOutstanding}
            nextStatementDate={nextStatementDate}
            autopaySettings={autopaySettings}
            hasInterestReserve={loan.configuration.hasInterestReserve}
            hasPayoffStatement={hasActivePayoffStatement}
            exactPaymentOnly={exactPaymentOnly}
            refetch={refetch}
            delinquentInterestRate={delinquentInterestRate ?? 0}
            currentBillSettingsType={currentBillSettingsType}
            activeWorkoutPlan={activeWorkoutPlan ?? undefined}
          />
          {processingPayments?.length > 0 && (
            <PaymentDetails
              companyId={company.id}
              loanId={loanId}
              payments={processingPayments}
              status={paymentDueStatus}
              subStatus={paymentDueSubStatus}
              refetch={refetch}
            />
          )}
        </>
      )}

      {paymentDueStatus === 'submitted' && !borrowerHasAmountRemaining && (
        <PaymentProcessing
          companyId={company.id}
          loanId={loanId}
          processingPayments={processingPayments}
          status={paymentDueStatus}
          subStatus={paymentDueSubStatus}
          autopaySettings={autopaySettings ?? undefined}
          onAdditionalPayment={() => {
            setDisplayAdditionalPayment(true);
          }}
          refetch={refetch}
        />
      )}

      {paymentDueStatus === 'notDue' && !borrowerHasAmountRemaining && (
        <PreviousPayment
          paymentsDue={paymentsDue}
          paymentToPaymentDue={paymentToPaymentDue || []}
          paymentsMade={payments}
          onAdditionalPayment={() => {
            setDisplayAdditionalPayment(true);
          }}
          transferData={stripNullFromNullOrUndefined(transferData)}
        />
      )}

      {hasInterestReserveDue && (
        <InterestReservePayment
          onAdditionalPayment={() => {
            setDisplayAdditionalPayment(true);
          }}
        />
      )}
      {displayAdditionalPayment && (
        <DemoPaymentForm
          loanId={loanId}
          company={company}
          totalAmountDue={totalAmountDue}
          shortageAmount={shortageAmount}
          escrowShortageAnalysisId={escrowShortageAnalysisId}
          chargesAmountDue={chargeAmountDue}
          paymentsDue={paymentsDue || []}
          processingPayments={processingPayments}
          chargesDue={unfulfilledCharges}
          subStatus={paymentDueSubStatus}
          isAdditionalPayment
          nextStatementDate={nextStatementDate}
          autopaySettings={autopaySettings}
          hasInterestReserve={hasInterestReserve}
          hasPayoffStatement={hasActivePayoffStatement}
          exactPaymentOnly={exactPaymentOnly}
          onPaymentSubmission={handlePaymentSubmission}
          refetch={refetch}
          delinquentInterestRate={delinquentInterestRate ?? 0}
          currentBillSettingsType={currentBillSettingsType}
          activeWorkoutPlan={activeWorkoutPlan ?? undefined}
        />
      )}
    </>
  );
});

export interface DemoPaymentFormProps {
  loanId: LoanId;
  company: CompanyFragment;
  totalAmountDue: number;
  shortageAmount: number;
  escrowShortageAnalysisId: EscrowPaymentHistoryItemId | undefined;
  chargesAmountDue: number; // TODO subtract out from min payment amount / underpayment
  paymentsDue: PortalPaymentDueFragment[];
  processingPayments: PortalPaymentFragment[];
  chargesDue: PortalChargeFragment[];
  subStatus?: PortalPaymentDueSubStatus;
  isAdditionalPayment?: boolean; // used for views where the payment status is not "due"
  hasOutstandingBalance?: boolean;
  nextStatementDate?: string;
  autopaySettings?: PortalLoanSnapshot['autopaySettings'];
  hasInterestReserve: boolean;
  hasPayoffStatement: boolean;
  exactPaymentOnly: boolean;
  delinquentInterestRate: number;
  currentBillSettingsType: LoanBillSettingsType;
  activeWorkoutPlan: PortalSelectedLoan['currentSnapshot']['activeWorkoutPlan'] | undefined;
  onPaymentSubmission?: (variables: InitiatePaymentMutationVariables) => void; // notify parent of payment made
  refetch?: (poll?: boolean) => void; // refetch after successful submission
}

type AdditionalPayment = {
  principal: number;
  escrow: number;
  suspense: number;
};

const DemoPaymentFormShape = z.object({
  otherAmount: z.string().transform(Number).or(z.number()).optional(),
  paymentIntention: z.enum(['total', 'totalWithShortage', 'other']),
  additionalPrincipal: z.coerce.number().optional(), // DEMO: this is available in main form as opposed to just the overpayment form
  // DEMO ADDITIONS
  paymentDate: z.date(),
  frequency: z.enum(['oneTime', 'monthly']),
});
type TDemoPaymentFormShape = z.infer<typeof DemoPaymentFormShape>;

const DemoPaymentForm = (props: DemoPaymentFormProps) => {
  type RecurringPayment = { loanId: LoanId; frequency: 'oneTime' | 'monthly'; date: Date; amount: number };
  const [recurringPayments, setRecurringPayments] = useLocalStorage<RecurringPayment[]>('recurringPayment', []);

  const {
    loanId,
    company,
    totalAmountDue,
    shortageAmount,
    escrowShortageAnalysisId,
    chargesAmountDue,
    paymentsDue,
    processingPayments,
    chargesDue,
    subStatus,
    isAdditionalPayment,
    hasOutstandingBalance,
    nextStatementDate,
    autopaySettings,
    hasInterestReserve,
    hasPayoffStatement,
    exactPaymentOnly,
    delinquentInterestRate,
    currentBillSettingsType,
    activeWorkoutPlan,
    onPaymentSubmission,
    refetch,
  } = props;
  const loanRecurringPayment = recurringPayments.find((rp) => rp.loanId === loanId);

  const [initiatePayment] = useMutation(InitiatePaymentDocument); // DEMO: payment initiated only if today's date is selected

  const { control, reset, watch, setValue } = useForm<TDemoPaymentFormShape>({
    resolver: zodResolver(DemoPaymentFormShape),
    defaultValues: {
      otherAmount: undefined,
      paymentIntention: loanRecurringPayment ? 'other' : 'total', // DEMO
      additionalPrincipal: undefined,
      // DEMO ADDITIONS
      paymentDate: new Date(),
      frequency: 'oneTime',
    },
  });

  // For escrow analysis statements, default paymentAmount includes the 1/12th shortage
  // This is the remaining 11/12ths of the shortage (to make us whole)
  const additionalEscrowShortage = multiply(shortageAmount / 12, 11);

  // Track payment amount and method for form submission
  const [paymentBank, setPaymentBank] = useState<DwollaBank | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDeleteRecurringPaymentModal, setShowDeleteRecurringPaymentModal] = useState(false);

  const [activeModal, setActiveModal] = useState<
    'overPayment' | 'underPayment' | 'confirmPayment' | 'dwollaLimit' | 'demoPaymentReview' | undefined
  >(undefined);
  const [hasBankError, setHasBankError] = useState(false);

  const paymentDate = getOldestUnfulfilledPaymentDueDate(paymentsDue);
  const amountDuesDisplay = getPaymentFormAmountDueDisplay(paymentsDue, chargesDue);
  const amountDuesForDisplay = paymentFormAmountDuesForDisplay(amountDuesDisplay, false);
  const amountDuesForShortageDisplay = paymentFormAmountDuesForDisplay(amountDuesDisplay, true);

  const onBankUpdate = useCallback((bank: DwollaBank | undefined) => {
    if (bank) {
      setHasBankError(false);
    }
    setPaymentBank(bank);
  }, []);

  const calculatePaymentAmount = () => {
    switch (paymentIntention) {
      case 'total':
        return totalAmountDue;
      case 'totalWithShortage':
        return add(totalAmountDue, additionalEscrowShortage);
      case 'other':
        return Number(otherAmount);
    }
  };

  const otherAmount = watch('otherAmount');
  const paymentIntention = watch('paymentIntention');
  const inputAdditionalPrincipal = watch('additionalPrincipal');
  const selectedPaymentDate = watch('paymentDate');
  const selectedPaymentFrequency = watch('frequency');
  const paymentAmount = calculatePaymentAmount();
  const minAmountDue = subtract(totalAmountDue, chargesAmountDue); // just the statement due amount. Borrower can pay amountDue - feeDue and the loan will be considered up to date
  const autopayEnabledByServicer = Boolean(autopaySettings && autopaySettings.type === 'usio');

  const submitPayment = async (additionalPayment?: AdditionalPayment) => {
    try {
      if (!paymentBank || !paymentAmount) {
        throw new Error('Bank or payment amount are not properly set.');
      }

      // On successful submission, the parent page loan hook will be notified and handle necessary view updates
      let variables: InitiatePaymentMutationVariables = {
        borrowerBankUrl: paymentBank.links.self,
        amount: paymentAmount,
        loanId,
        companyId: company.id,
      };

      if (paymentIntention === 'totalWithShortage' && escrowShortageAnalysisId) {
        variables = {
          ...variables,
          amount: paymentAmount,
          escrowShortageAnalysisId,
          additionalPrincipal: 0,
          additionalEscrow: additionalEscrowShortage,
          additionalSuspense: 0,
        };
      }

      if (additionalPayment) {
        // If additional payment (aka overpayment), update the payment amount to reflect the overpayment amounts)
        const overpaymentAmount = add(
          totalAmountDue,
          additionalPayment.principal,
          additionalPayment.escrow,
          additionalPayment.suspense,
        );

        variables = {
          ...variables,
          amount: overpaymentAmount,
          additionalPrincipal: additionalPayment.principal,
          additionalEscrow: additionalPayment.escrow,
          additionalSuspense: additionalPayment.suspense,
        };
      }

      await initiatePayment({
        variables,
      });

      reset();

      // Refetch Dwolla token after successful submission
      refetch && refetch();

      // Notify parent of successful payment submission (optional subscription field)
      if (onPaymentSubmission) {
        onPaymentSubmission(variables);
      }

      // Scroll to Payment header so user sees the update
      SCROLLER.scrollTo('payment-header', { offset: -150 });
    } catch (err: any) {
      // Determine error message to display
      if (err?.message?.indexOf('Invalid amount. The supplied amount is greater than your transaction limit.') >= 0) {
        setActiveModal('dwollaLimit');
      } else {
        toast.error('Whoops! Something went wrong with submitting your payment. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onModalSubmit = async (additionalPayment?: AdditionalPayment) => {
    // Set loader & close modals
    setIsSubmitting(true);
    setActiveModal(undefined);

    // Send through the payment
    await submitPayment(additionalPayment);
  };

  const onSubmit = async (e: FormEvent) => {
    if (e) {
      e.preventDefault();
    }

    setIsSubmitting(true);
    setHasBankError(false);

    // Frontend validation (browser "required" should catch this already, but this is fallback)
    const missingData = !paymentAmount || !paymentBank;
    if (missingData) {
      if (!paymentBank) {
        setHasBankError(true);
        toast.error('Connected bank account is required');
      } else if (!paymentAmount) {
        toast.error('Enter a payment amount');
      }

      setIsSubmitting(false);
      return;
    }

    // Check if under or over payment
    if (paymentIntention === 'totalWithShortage' && escrowShortageAnalysisId) {
      setActiveModal('confirmPayment');
    } else if (minAmountDue > 0 && minAmountDue > paymentAmount) {
      // Underpayment
      setActiveModal('underPayment');
      setIsSubmitting(false);
    } else if ((totalAmountDue > 0 && totalAmountDue < paymentAmount) || isAdditionalPayment) {
      // Overpayment
      setActiveModal('overPayment');
      setIsSubmitting(false);
    } else {
      setIsSubmitting(false);
      setActiveModal('demoPaymentReview');
    }
  };

  const onDemoPaymentReviewSubmit = async () => {
    const additionalPrincipal = parseFloat((inputAdditionalPrincipal ?? '0') as string);

    // Submit one-time today payments as normal
    const isInstantPayment =
      selectedPaymentFrequency === 'oneTime' &&
      DateTime.fromJSDate(selectedPaymentDate).toISODate() === DateTime.now().toISODate();
    if (isInstantPayment) {
      await submitPayment(
        paymentIntention === 'other' ? undefined : { principal: additionalPrincipal, escrow: 0, suspense: 0 },
      );
    } else {
      setRecurringPayments([
        ...recurringPayments,
        {
          loanId,
          frequency: selectedPaymentFrequency,
          date: selectedPaymentDate,
          amount: add(paymentAmount, additionalPrincipal),
        },
      ]);
      setValue('paymentIntention', 'other');
      SCROLLER.scrollTo('payment-header', { offset: -150 });
      toast.success('Recurring payment successfully scheduled');
      setActiveModal(undefined);
    }
  };

  return (
    <>
      <PaymentFormAlerts
        isAdditionalPayment={isAdditionalPayment ?? false}
        company={company}
        totalAmountDue={totalAmountDue}
        paymentsDue={paymentsDue || []}
        processingPayments={processingPayments}
        subStatus={subStatus}
        hasOutstandingBalance={hasOutstandingBalance}
        autopaySettings={autopaySettings}
        hasInterestReserve={hasInterestReserve}
        hasPayoffStatement={hasPayoffStatement}
        autopayEnabledByServicer={autopayEnabledByServicer}
        delinquentInterestRate={delinquentInterestRate}
        currentBillSettingsType={currentBillSettingsType}
        activeWorkoutPlan={activeWorkoutPlan}
      />
      <section
        className={classNames(['payment-form', 'make-payment__container'], {
          'payment-form--busy': isSubmitting,
          'payment-form--addition': isAdditionalPayment || loanRecurringPayment, // DEMO check
        })}
      >
        {/* START DEMO SCHEDULED PAYMENT */}
        {loanRecurringPayment && (
          <div className="mb-4">
            {/* post-demo this shouldnt be h1-coded */}
            <h1>Scheduled Payment</h1>
            <div className="make-payment__container0 mt-2 p-3">
              <div className="d-flex justify-content-between">
                <div>
                  <h3 className="u-fs-1 u-bold u-color-bark3">
                    {loanRecurringPayment.frequency === 'oneTime' ? 'One-Time' : 'Monthly'} Payment
                  </h3>
                  <div>
                    <div>
                      <span className="u-bold">{paymentAmountFormat(loanRecurringPayment.amount)}</span> on{' '}
                      {dateFormat(loanRecurringPayment.date)}
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <Button
                    size="sm"
                    variant="tertiary"
                    className="make-payment__container0 d-flex p-2 u-color-bark4 hover:u-color-bark3"
                    onClick={() => setShowDeleteRecurringPaymentModal(true)}
                  >
                    <DeleteOutlined />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        <WillowModal
          showModal={showDeleteRecurringPaymentModal}
          onClose={() => setShowDeleteRecurringPaymentModal(false)}
        >
          <div className="modal-confirm-payment">
            <h2>Delete scheduled payment?</h2>
            <div className="modal-confirm-payment__copy">
              Once confirmed, your scheduled payment will no longer be processed. You may reschedule another payment at
              any time.
            </div>
            <div className="d-flex justify-content-end">
              <div className="d-flex gap-2 mt-4">
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={() => setShowDeleteRecurringPaymentModal(false)}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  onClick={() => {
                    setIsSubmitting(true);
                    setTimeout(() => {
                      setRecurringPayments(recurringPayments.filter((rp) => rp.loanId !== loanId));
                      setIsSubmitting(false);
                      setShowDeleteRecurringPaymentModal(false);
                      setValue('paymentIntention', 'total');
                    }, 600);
                  }}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  Yes, delete
                </Button>
              </div>
            </div>
          </div>
        </WillowModal>
        {/* END DEMO SCHEDULED PAYMENT */}

        <h1>Make a Payment</h1>

        {isAdditionalPayment && (
          <div className="payment-form__addition-alert">
            <Alert
              title={`Next statement available on ${nextStatementDate}`}
              subtitle="Only make a payment if instructed by your lender"
              level="ERROR"
            />
          </div>
        )}

        <form onSubmit={onSubmit}>
          <h2>
            Payment Method
            <OverlayTrigger
              placement="top"
              trigger={['hover', 'focus']}
              overlay={<Tooltip>Learn more about online payments at the FAQ page</Tooltip>}
            >
              <QuestionCircleOutlined />
            </OverlayTrigger>
          </h2>
          <div className={classNames(['payment-form__dwolla'], { 'payment-form__dwolla--error': hasBankError })}>
            <PaymentMethod companyName={company.name} autopaySettings={autopaySettings} onBankUpdate={onBankUpdate} />
          </div>
          {/* DEMO: AUTOPAY TOGGLE REMOVED */}
          <h2 data-testid="payment-form-amount-title">Amount</h2>
          <div className="make-payment__container0 payment-form__amount">
            {/* Only give "total" option if not making an additional payment */}
            {/* DEMO: check recurringPayment */}
            {!isAdditionalPayment && !loanRecurringPayment && (
              <div
                className={classNames(['payment-form__radio'], {
                  'u-bg-secondary': paymentIntention === 'total',
                })}
                onClick={() => {
                  setValue('paymentIntention', 'total');
                }}
                data-testid="payment-form-amount-total"
              >
                <Form.Check.Input
                  type="radio"
                  value="total"
                  className="checked:u-bg-willow"
                  onChange={() => setValue('paymentIntention', 'total')}
                  checked={paymentIntention === 'total'}
                />
                <Form.Check.Label>
                  <div className="payment-form__label_title desktop">Amount due {paymentDate}</div>
                  <div className="payment-form__label_title tablet">
                    Amount due
                    <br />
                    {paymentDateFormat(paymentDate, true)}
                  </div>

                  <div className="payment-form__total">{paymentAmountFormat(totalAmountDue) || '$0.00'}</div>
                </Form.Check.Label>
                {amountDuesForDisplay.length > 1 && (
                  <div className="payment-form__multiple-due">
                    <p>Amount due includes additional balance:</p>
                    <ul>
                      {amountDuesForDisplay.map(({ desktop, tablet }, index) => (
                        <li key={`overdue-item-${index}`}>
                          <span className="desktop">{desktop}</span>
                          <span className="tablet">{tablet}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
            {additionalEscrowShortage > 0 && (
              <div
                className={classNames(['payment-form__radio'], {
                  'u-bg-secondary': paymentIntention === 'totalWithShortage',
                })}
                onClick={() => {
                  setValue('paymentIntention', 'totalWithShortage');
                }}
                data-testid="payment-form-amount-totalWithShortage"
              >
                <Form.Check.Input
                  type="radio"
                  value="totalWithShortage"
                  className="checked:u-bg-willow"
                  checked={paymentIntention === 'totalWithShortage'}
                  onChange={() => setValue('paymentIntention', 'totalWithShortage')}
                />
                <Form.Check.Label>
                  <div className="payment-form__label_title desktop">Amount with escrow shortage due {paymentDate}</div>
                  <div className="payment-form__label_title tablet">
                    Amount due
                    <br />
                    {paymentDateFormat(paymentDate, true)}
                  </div>

                  <div className="payment-form__total">
                    {paymentAmountFormat(add(totalAmountDue, additionalEscrowShortage)) || '$0.00'}
                  </div>
                </Form.Check.Label>

                <div className="payment-form__multiple-due">
                  <p>Amount due includes additional balance:</p>
                  <ul>
                    {amountDuesForShortageDisplay.map(({ desktop, tablet }, index) => (
                      <li key={`overdue-item-${index}`}>
                        <span className="desktop">{desktop}</span>
                        <span className="tablet">{tablet}</span>
                      </li>
                    ))}
                    <li>{paymentAmountFormat(shortageAmount)} (Escrow shortage)</li>
                  </ul>
                </div>
              </div>
            )}
            {(isAdditionalPayment || !exactPaymentOnly) && (
              <div
                className={classNames(['payment-form__radio'], {
                  'u-bg-secondary': paymentIntention === 'other',
                })}
                onClick={() => {
                  setValue('paymentIntention', 'other');
                }}
                data-testid="payment-form-amount-other"
              >
                <Form.Check.Input
                  type="radio"
                  className="checked:u-bg-willow"
                  value="other"
                  checked={paymentIntention === 'other'}
                  onChange={() => {
                    setValue('paymentIntention', 'other');
                    setValue('additionalPrincipal', 0);
                  }}
                />
                <Form.Check.Label>
                  <div className="payment-form__label_title desktop">Other Amount</div>
                  <div className="payment-form__label_title tablet">Other</div>
                  <div className="d-flex payment-form__other-input">
                    <ControlledCurrencyInput
                      id="other-amount"
                      className="payment-form__other-input"
                      control={control}
                      fieldName="otherAmount"
                      data-testid="payment-form-other-input"
                      onAmountUpdate={() => {
                        setValue('paymentIntention', 'other');
                        setValue('additionalPrincipal', 0);
                      }} // Selects the radio button when user types
                    />
                  </div>
                </Form.Check.Label>
              </div>
            )}
          </div>
          {/* START DEMO */}
          {!loanRecurringPayment && (
            <>
              {/* DEMO field (current live version this is only available in overpayment modal) */}
              <h2 className="mt-4">
                Additional Principal Amount
                <OverlayTrigger
                  placement="top"
                  trigger={['hover', 'focus']}
                  overlay={
                    <Tooltip>
                      {paymentIntention === 'total' ? (
                        <>Additional principal payments are applied to principal after your full payment has posted</>
                      ) : (
                        <>Select the full payment amount above in order to apply additional principal</>
                      )}
                    </Tooltip>
                  }
                >
                  <QuestionCircleOutlined />
                </OverlayTrigger>
              </h2>
              <div className="make-payment__container0 p-4">
                <Form.Check.Label
                  className={classNames('d-flex justify-content-between align-items-center', {
                    'opacity-50': paymentIntention !== 'total',
                  })}
                >
                  <div className="payment-form__label_title">Added to monthly payment</div>
                  <div className="payment-form__total">
                    <div className="payment-form__other-input">
                      <ControlledCurrencyInput
                        id="ap-amount"
                        className="payment-form__other-input"
                        control={control}
                        fieldName="additionalPrincipal"
                        disabled={paymentIntention !== 'total'}
                      />
                    </div>
                  </div>
                </Form.Check.Label>
              </div>
              {/* DEMO-only Scheduling & Frequency fields */}
              <h2 className="mt-4">Scheduling & Frequency</h2>
              <div className="make-payment__container0">
                <div className="p-4 d-flex justify-content-between align-items-center">
                  <div className="payment-form__label_title">Confirm payment date</div>
                  <div style={{ width: '170px' }}>
                    <ControlledSingleDatePicker
                      control={control}
                      fieldName="paymentDate"
                      minDate={new Date()}
                      className="u-fs-4"
                    />
                  </div>
                </div>
                <div className="p-4 u-border-t-1 u-border-color-bark1 d-flex justify-content-between align-items-center">
                  <div className="payment-form__label_title">Confirm payment frequency</div>
                  <div style={{ width: '170px' }}>
                    <ControlledSelect
                      control={control}
                      fieldName="frequency"
                      placeholder="Select payment frequency"
                      isClearable={false}
                      options={[
                        { value: 'oneTime', label: 'One-Time' },
                        { value: 'monthly', label: 'Monthly' },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {/* END DEMO */}

          <div className="payment-form__submit">
            <div className="payment-form__submit_inner">
              <input type="submit" value="Review Payment" disabled={isSubmitting} />
              {isSubmitting && <Loader />}
            </div>
          </div>
        </form>
      </section>

      <ConfirmPaymentModal
        showModal={activeModal === 'confirmPayment'}
        paymentAmount={paymentAmount}
        bankName={paymentBank?.name || 'selected bank'}
        autopayEnabled={!!autopaySettings}
        onClose={() => {
          setActiveModal(undefined);
          setIsSubmitting(false);
        }}
        paymentSubmit={() => onModalSubmit()}
      />

      {/* Demo: not updating the under or over-payment modal behavior */}
      <UnderPaymentModal
        showModal={activeModal === 'underPayment'}
        onClose={() => {
          setActiveModal(undefined);
          setIsSubmitting(false);
        }}
        onSubmit={() => onModalSubmit()}
        minPayment={minAmountDue}
        submissionAmount={paymentAmount}
        bankName={paymentBank?.name || 'selected bank'}
        autopayEnabled={!!autopaySettings}
        disabled={isSubmitting}
        applyPartialPayment={company.adminSettings.applyPartialPayments ?? false}
      />
      <OverPaymentModal
        showModal={activeModal === 'overPayment'}
        onClose={() => {
          setActiveModal(undefined);
          setIsSubmitting(false);
        }}
        onSubmit={(additionalPayment) => onModalSubmit(additionalPayment)}
        paymentAmount={paymentAmount}
        amountDue={totalAmountDue || 0}
        autopayEnabled={!!autopaySettings}
        bankName={paymentBank?.name || 'selected bank'}
        disabled={isSubmitting}
      />
      {company && (
        <DwollaLimitModal
          company={company}
          showModal={activeModal === 'dwollaLimit'}
          onClose={() => {
            setActiveModal(undefined);
            setIsSubmitting(false);
          }}
        />
      )}
      {/* DEMO: this modal over the current confirmModal for happy path */}
      <DemoReviewPaymentModal
        showModal={activeModal === 'demoPaymentReview'}
        paymentAmount={
          paymentIntention === 'other'
            ? paymentAmount
            : add(paymentAmount, parseFloat((inputAdditionalPrincipal ?? '0') as string)) ?? 0
        }
        bankName={paymentBank?.name || 'selected bank'}
        onClose={() => {
          setActiveModal(undefined);
          setIsSubmitting(false);
        }}
        paymentSubmit={() => onDemoPaymentReviewSubmit()}
        lenderName={company.name}
        paymentDate={selectedPaymentDate}
        isRecurring={selectedPaymentFrequency === 'monthly'}
      />
    </>
  );
};

interface DemoReviewPaymentModalProps {
  showModal: boolean;
  onClose: () => void;
  paymentAmount: number;
  bankName: string;
  paymentSubmit: () => void;
  lenderName: string;
  paymentDate: Date;
  isRecurring: boolean;
}
const DemoReviewPaymentModal = NamedMemo<DemoReviewPaymentModalProps>(
  'DemoReviewPaymentModal',
  ({ showModal, onClose, paymentAmount, bankName, paymentSubmit, lenderName, paymentDate, isRecurring }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const paymentDayOfMonth = DateTime.fromJSDate(paymentDate).day.valueOf();
    return (
      <WillowModal showModal={showModal} onClose={onClose}>
        <div className="modal-confirm-payment">
          <h2>Review Payment Details</h2>

          <div className="modal-confirm-payment__copy">
            Once confirmed, you authorize {lenderName} to initiate payment from your designated account.
          </div>

          <ul className="modal-confirm-payment__details">
            <li>
              <div className="modal-confirm-payment__details__title">Amount Total</div>
              <div className="modal-confirm-payment__details__desc">{paymentAmountFormat(paymentAmount)}</div>
            </li>
            <li>
              <div className="modal-confirm-payment__details__title">From Account</div>
              <div className="modal-confirm-payment__details__desc">{bankName}</div>
            </li>
            <li>
              <div className="modal-confirm-payment__details__title">Frequency</div>
              <div className="modal-confirm-payment__details__desc">
                {isRecurring ? (
                  <>Monthly on {convertNumberToOrdinal(paymentDayOfMonth)} of the month starting</>
                ) : (
                  <>One-time on</>
                )}{' '}
                {formatDateFromDate(paymentDate)}
              </div>
            </li>
          </ul>

          <div className="d-flex justify-content-end">
            <div className="d-flex gap-2 mt-3">
              <Button size="sm" variant="secondary" onClick={onClose} disabled={isSubmitting}>
                Cancel
              </Button>
              <Button
                size="sm"
                onClick={() => {
                  setIsSubmitting(true);
                  paymentSubmit();
                }}
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Confirm Payment
              </Button>
            </div>
          </div>
        </div>
      </WillowModal>
    );
  },
);
