import { createContext, ReactNode } from 'react';

import { PortalCompanyAdminSettings } from '@willow/graphql-iso/src/portal';
import { AppContext as AppContextEnv } from '@willow/shared-iso';
import { NamedMemo, useSentryContext } from '@willow/shared-web';
import { BorrowerId, PortalUserId } from '@willow/types-iso';

import { PortalSelectedLoan } from '../../App';
import { FeatureFlagContextProvider } from '../../contexts/featureFlagContext';

export type PortalUser = {
  id: PortalUserId;
  borrowerId: BorrowerId;
  email: string;
  phoneNumbers: {
    home?: string;
    work?: string;
    cell?: string;
  };
  firstName: string;
  lastName: string;
  acceptedTerms: boolean;
  isAdmin: boolean;
};

export type EmptyPortalUser = {
  id: undefined;
  borrowerId: undefined;
  email: undefined;
  phoneNumbers: {
    home: undefined;
    work: undefined;
    cell: undefined;
  };
  firstName: undefined;
  lastName: undefined;
  acceptedTerms: boolean;
  isAdmin: boolean;
};

export const EmptyUserContext: PortalUser | EmptyPortalUser = {
  id: undefined,
  borrowerId: undefined,
  email: undefined,
  phoneNumbers: {
    home: undefined,
    work: undefined,
    cell: undefined,
  },
  firstName: undefined,
  lastName: undefined,
  acceptedTerms: false,
  isAdmin: false,
};

export const UserContext = createContext<PortalUser | EmptyPortalUser>(EmptyUserContext);

export const EnvironmentContext = createContext<AppContextEnv>('development');

export const AppContext = NamedMemo<{
  user: PortalUser | EmptyPortalUser;
  selectedLoan: PortalSelectedLoan | undefined;
  allCompanySettings: PortalCompanyAdminSettings[] | undefined;
  environment: AppContextEnv;
  children: ReactNode;
}>('AppContext', ({ children, allCompanySettings, selectedLoan, user, environment }) => {
  useSentryContext(user);
  return (
    <EnvironmentContext.Provider value={environment}>
      <UserContext.Provider value={user}>
        <FeatureFlagContextProvider allCompanySettings={allCompanySettings} selectedLoan={selectedLoan}>
          {children}
        </FeatureFlagContextProvider>
      </UserContext.Provider>
    </EnvironmentContext.Provider>
  );
});
