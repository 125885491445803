import * as z from 'zod';

import { zodDateOrString } from '../../utils';

export const FannieMaeCRSV1 = z.object({
  version: z.literal('1'),
  lenderNumber: z.string(),
  remittanceCode: z.string(),
  draftAmount: z.number(),
  fannieMaeLoanNumber: z.string(),
  draftSettlementDate: zodDateOrString.optional(),
});
export type FannieMaeCRSV1 = z.infer<typeof FannieMaeCRSV1>;

export const FannieMaeCRS = FannieMaeCRSV1;
export type FannieMaeCRS = z.infer<typeof FannieMaeCRS>;
// Make `FannieMaeCRS` a discriminated union on 'version' when changes happen
// export const FannieMaeCRSV2 = z.object({
//   version: z.literal('2'),
//   foo: z.string(),
// });
// export const FannieMaeCRS = z.discriminatedUnion('version', [FannieMaeCRSV1, FannieMaeCRSV02012025]);
