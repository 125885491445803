import { z } from 'zod';

import { PayoffId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const ReversePayoffEvent = BaseLoanEvent.extend({
  type: z.literal('reversePayoff'),
  payoffId: zodBrandedUuid<PayoffId>(),
  comment: z.string().optional(),
});
export type ReversePayoffEvent = z.infer<typeof ReversePayoffEvent>;
