import { z } from 'zod';

import { AppraisalId, PropertyId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils';

export const Appraisal = z.object({
  id: zodBrandedUuid<AppraisalId>(),
  propertyId: zodBrandedUuid<PropertyId>().optional(), // TODO migration required
  amount: z.number().nonnegative(),
  date: zodDateOrString,
  createdAt: zodDateOrString,
  updatedAt: zodDateOrString,
});

export type Appraisal = z.infer<typeof Appraisal>;

export const AppraisalInput = Appraisal.pick({ amount: true, date: true });
export type AppraisalInput = z.infer<typeof AppraisalInput>;
