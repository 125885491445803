import classNames from 'classnames';

import { ControlledSelect, useZodHookForm } from '@willow/shared-web';
import { parseGqlIsoDate } from '@willow/types-iso';

import { PortalSelectedLoan } from '../../App';
import { Metadata } from '../../components/metadata/Metadata';
import { PageHeading } from '../../components/page-heading/PageHeading';
import { UploadDocument } from '../../components/upload-document/UploadDocument';
import { ViewDocuments } from '../../components/view-documents/ViewDocuments';
import { DocumentTableShape, getDocumentTypeOptions } from './form';

import s from './Documents.module.scss';

interface Props {
  loan: PortalSelectedLoan;
}

export const DocumentsPage = ({ loan }: Props) => {
  const { currentSnapshot } = loan;
  const { paymentsDue, transferData } = currentSnapshot;

  const DOCUMENT_OPTIONS = getDocumentTypeOptions();
  const { control, watch } = useZodHookForm(DocumentTableShape, {
    defaultValues: {
      type: 'current',
    },
  });
  const selectedDocumentType = watch('type');

  return (
    <>
      <Metadata metadata={{ title: 'Documents' }} />

      <PageHeading title="Documents" subtitle="Download and view all loan documents here" />

      <div className="u-container mt-4">
        <div className={classNames(s.documentOptions, 'flex-wrap gap-3 justify-content-between align-items-center')}>
          <div className={classNames(s.documentTypeFilter, 'w-100')}>
            <ControlledSelect options={DOCUMENT_OPTIONS} control={control} fieldName="type" isClearable={false} />
          </div>

          {selectedDocumentType === 'current' && <UploadDocument company={loan.company} loanId={loan.id} />}
        </div>

        <div className="mt-2">
          <ViewDocuments
            documentType={selectedDocumentType}
            loanId={loan.id}
            paymentsDue={paymentsDue}
            transferDate={
              transferData?.transferEffectiveDate ? parseGqlIsoDate(transferData.transferEffectiveDate) : undefined
            }
          />
        </div>
      </div>
    </>
  );
};
