import * as z from 'zod';

import { TeamPermissionsId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils';

export const TeamPermissionsActions = z.object({
  createLoans: z.boolean(),
  createPayments: z.boolean(),
  createFees: z.boolean(),
  createDepositsOrTransfers: z.boolean(),
  createDisbursementsFromEscrow: z.boolean(),
  createDisbursementsFromServicingTransfer: z.boolean(),
  createDisbursementsFromAdvance: z.boolean(),
  createDisbursementsFromPayoff: z.boolean(),
  createDisbursementsFromDraw: z.boolean(),

  createPayoffStatements: z.boolean(),
  createLoanDocuments: z.boolean(),
  createWorkoutPlans: z.boolean(),
  createDefaultInterest: z.boolean(),
  createReports: z.boolean(),
  createPrincipalModifications: z.boolean(),
  createWriteOffs: z.boolean(),
  createForm1098s: z.boolean(),
  createEscrowAnalysis: z.boolean(),

  updateAuthorizedContacts: z.boolean(),
  updateMaturityDate: z.boolean(),
  updateInterestRate: z.boolean(),
  updateLoanStatus: z.boolean(),
  updateLoanIdsAndRemittance: z.boolean(),
  updateLoanData: z.boolean(),
  updateConstructionToPerm: z.boolean(),
  updateStopSettings: z.boolean(),
  updateBankingDetails: z.boolean(),
  updateEscrowDetails: z.boolean(),

  // TODO: Deprecate
  createDisbursements: z.boolean().optional(),
  createDraws: z.boolean().optional(),
  updateBorrowerDetails: z.boolean().optional(),
});
export type TeamPermissionsActions = z.infer<typeof TeamPermissionsActions>;

export const TeamPermissions = z.object({
  id: zodBrandedUuid<TeamPermissionsId>(),
  actions: TeamPermissionsActions,
  // Later...
  // tasks: TaskPermissions,
  // workflows: WorkflowPermissions,
});
export type TeamPermissions = z.infer<typeof TeamPermissions>;

export const getDefaultTeamPermissionActions = (defaultValue = false): TeamPermissionsActions => ({
  createLoans: defaultValue,
  createPayments: defaultValue,
  createFees: defaultValue,
  createDepositsOrTransfers: defaultValue,
  createDisbursementsFromEscrow: defaultValue,
  createDisbursementsFromServicingTransfer: defaultValue,
  createDisbursementsFromAdvance: defaultValue,
  createDisbursementsFromPayoff: defaultValue,
  createDisbursementsFromDraw: defaultValue,

  createPayoffStatements: defaultValue,
  createLoanDocuments: defaultValue,
  createWorkoutPlans: defaultValue,
  createReports: defaultValue,
  createDefaultInterest: defaultValue,
  createPrincipalModifications: defaultValue,
  createWriteOffs: defaultValue,

  updateAuthorizedContacts: defaultValue,
  updateMaturityDate: defaultValue,
  updateInterestRate: defaultValue,
  updateLoanStatus: defaultValue,
  updateLoanIdsAndRemittance: defaultValue,
  updateLoanData: defaultValue,
  updateConstructionToPerm: defaultValue,
  updateStopSettings: defaultValue,

  createForm1098s: defaultValue,
  createEscrowAnalysis: defaultValue,
  updateBankingDetails: defaultValue,
  updateEscrowDetails: defaultValue,
});
