import { z } from 'zod';

import { zodDateOrString } from '../../utils';
import { NonNegativeMonetaryValue } from '../../validations/fields';

/**
 * Foreclosure Collected Data
 */
export const ForeclosureStepDemandLettersCollectedData = z.object({
  type: z.literal('foreclosureStepDemandLetters'),
  expirationDate: zodDateOrString,
  sentDate: zodDateOrString,
  mailService: z.string().optional(),
  trackingNumber: z.string().optional(),
});
export type ForeclosureStepDemandLettersCollectedData = z.infer<typeof ForeclosureStepDemandLettersCollectedData>;

export const ForeclosureStepReferAttorneyCollectedData = z.object({
  type: z.literal('referForeclosureAttorney'),
  contactDate: zodDateOrString,
  lawFirm: z.string().optional(),
  attorneyContactName: z.string(),
  attorneyPhone: z.string(),
});
export type ForeclosureStepReferAttorneyCollectedData = z.infer<typeof ForeclosureStepReferAttorneyCollectedData>;

export const ForeclosureStepJudicialStateCollectedData = z.object({
  type: z.literal('judicialState'),
  foreclosureCourtFilingDate: zodDateOrString,
  hearingDate: zodDateOrString,
  foreclosureSaleDate: zodDateOrString,
  saleContestedByBorrower: z.boolean(),
  hearingOutcome: z.union([
    z.literal('foreclosureApproved'),
    z.literal('foreclosureDenied'),
    z.literal('foreclosureHeld'),
  ]),
  minimumBid: NonNegativeMonetaryValue,
});
export type ForeclosureStepJudicialStateCollectedData = z.infer<typeof ForeclosureStepJudicialStateCollectedData>;

export const ForeclosureStepNonJudicialStateCollectedData = z.object({
  type: z.literal('nonJudicialState'),
  propertyValuation: NonNegativeMonetaryValue,
  foreclosureSaleDate: zodDateOrString,
  thirdPartySaleAgent: z.string(),
  minimumBid: NonNegativeMonetaryValue,
});
export type ForeclosureStepNonJudicialStateCollectedData = z.infer<typeof ForeclosureStepNonJudicialStateCollectedData>;

export const ForeclosureStepHomeSaleCollectedData = z.object({
  type: z.literal('homeSale'),
  ownership: z.union([z.literal('thirdParty'), z.literal('bank')]),
  bidAmount: NonNegativeMonetaryValue,
  closingDate: zodDateOrString,
  thirdPartyContactName: z.string().optional(),
  thirdPartyPhone: z.string().optional(),
  bankName: z.string().optional(),
  bankPhone: z.string().optional(),
});
export type ForeclosureStepHomeSaleCollectedData = z.infer<typeof ForeclosureStepHomeSaleCollectedData>;

export const ForeclosureStepCollectedData = z.discriminatedUnion('type', [
  ForeclosureStepDemandLettersCollectedData,
  ForeclosureStepReferAttorneyCollectedData,
  ForeclosureStepJudicialStateCollectedData,
  ForeclosureStepNonJudicialStateCollectedData,
  ForeclosureStepHomeSaleCollectedData,
]);
export type ForeclosureStepCollectedData = z.infer<typeof ForeclosureStepCollectedData>;
