import { DateTime } from 'luxon';

import { CompanyReportType } from '../../CompanyReport';
import { RecurringCompanyReport } from '../../RecurringCompanyReport';
import { Timezone } from '../../Time';

export const FREDDIE_MAC_TIMEZONE: Timezone = 'America/New_York' as const;
export const FREDDIE_MAC_HOLIDAYS: DateTime[] = [
  DateTime.fromObject({ year: 2025, month: 1, day: 1 }),
  DateTime.fromObject({ year: 2025, month: 1, day: 20 }),
  DateTime.fromObject({ year: 2025, month: 2, day: 17 }),
  DateTime.fromObject({ year: 2025, month: 5, day: 26 }),
  DateTime.fromObject({ year: 2025, month: 6, day: 19 }),
  DateTime.fromObject({ year: 2025, month: 7, day: 4 }),
  DateTime.fromObject({ year: 2025, month: 9, day: 1 }),
  DateTime.fromObject({ year: 2025, month: 11, day: 11 }),
  DateTime.fromObject({ year: 2025, month: 11, day: 27 }),
  DateTime.fromObject({ year: 2025, month: 11, day: 28 }),
  DateTime.fromObject({ year: 2025, month: 12, day: 25 }),
] as const;

export const FREDDIE_MAC_DEFAULT_RECURRING_REPORT_VALUES: Record<
  | CompanyReportType.FREDDIE_MAC_LLR_DAILY
  | CompanyReportType.FREDDIE_MAC_LLR_MONTHLY
  | CompanyReportType.FREDDIE_MAC_LLR_TRAILING,
  Pick<RecurringCompanyReport, 'deliveryHour' | 'deliveryTimeZone' | 'title'> & { deliveryDay: number }
> = {
  FREDDIE_MAC_LLR_MONTHLY: {
    deliveryHour: 5,
    deliveryTimeZone: FREDDIE_MAC_TIMEZONE,
    title: 'Freddie Mac LLR Monthly',
    deliveryDay: 15,
  },
  FREDDIE_MAC_LLR_DAILY: {
    deliveryHour: 5,
    deliveryTimeZone: FREDDIE_MAC_TIMEZONE,
    title: 'Freddie Mac LLR Daily',
    deliveryDay: 0,
  },
  FREDDIE_MAC_LLR_TRAILING: {
    deliveryHour: 5,
    deliveryTimeZone: FREDDIE_MAC_TIMEZONE,
    title: 'Freddie Mac LLR Trailing',
    deliveryDay: 1,
  },
} as const;
