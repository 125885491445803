import { z } from 'zod';

import { MERSMIN, MERSOrgId } from '../BrandedIds';
import { zodBrandedString, zodDateOrString } from '../utils';

export const MERSMINStatus = z.enum(['ACTIVE', 'INACTIVE']);
export type MERSMINStatus = z.infer<typeof MERSMINStatus>;

/**
 * Mortgage Electronic Registration System (MERS) is a database that tracks all U.S. originated mortgages
 * https://www.mersinc.org/publicdocs/MERS_System_Patron_Member_Guide.pdf
 *
 * Note: these MERS fields remain the same for the life of a loan
 */
export const MERS = z.object({
  registrationDate: zodDateOrString.optional(),
  MIN: zodBrandedString<MERSMIN>().optional(), // Mortgage Identification Number (MIN) assigned to each loan for tracking throughout the life of a loan
  MINStatus: MERSMINStatus.optional(),
  orgId: zodBrandedString<MERSOrgId>().optional(), // Organization ID assigned to each member (aka the lender)
  momFlag: z.boolean().optional(), // MERS as Original Mortgagee
});
export type MERS = z.infer<typeof MERS>;
