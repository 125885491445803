import * as z from 'zod';

import { LosId } from '../../BrandedIds';
import { zodBrandedString } from '../../utils';
import { OptionalEmailString } from '../email';

export const ManagePointOfContactRow = z.object({
  loanId: zodBrandedString<LosId>(),
  pointOfContact: OptionalEmailString.describe(
    'Email address of internal user who is the primary point of contact. Leaving this field blank removes the point of contact',
  ), // lender user email address. undefined value removes the POC
});
export type ManagePointOfContactRow = z.infer<typeof ManagePointOfContactRow>;

export const MANAGE_POINT_OF_CONTACT_ROW_REQUIRED_FIELDS = Object.keys(ManagePointOfContactRow.shape).filter(
  (key) => !ManagePointOfContactRow.shape[key as keyof typeof ManagePointOfContactRow.shape].isOptional(),
);
