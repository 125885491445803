import { ReactNode } from 'react';
import ReactSelect, { GroupBase, OptionsOrGroups, Props as SelectProps } from 'react-select';
import Creatable, { CreatableProps } from 'react-select/creatable';

import { WILLOW_SELECT_STYLES, WILLOW_SELECT_THEME } from './styles';

export type Option = { label: ReactNode | JSX.Element; value: string | number; disabled?: true };

export type Options = OptionsOrGroups<any, GroupBase<any>> | Option[];

export type ReactSelectOverrides = Pick<SelectProps, 'menuPosition'>;

export function WillowSelect<TOptions extends Options, TIsMulti extends boolean>(
  props: SelectProps<TOptions, TIsMulti>,
) {
  return <ReactSelect styles={WILLOW_SELECT_STYLES} theme={WILLOW_SELECT_THEME} {...props} />;
}

export function WillowCreatableSelect<TOption extends Option, TIsMulti extends boolean>(
  props: CreatableProps<TOption, TIsMulti, GroupBase<TOption>>,
) {
  return <Creatable styles={WILLOW_SELECT_STYLES} theme={WILLOW_SELECT_THEME} {...props} />;
}
