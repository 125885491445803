import { z } from 'zod';

import { PaymentId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const RepayMarkAsFailedEvent = BaseLoanEvent.extend({
  type: z.literal('repayMarkAsFailed'),
  paymentId: zodBrandedUuid<PaymentId>(),
});
export type RepayMarkAsFailedEvent = z.infer<typeof RepayMarkAsFailedEvent>;
