/*
  Two input fields with calendar picker: start date + end date
*/

import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';

import { isReactDatePickerValid } from '../../utils/dateFormat';
import { NamedMemo } from '../NamedMemo';

import 'react-datepicker/dist/react-datepicker.css';
import './DateRangePicker.scss';

interface Props {
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  label?: string;
  onSelection: ({ startDate, endDate }: { startDate?: Date; endDate?: Date }) => void;
  className?: string;
}

export const DateRangePicker = NamedMemo<Props>(
  'DateRangePicker',
  ({ startDate: startDateProps, endDate: endDateProps, onSelection, label, className }) => {
    const [startDate, setStartDate] = useState<Date | undefined>(startDateProps);
    const [endDate, setEndDate] = useState<Date | undefined>(endDateProps);

    useEffect(() => {
      if (startDateProps !== startDate) {
        setStartDate(startDateProps);
      }
      if (endDateProps !== endDate) {
        setEndDate(endDateProps);
      }
    }, [startDateProps, endDateProps, startDate, endDate]);

    const onSetStartDate = useCallback(
      (date: Date) => {
        if (date && !isReactDatePickerValid(date)) {
          toast.error('Please enter valid start date');
        }
        setStartDate(date);
        onSelection({ startDate: date, endDate });
      },
      [endDate, onSelection],
    );

    const onSetEndDate = useCallback(
      (date: Date) => {
        if (date && !isReactDatePickerValid(date)) {
          toast.error('Please enter valid end date');
        }

        setEndDate(date);
        onSelection({ startDate, endDate: date });
      },
      [startDate, onSelection],
    );

    return (
      <div className={classnames('date-range-picker__container', className)}>
        {label && <h2 className="date-range-picker__text">{label}</h2>}
        <DatePicker
          maxDate={endDate}
          selected={startDate}
          onChange={onSetStartDate}
          placeholderText="Start Date"
          isClearable
          autoComplete="off"
        />
        to
        <DatePicker
          minDate={startDate}
          selected={endDate}
          onChange={onSetEndDate}
          placeholderText="End Date"
          isClearable
          autoComplete="off"
        />
      </div>
    );
  },
);
