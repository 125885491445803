import * as z from 'zod';

import { zodDateOrString } from '../../../utils/Zod';
import {
  AddressLine,
  Checkbox,
  DateString,
  NonNegativeInteger,
  NonNegativeMonetaryValue,
  StateAbbreviation,
  Zipcode,
} from '../../fields';

export const AddressFloodFields = z.object({
  zone: z.string().optional(),
  zoneFlag: Checkbox.optional(),
  partialZoneIndicator: z.string().optional(),
  contractType: z.string().optional(),
  contractNumber: z.string().optional(),
  program: z.string().optional(),
  nfipDateRevisionType: z.string().optional(),
  nfipCommunityIdentifier: z.string().optional(),
  nfipMapIdentifier: z.string().optional(),
  nfipMapPanelDate: DateString.optional(),
  certificateProviderName: z.string().optional(),
  certificateNumber: z.string().optional(),
  determinationDate: DateString.optional(),
});

const AddressAppraisalCreateFields = z.object({
  amount: NonNegativeMonetaryValue.optional(),
  date: DateString.optional(),
});
const AddressAppraisalImportFields = z.object({
  ...AddressAppraisalCreateFields.shape,
  date: zodDateOrString.optional(),
});

const AddressTransferableMonitoringContractsFields = z.object({
  flood: Checkbox.optional(),
  tax: Checkbox.optional(),
});

export const AddressFields = z.object({
  addressLine1: AddressLine,
  addressLine2: AddressLine.optional(),
  addressLine3: AddressLine.optional(),
  addressLine4: AddressLine.optional(),
  addressLocality: z.string(),
  addressRegion: StateAbbreviation,
  addressPostcode: Zipcode,
  addressCounty: z.string().optional(),
  addressCountry: z.string().optional(),
  addressCoverageAmount: NonNegativeMonetaryValue.optional(),
  addressReplacementCostValue: NonNegativeMonetaryValue.optional(),
  addressPropertyType: z.string().optional(),
  addressPropertySubType: z.string().optional(),
  addressFloodZone: z.string().optional(), // legacy field
  addressFloodInformation: AddressFloodFields.optional(),
  addressPurchasePrice: NonNegativeMonetaryValue.optional(),
  addressNumberOfUnits: NonNegativeInteger.optional(),
  addressPropertyValuationMethodType: z.string().optional(),
  addressAppraisal: AddressAppraisalCreateFields.optional(),
  addressTransferableMonitoringContracts: AddressTransferableMonitoringContractsFields.optional(),

  address2Line1: AddressLine.optional(),
  address2Line2: AddressLine.optional(),
  address2Line3: AddressLine.optional(),
  address2Line4: AddressLine.optional(),
  address2Locality: z.string().optional(),
  address2Region: StateAbbreviation.optional().or(z.literal('')),
  address2Postcode: Zipcode.optional(),
  address2County: z.string().optional(),
  address2Country: z.string().optional(),
  address2CoverageAmount: NonNegativeMonetaryValue.optional(),
  address2ReplacementCostValue: NonNegativeMonetaryValue.optional(),
  address2PropertyType: z.string().optional(),
  address2PropertySubType: z.string().optional(),
  address2FloodZone: z.string().optional(),
  address2FloodInformation: AddressFloodFields.optional(),
  address2PurchasePrice: NonNegativeMonetaryValue.optional(),
  address2NumberOfUnits: NonNegativeInteger.optional(),
  address2PropertyValuationMethodType: z.string().optional(),
  address2Appraisal: AddressAppraisalCreateFields.optional(),
  address2TransferableMonitoringContracts: AddressTransferableMonitoringContractsFields.optional(),

  address3Line1: AddressLine.optional(),
  address3Line2: AddressLine.optional(),
  address3Line3: AddressLine.optional(),
  address3Line4: AddressLine.optional(),
  address3Locality: z.string().optional(),
  address3Region: StateAbbreviation.optional().or(z.literal('')),
  address3Postcode: Zipcode.optional(),
  address3County: z.string().optional(),
  address3Country: z.string().optional(),
  address3CoverageAmount: NonNegativeMonetaryValue.optional(),
  address3ReplacementCostValue: NonNegativeMonetaryValue.optional(),
  address3PropertyType: z.string().optional(),
  address3PropertySubType: z.string().optional(),
  address3FloodZone: z.string().optional(),
  address3FloodInformation: AddressFloodFields.optional(),
  address3PurchasePrice: NonNegativeMonetaryValue.optional(),
  address3NumberOfUnits: NonNegativeInteger.optional(),
  address3PropertyValuationMethodType: z.string().optional(),
  address3Appraisal: AddressAppraisalCreateFields.optional(),
  address3TransferableMonitoringContracts: AddressTransferableMonitoringContractsFields.optional(),
  address4Line1: AddressLine.optional(),
  address4Line2: AddressLine.optional(),
  address4Line3: AddressLine.optional(),
  address4Line4: AddressLine.optional(),
  address4Locality: z.string().optional(),
  address4Region: StateAbbreviation.optional().or(z.literal('')),
  address4Postcode: Zipcode.optional(),
  address4County: z.string().optional(),
  address4Country: z.string().optional(),
  address4CoverageAmount: NonNegativeMonetaryValue.optional(),
  address4ReplacementCostValue: NonNegativeMonetaryValue.optional(),
  address4PropertyType: z.string().optional(),
  address4PropertySubType: z.string().optional(),
  address4FloodZone: z.string().optional(),
  address4FloodInformation: AddressFloodFields.optional(),
  address4PurchasePrice: NonNegativeMonetaryValue.optional(),
  address4NumberOfUnits: NonNegativeInteger.optional(),
  address4PropertyValuationMethodType: z.string().optional(),
  address4Appraisal: AddressAppraisalCreateFields.optional(),
  address4TransferableMonitoringContracts: AddressTransferableMonitoringContractsFields.optional(),
  address5Line1: AddressLine.optional(),
  address5Line2: AddressLine.optional(),
  address5Line3: AddressLine.optional(),
  address5Line4: AddressLine.optional(),
  address5Locality: z.string().optional(),
  address5Region: StateAbbreviation.optional().or(z.literal('')),
  address5Postcode: Zipcode.optional(),
  address5County: z.string().optional(),
  address5Country: z.string().optional(),
  address5CoverageAmount: NonNegativeMonetaryValue.optional(),
  address5ReplacementCostValue: NonNegativeMonetaryValue.optional(),
  address5PropertyType: z.string().optional(),
  address5PropertySubType: z.string().optional(),
  address5FloodZone: z.string().optional(),
  address5FloodInformation: AddressFloodFields.optional(),
  address5PurchasePrice: NonNegativeMonetaryValue.optional(),
  address5NumberOfUnits: NonNegativeInteger.optional(),
  address5PropertyValuationMethodType: z.string().optional(),
  address5Appraisal: AddressAppraisalCreateFields.optional(),
  address5TransferableMonitoringContracts: AddressTransferableMonitoringContractsFields.optional(),

  address6Line1: AddressLine.optional(),
  address6Line2: AddressLine.optional(),
  address6Line3: AddressLine.optional(),
  address6Line4: AddressLine.optional(),
  address6Locality: z.string().optional(),
  address6Region: StateAbbreviation.optional().or(z.literal('')),
  address6Postcode: Zipcode.optional(),
  address6County: z.string().optional(),
  address6Country: z.string().optional(),
  address6CoverageAmount: NonNegativeMonetaryValue.optional(),
  address6ReplacementCostValue: NonNegativeMonetaryValue.optional(),
  address6PropertyType: z.string().optional(),
  address6PropertySubType: z.string().optional(),
  address6FloodZone: z.string().optional(),
  address6FloodInformation: AddressFloodFields.optional(),
  address6PurchasePrice: NonNegativeMonetaryValue.optional(),
  address6NumberOfUnits: NonNegativeInteger.optional(),
  address6PropertyValuationMethodType: z.string().optional(),
  address6Appraisal: AddressAppraisalCreateFields.optional(),
  address6TransferableMonitoringContracts: AddressTransferableMonitoringContractsFields.optional(),

  address7Line1: AddressLine.optional(),
  address7Line2: AddressLine.optional(),
  address7Line3: AddressLine.optional(),
  address7Line4: AddressLine.optional(),
  address7Locality: z.string().optional(),
  address7Region: StateAbbreviation.optional().or(z.literal('')),
  address7Postcode: Zipcode.optional(),
  address7County: z.string().optional(),
  address7Country: z.string().optional(),
  address7CoverageAmount: NonNegativeMonetaryValue.optional(),
  address7ReplacementCostValue: NonNegativeMonetaryValue.optional(),
  address7PropertyType: z.string().optional(),
  address7PropertySubType: z.string().optional(),
  address7FloodZone: z.string().optional(),
  address7FloodInformation: AddressFloodFields.optional(),
  address7PurchasePrice: NonNegativeMonetaryValue.optional(),
  address7NumberOfUnits: NonNegativeInteger.optional(),
  address7PropertyValuationMethodType: z.string().optional(),
  address7Appraisal: AddressAppraisalCreateFields.optional(),
  address7TransferableMonitoringContracts: AddressTransferableMonitoringContractsFields.optional(),

  address8Line1: AddressLine.optional(),
  address8Line2: AddressLine.optional(),
  address8Line3: AddressLine.optional(),
  address8Line4: AddressLine.optional(),
  address8Locality: z.string().optional(),
  address8Region: StateAbbreviation.optional().or(z.literal('')),
  address8Postcode: Zipcode.optional(),
  address8County: z.string().optional(),
  address8Country: z.string().optional(),
  address8CoverageAmount: NonNegativeMonetaryValue.optional(),
  address8ReplacementCostValue: NonNegativeMonetaryValue.optional(),
  address8PropertyType: z.string().optional(),
  address8PropertySubType: z.string().optional(),
  address8FloodZone: z.string().optional(),
  address8FloodInformation: AddressFloodFields.optional(),
  address8PurchasePrice: NonNegativeMonetaryValue.optional(),
  address8NumberOfUnits: NonNegativeInteger.optional(),
  address8PropertyValuationMethodType: z.string().optional(),
  address8Appraisal: AddressAppraisalCreateFields.optional(),
  address8TransferableMonitoringContracts: AddressTransferableMonitoringContractsFields.optional(),

  address9Line1: AddressLine.optional(),
  address9Line2: AddressLine.optional(),
  address9Line3: AddressLine.optional(),
  address9Line4: AddressLine.optional(),
  address9Locality: z.string().optional(),
  address9Region: StateAbbreviation.optional().or(z.literal('')),
  address9Postcode: Zipcode.optional(),
  address9County: z.string().optional(),
  address9Country: z.string().optional(),
  address9CoverageAmount: NonNegativeMonetaryValue.optional(),
  address9ReplacementCostValue: NonNegativeMonetaryValue.optional(),
  address9PropertyType: z.string().optional(),
  address9PropertySubType: z.string().optional(),
  address9FloodZone: z.string().optional(),
  address9FloodInformation: AddressFloodFields.optional(),
  address9PurchasePrice: NonNegativeMonetaryValue.optional(),
  address9NumberOfUnits: NonNegativeInteger.optional(),
  address9PropertyValuationMethodType: z.string().optional(),
  address9Appraisal: AddressAppraisalCreateFields.optional(),
  address9TransferableMonitoringContracts: AddressTransferableMonitoringContractsFields.optional(),

  address10Line1: AddressLine.optional(),
  address10Line2: AddressLine.optional(),
  address10Line3: AddressLine.optional(),
  address10Line4: AddressLine.optional(),
  address10Locality: z.string().optional(),
  address10Region: StateAbbreviation.optional().or(z.literal('')),
  address10Postcode: Zipcode.optional(),
  address10County: z.string().optional(),
  address10Country: z.string().optional(),
  address10CoverageAmount: NonNegativeMonetaryValue.optional(),
  address10ReplacementCostValue: NonNegativeMonetaryValue.optional(),
  address10PropertyType: z.string().optional(),
  address10PropertySubType: z.string().optional(),
  address10FloodZone: z.string().optional(),
  address10FloodInformation: AddressFloodFields.optional(),
  address10PurchasePrice: NonNegativeMonetaryValue.optional(),
  address10NumberOfUnits: NonNegativeInteger.optional(),
  address10PropertyValuationMethodType: z.string().optional(),
  address10Appraisal: AddressAppraisalCreateFields.optional(),
  address10TransferableMonitoringContracts: AddressTransferableMonitoringContractsFields.optional(),

  address11Line1: AddressLine.optional(),
  address11Line2: AddressLine.optional(),
  address11Line3: AddressLine.optional(),
  address11Line4: AddressLine.optional(),
  address11Locality: z.string().optional(),
  address11Region: StateAbbreviation.optional().or(z.literal('')),
  address11Postcode: Zipcode.optional(),
  address11County: z.string().optional(),
  address11Country: z.string().optional(),
  address11CoverageAmount: NonNegativeMonetaryValue.optional(),
  address11ReplacementCostValue: NonNegativeMonetaryValue.optional(),
  address11PropertyType: z.string().optional(),
  address11PropertySubType: z.string().optional(),
  address11FloodZone: z.string().optional(),
  address11FloodInformation: AddressFloodFields.optional(),
  address11PurchasePrice: NonNegativeMonetaryValue.optional(),
  address11NumberOfUnits: NonNegativeInteger.optional(),
  address11PropertyValuationMethodType: z.string().optional(),
  address11Appraisal: AddressAppraisalCreateFields.optional(),
  address11TransferableMonitoringContracts: AddressTransferableMonitoringContractsFields.optional(),

  address12Line1: AddressLine.optional(),
  address12Line2: AddressLine.optional(),
  address12Line3: AddressLine.optional(),
  address12Line4: AddressLine.optional(),
  address12Locality: z.string().optional(),
  address12Region: StateAbbreviation.optional().or(z.literal('')),
  address12Postcode: Zipcode.optional(),
  address12County: z.string().optional(),
  address12Country: z.string().optional(),
  address12CoverageAmount: NonNegativeMonetaryValue.optional(),
  address12ReplacementCostValue: NonNegativeMonetaryValue.optional(),
  address12PropertyType: z.string().optional(),
  address12PropertySubType: z.string().optional(),
  address12FloodZone: z.string().optional(),
  address12FloodInformation: AddressFloodFields.optional(),
  address12PurchasePrice: NonNegativeMonetaryValue.optional(),
  address12NumberOfUnits: NonNegativeInteger.optional(),
  address12PropertyValuationMethodType: z.string().optional(),
  address12Appraisal: AddressAppraisalCreateFields.optional(),
  address12TransferableMonitoringContracts: AddressTransferableMonitoringContractsFields.optional(),

  address13Line1: AddressLine.optional(),
  address13Line2: AddressLine.optional(),
  address13Line3: AddressLine.optional(),
  address13Line4: AddressLine.optional(),
  address13Locality: z.string().optional(),
  address13Region: StateAbbreviation.optional().or(z.literal('')),
  address13Postcode: Zipcode.optional(),
  address13County: z.string().optional(),
  address13Country: z.string().optional(),
  address13CoverageAmount: NonNegativeMonetaryValue.optional(),
  address13ReplacementCostValue: NonNegativeMonetaryValue.optional(),
  address13PropertyType: z.string().optional(),
  address13PropertySubType: z.string().optional(),
  address13FloodZone: z.string().optional(),
  address13FloodInformation: AddressFloodFields.optional(),
  address13PurchasePrice: NonNegativeMonetaryValue.optional(),
  address13NumberOfUnits: NonNegativeInteger.optional(),
  address13PropertyValuationMethodType: z.string().optional(),
  address13Appraisal: AddressAppraisalCreateFields.optional(),
  address13TransferableMonitoringContracts: AddressTransferableMonitoringContractsFields.optional(),

  address14Line1: AddressLine.optional(),
  address14Line2: AddressLine.optional(),
  address14Line3: AddressLine.optional(),
  address14Line4: AddressLine.optional(),
  address14Locality: z.string().optional(),
  address14Region: StateAbbreviation.optional().or(z.literal('')),
  address14Postcode: Zipcode.optional(),
  address14County: z.string().optional(),
  address14Country: z.string().optional(),
  address14CoverageAmount: NonNegativeMonetaryValue.optional(),
  address14ReplacementCostValue: NonNegativeMonetaryValue.optional(),
  address14PropertyType: z.string().optional(),
  address14PropertySubType: z.string().optional(),
  address14FloodZone: z.string().optional(),
  address14FloodInformation: AddressFloodFields.optional(),
  address14PurchasePrice: NonNegativeMonetaryValue.optional(),
  address14NumberOfUnits: NonNegativeInteger.optional(),
  address14PropertyValuationMethodType: z.string().optional(),
  address14Appraisal: AddressAppraisalCreateFields.optional(),
  address14TransferableMonitoringContracts: AddressTransferableMonitoringContractsFields.optional(),

  address15Line1: AddressLine.optional(),
  address15Line2: AddressLine.optional(),
  address15Line3: AddressLine.optional(),
  address15Line4: AddressLine.optional(),
  address15Locality: z.string().optional(),
  address15Region: StateAbbreviation.optional().or(z.literal('')),
  address15Postcode: Zipcode.optional(),
  address15County: z.string().optional(),
  address15Country: z.string().optional(),
  address15CoverageAmount: NonNegativeMonetaryValue.optional(),
  address15ReplacementCostValue: NonNegativeMonetaryValue.optional(),
  address15PropertyType: z.string().optional(),
  address15PropertySubType: z.string().optional(),
  address15FloodZone: z.string().optional(),
  address15FloodInformation: AddressFloodFields.optional(),
  address15PurchasePrice: NonNegativeMonetaryValue.optional(),
  address15NumberOfUnits: NonNegativeInteger.optional(),
  address15PropertyValuationMethodType: z.string().optional(),
  address15Appraisal: AddressAppraisalCreateFields.optional(),
  address15TransferableMonitoringContracts: AddressTransferableMonitoringContractsFields.optional(),
});

export const ImportLoanAddressFields = z.object({
  ...AddressFields.shape,
  addressAppraisal: AddressAppraisalImportFields.optional(),
  address2Appraisal: AddressAppraisalImportFields.optional(),
  address3Appraisal: AddressAppraisalImportFields.optional(),
  address4Appraisal: AddressAppraisalImportFields.optional(),
  address5Appraisal: AddressAppraisalImportFields.optional(),
  address6Appraisal: AddressAppraisalImportFields.optional(),
  address7Appraisal: AddressAppraisalImportFields.optional(),
  address8Appraisal: AddressAppraisalImportFields.optional(),
  address9Appraisal: AddressAppraisalImportFields.optional(),
  address10Appraisal: AddressAppraisalImportFields.optional(),
});
export type ImportLoanAddressFields = z.infer<typeof ImportLoanAddressFields>;
