// eslint-disable-next-line import/no-extraneous-dependencies
import { z } from 'zod';

import { Draw } from '../loan/Draw';
import { BalancePaymentSnapshot } from '../loan/Snapshots';

// Our API fields deviate slightly from what we use internally.
// When we version our API, we may need to version this type as well.

export const TransactionForApi = z.object({
  id: z.string(),
  losId: z
    .string()
    .describe(
      'LOS ID of the loan the transaction occurred on. This LOS ID is provided by the client during loan creation.',
    ),
  externalId: z
    .string()
    .optional()
    .describe(
      'Optional unique ID provided with transactions posted via the API. Maps to idempotencyKey from POST requests.',
    ),
  type: z.enum([
    'PAYMENT',
    'PAYMENT_REVERSAL',
    'DISBURSEMENT',
    'DISBURSEMENT_REVERSAL',
    'SWEEP',
    'DRAW',
    'DRAW_REVERSAL',
    'PAYOFF',
    'PARTIAL_PAYOFF',
    'BOARDING',
    'FEE',
    'FEE_REVERSAL',
    'PAYMENT_DEFERMENT',
    'PAYMENT_DEFERMENT_REVERSAL',
    'DEPOSIT',
    'DEPOSIT_REVERSAL',
    'PRINCIPAL_MODIFICATION',
    'PRINCIPAL_MODIFICATION_REVERSAL',
    'WRITEOFF',
  ]),
  status: z.enum(['PENDING', 'PROCESSED', 'FAILED', 'CANCELLED']),
  disbursementType: z
    .enum(['SERVICING_TRANSFER', 'ESCROW', 'FORWARD_PAYMENT', 'ADVANCE', 'PAYOFF', 'PAYMENT_AFTER_PAYOFF', 'OTHER'])
    .optional()
    .describe('Type of disbursement. Will be null when type != DISBURSEMENT'),
  dateDue: z
    .string()
    .datetime()
    .optional()
    .describe('Date that the transaction was due if it was allocated toward a statement'),
  processedDate: z.string().datetime().optional().describe('Date that the transaction was processed'),
  dateReceived: z.string().datetime().optional().describe('Date that the transaction was received'),
  method: z
    .enum([
      'CHECK',
      'WIRE',
      'PHONE',
      'ACH',
      'AUTHORIZED_ACH',
      'REPAY_ACH',
      'RESERVE',
      'BALANCE_FORWARD',
      'PRINCIPAL_PREPAYMENT',
      'BALANCE_REALLOCATION',
    ])
    .optional(),
  referenceId: z
    .string()
    .optional()
    .describe(
      'Provides contextual information related to the type of transaction. When type = PAYMENT and method = CHECK: referenceId is the check # recorded by lender. When type = PAYMENT and method = ACH: referenceId is the corresponding id of the payment transfer from the payment processor. When type = PAYMENT_REVERSAL: referenceId is the ID of payment that was reversed. When type = DISBURSEMENT_REVERSAL: referenceId is the ID of disbursement that was reversed.',
    ),
  expectedPaymentAmount: z.string().describe('String in monetary format (0.00)'),
  amount: z.string().describe('String in monetary format (0.00)'),
  amountPrincipal: z.string().describe('String in monetary format (0.00)'),
  amountPrincipalPrepayment: z.string().describe('String in monetary format (0.00)'),
  amountInterest: z.string().describe('String in monetary format (0.00)'),
  amountEscrow: z.string().describe('String in monetary format (0.00)'),
  amountFee: z.string().describe('String in monetary format (0.00)'),
  amountSuspense: z.string().describe('String in monetary format (0.00)'),
  balanceSnapshot: BalancePaymentSnapshot,
  achBatch: z.string().optional().describe('ACH Batch id. Only applicable for authorized ACH payments'),
  disbursementRecipient: z.string().optional().describe('null when type != DISBURSEMENT'),
  comment: z.string().optional(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});
export type TransactionForApi = z.infer<typeof TransactionForApi>;

// TODO: Add more transaction types to this union as we support posting them
export type TransactionForApiPostPutType = Draw;
