import { z } from 'zod';

import { PaymentId } from '../BrandedIds';
import { DwollaLenderTransferType } from '../loan/Payments';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

export const InitiateDwollaTransferSideEffectContext = BaseLoanIdSideEffect.extend({
  balanceUrl: z.string(),
  amount: z.number(),
  transactionId: z.string().optional(),
  paymentId: zodBrandedUuid<PaymentId>().optional(),
  transferType: DwollaLenderTransferType,
  sweepType: z.literal('sweepToBalance').optional(), // Deprecated
});
export const InitiateDwollaTransferSideEffect = InitiateDwollaTransferSideEffectContext.extend({
  type: z.literal('initiateDwollaTransfer'),
});
export type InitiateDwollaTransferSideEffect = z.infer<typeof InitiateDwollaTransferSideEffect>;
