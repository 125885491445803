import * as z from 'zod';

import { PhoneNumber } from '../../Phone';
import { OptionalEmailString } from '../email';
import {
  Checkbox,
  dateIsOnFirstOfMonth,
  dateIsWithinRange,
  DateString,
  Float,
  NonNegativeFloat,
  NonNegativeMonetaryValue,
  StateAbbreviation,
  Zipcode,
} from '../fields';

import 'zod-openapi/extend';

// NOTE: If parsing data with TransferLoanRow, make sure
// you trim and strip the data beforehand with the `trimAndStrip` util
export const TransferLoanRow = (allowTransferDateAnyDayOfMonth: boolean) =>
  z.object({
    loanId: z.string(),
    servicerName: z.string(),
    servicerLoanNumber: z.string(),
    transferEffectiveDate: allowTransferDateAnyDayOfMonth
      ? DateString.and(dateIsWithinRange({ months: 361 }))
      : DateString.and(dateIsWithinRange({ months: 361 })).and(dateIsOnFirstOfMonth),
    transferSoldDate: DateString.optional(),
    servicerPhone: PhoneNumber,
    servicerPaymentAddressLine1: z.string(),
    servicerPaymentAddressLine2: z.string().optional(),
    servicerPaymentAddressLine3: z.string().optional(),
    servicerPaymentAddressLine4: z.string().optional(),
    servicerPaymentAddressLocality: z.string(),
    servicerPaymentAddressRegion: StateAbbreviation.or(z.literal('')),
    servicerPaymentAddressPostcode: Zipcode,
    servicerPaymentAddressCountry: z.string().optional(),
    servicerCorrespondenceAddressLine1: z.string().optional(),
    servicerCorrespondenceAddressLine2: z.string().optional(),
    servicerCorrespondenceAddressLine3: z.string().optional(),
    servicerCorrespondenceAddressLine4: z.string().optional(),
    servicerCorrespondenceAddressLocality: z.string().optional(),
    servicerCorrespondenceAddressRegion: StateAbbreviation.optional().or(z.literal('')),
    servicerCorrespondenceAddressPostcode: Zipcode.optional(),
    servicerCorrespondenceAddressCountry: z.string().optional(),
    servicerQwrAddressLine1: z.string().optional(), // qualified written request address
    servicerQwrAddressLine2: z.string().optional(),
    servicerQwrAddressLine3: z.string().optional(),
    servicerQwrAddressLine4: z.string().optional(),
    servicerQwrAddressLocality: z.string().optional(),
    servicerQwrAddressRegion: StateAbbreviation.optional().or(z.literal('')),
    servicerQwrAddressPostcode: Zipcode.optional(),
    servicerQwrAddressCountry: z.string().optional(),
    servicerMortgageeClauseCompanyName: z.string().optional(),
    servicerMortgageeClauseAddressLine1: z.string().optional(),
    servicerMortgageeClauseAddressLine2: z.string().optional(),
    servicerMortgageeClauseAddressLine3: z.string().optional(),
    servicerMortgageeClauseAddressLine4: z.string().optional(),
    servicerMortgageeClauseAddressLocality: z.string().optional(),
    servicerMortgageeClauseAddressRegion: StateAbbreviation.optional().or(z.literal('')),
    servicerMortgageeClauseAddressPostcode: Zipcode.optional(),
    servicerMortgageeClauseAddressCountry: z.string().optional(),
    beginningOfYearPrincipalBalance: NonNegativeMonetaryValue.optional().describe(
      'Note: A loan that has been active since the beginning of the year should include this number.',
    ),
    create1098Form: Checkbox.optional().openapi({
      description: 'Default: true. Set to false to prevent 1098 from generating at year end',
      default: true,
    }),
    loanOfficerName: z.string().optional().describe('Current loan officer’s name'),
    loanOfficerEmail: OptionalEmailString.describe(
      "Current loan officer's email address for borrower to contact with questions about transfer",
    ),
    servicerWebsite: z.string().optional(),
    servicerHours1: z.string().optional(),
    servicerHours2: z.string().optional(),
    servicerHours3: z.string().optional(),
    transferPrincipalBalance: NonNegativeFloat.optional(),
    transferEscrowBalance: Float.optional(),
    transferReserveBalance: NonNegativeFloat.optional(),
    nextServicerCollectsLatePayments: Checkbox.optional().openapi({
      description:
        'Default: false. If true, the any missed payment will no longer be considered late after the transfer date as the expectation is that the next servicer will collect these payments',
      default: false,
    }),
  });
export type TransferLoanRow = z.infer<ReturnType<typeof TransferLoanRow>>;

export const TransferRevisionRow = (allowTransferDateAnyDayOfMonth: boolean) =>
  TransferLoanRow(allowTransferDateAnyDayOfMonth).partial().required({ loanId: true });

// TODO typescript is not happy with this
// export const TRANSFER_LOAN_ROW_REQUIRED_FIELDS = Object.keys(TransferLoanRow(false).shape).filter(
//   (key) => !TransferLoanRow(false).shape[key as any as keyof ReturnType<typeof TransferLoanRow>].isOptional(),
// );

export const TRANSFER_LOAN_ROW_REQUIRED_FIELDS = [
  'loanId',
  'servicerName',
  'servicerLoanNumber',
  'transferEffectiveDate',
  'servicerPhone',
  'servicerPaymentAddressLine1',
  'servicerPaymentAddressLocality',
  'servicerPaymentAddressRegion',
  'servicerPaymentAddressPostcode',
];

export const REVISE_TRANSFER_DETAILS_LOAN_ROW_REQUIRED_FIELDS = ['loanId'];
