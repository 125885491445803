import { z } from 'zod';

import { OptionalPhoneNumber } from '../../../Phone';
import { OptionalEmailString } from '../../email';
import { AddressLine, Checkbox, DateString, NonNegativeMonetaryValue } from '../../fields';

export const InsuranceFields = z.object({
  insurance1Type: z.string().optional().describe('Insurance information for the first property on the loan.'),
  insurance1IsEscrowed: Checkbox.optional(),
  insurance1Amount: NonNegativeMonetaryValue.optional(),
  insurance1AgentName: z.string().optional(),
  insurance1AgentEmail: OptionalEmailString,
  insurance1AgentPhone: OptionalPhoneNumber,
  insurance1CompanyName: z.string().optional(),
  insurance1CompanyAddressLine1: AddressLine.optional(),
  insurance1CompanyAddressLine2: AddressLine.optional(),
  insurance1CompanyAddressCity: z.string().optional(),
  insurance1CompanyAddressState: z.string().optional(),
  insurance1CompanyAddressZip: z.string().optional(),
  insurance1PolicyNumber: z.string().optional(),
  insurance1PolicyStart: DateString.optional(),
  insurance1PolicyEnd: DateString.optional(),

  insurance2Type: z.string().optional(),
  insurance2IsEscrowed: Checkbox.optional(),
  insurance2Amount: NonNegativeMonetaryValue.optional(),
  insurance2AgentName: z.string().optional(),
  insurance2AgentEmail: OptionalEmailString,
  insurance2AgentPhone: OptionalPhoneNumber,
  insurance2CompanyName: z.string().optional(),
  insurance2CompanyAddressLine1: AddressLine.optional(),
  insurance2CompanyAddressLine2: AddressLine.optional(),
  insurance2CompanyAddressCity: z.string().optional(),
  insurance2CompanyAddressState: z.string().optional(),
  insurance2CompanyAddressZip: z.string().optional(),
  insurance2PolicyNumber: z.string().optional(),
  insurance2PolicyStart: DateString.optional(),
  insurance2PolicyEnd: DateString.optional(),

  insurance3Type: z.string().optional(),
  insurance3IsEscrowed: Checkbox.optional(),
  insurance3Amount: NonNegativeMonetaryValue.optional(),
  insurance3AgentName: z.string().optional(),
  insurance3AgentEmail: OptionalEmailString,
  insurance3AgentPhone: OptionalPhoneNumber,
  insurance3CompanyName: z.string().optional(),
  insurance3CompanyAddressLine1: AddressLine.optional(),
  insurance3CompanyAddressLine2: AddressLine.optional(),
  insurance3CompanyAddressCity: z.string().optional(),
  insurance3CompanyAddressState: z.string().optional(),
  insurance3CompanyAddressZip: z.string().optional(),
  insurance3PolicyNumber: z.string().optional(),
  insurance3PolicyStart: DateString.optional(),
  insurance3PolicyEnd: DateString.optional(),

  insurance4Type: z.string().optional(),
  insurance4IsEscrowed: Checkbox.optional(),
  insurance4Amount: NonNegativeMonetaryValue.optional(),
  insurance4AgentName: z.string().optional(),
  insurance4AgentEmail: OptionalEmailString,
  insurance4AgentPhone: OptionalPhoneNumber,
  insurance4CompanyName: z.string().optional(),
  insurance4CompanyAddressLine1: AddressLine.optional(),
  insurance4CompanyAddressLine2: AddressLine.optional(),
  insurance4CompanyAddressCity: z.string().optional(),
  insurance4CompanyAddressState: z.string().optional(),
  insurance4CompanyAddressZip: z.string().optional(),
  insurance4PolicyNumber: z.string().optional(),
  insurance4PolicyStart: DateString.optional(),
  insurance4PolicyEnd: DateString.optional(),

  insurance5Type: z.string().optional(),
  insurance5IsEscrowed: Checkbox.optional(),
  insurance5Amount: NonNegativeMonetaryValue.optional(),
  insurance5AgentName: z.string().optional(),
  insurance5AgentEmail: OptionalEmailString,
  insurance5AgentPhone: OptionalPhoneNumber,
  insurance5CompanyName: z.string().optional(),
  insurance5CompanyAddressLine1: AddressLine.optional(),
  insurance5CompanyAddressLine2: AddressLine.optional(),
  insurance5CompanyAddressCity: z.string().optional(),
  insurance5CompanyAddressState: z.string().optional(),
  insurance5CompanyAddressZip: z.string().optional(),
  insurance5PolicyNumber: z.string().optional(),
  insurance5PolicyStart: DateString.optional(),
  insurance5PolicyEnd: DateString.optional(),

  insurance6Type: z.string().optional(),
  insurance6IsEscrowed: Checkbox.optional(),
  insurance6Amount: NonNegativeMonetaryValue.optional(),
  insurance6AgentName: z.string().optional(),
  insurance6AgentEmail: OptionalEmailString,
  insurance6AgentPhone: OptionalPhoneNumber,
  insurance6CompanyName: z.string().optional(),
  insurance6CompanyAddressLine1: AddressLine.optional(),
  insurance6CompanyAddressLine2: AddressLine.optional(),
  insurance6CompanyAddressCity: z.string().optional(),
  insurance6CompanyAddressState: z.string().optional(),
  insurance6CompanyAddressZip: z.string().optional(),
  insurance6PolicyNumber: z.string().optional(),
  insurance6PolicyStart: DateString.optional(),
  insurance6PolicyEnd: DateString.optional(),
});

export const MultipleAddressesInsuranceFields = z.object({
  // ADDRESS 2
  address2Insurance1Type: z.string().optional(),
  address2Insurance1IsEscrowed: Checkbox.optional(),
  address2Insurance1Amount: NonNegativeMonetaryValue.optional(),
  address2Insurance1AgentName: z.string().optional(),
  address2Insurance1AgentEmail: OptionalEmailString,
  address2Insurance1AgentPhone: OptionalPhoneNumber,
  address2Insurance1CompanyName: z.string().optional(),
  address2Insurance1CompanyAddressLine1: AddressLine.optional(),
  address2Insurance1CompanyAddressLine2: AddressLine.optional(),
  address2Insurance1CompanyAddressCity: z.string().optional(),
  address2Insurance1CompanyAddressState: z.string().optional(),
  address2Insurance1CompanyAddressZip: z.string().optional(),
  address2Insurance1PolicyNumber: z.string().optional(),
  address2Insurance1PolicyStart: DateString.optional(),
  address2Insurance1PolicyEnd: DateString.optional(),

  address2Insurance2Type: z.string().optional(),
  address2Insurance2IsEscrowed: Checkbox.optional(),
  address2Insurance2Amount: NonNegativeMonetaryValue.optional(),
  address2Insurance2AgentName: z.string().optional(),
  address2Insurance2AgentEmail: OptionalEmailString,
  address2Insurance2AgentPhone: OptionalPhoneNumber,
  address2Insurance2CompanyName: z.string().optional(),
  address2Insurance2CompanyAddressLine1: AddressLine.optional(),
  address2Insurance2CompanyAddressLine2: AddressLine.optional(),
  address2Insurance2CompanyAddressCity: z.string().optional(),
  address2Insurance2CompanyAddressState: z.string().optional(),
  address2Insurance2CompanyAddressZip: z.string().optional(),
  address2Insurance2PolicyNumber: z.string().optional(),
  address2Insurance2PolicyStart: DateString.optional(),
  address2Insurance2PolicyEnd: DateString.optional(),

  address2Insurance3Type: z.string().optional(),
  address2Insurance3IsEscrowed: Checkbox.optional(),
  address2Insurance3Amount: NonNegativeMonetaryValue.optional(),
  address2Insurance3AgentName: z.string().optional(),
  address2Insurance3AgentEmail: OptionalEmailString,
  address2Insurance3AgentPhone: OptionalPhoneNumber,
  address2Insurance3CompanyName: z.string().optional(),
  address2Insurance3CompanyAddressLine1: AddressLine.optional(),
  address2Insurance3CompanyAddressLine2: AddressLine.optional(),
  address2Insurance3CompanyAddressCity: z.string().optional(),
  address2Insurance3CompanyAddressState: z.string().optional(),
  address2Insurance3CompanyAddressZip: z.string().optional(),
  address2Insurance3PolicyNumber: z.string().optional(),
  address2Insurance3PolicyStart: DateString.optional(),
  address2Insurance3PolicyEnd: DateString.optional(),

  address2Insurance4Type: z.string().optional(),
  address2Insurance4IsEscrowed: Checkbox.optional(),
  address2Insurance4Amount: NonNegativeMonetaryValue.optional(),
  address2Insurance4AgentName: z.string().optional(),
  address2Insurance4AgentEmail: OptionalEmailString,
  address2Insurance4AgentPhone: OptionalPhoneNumber,
  address2Insurance4CompanyName: z.string().optional(),
  address2Insurance4CompanyAddressLine1: AddressLine.optional(),
  address2Insurance4CompanyAddressLine2: AddressLine.optional(),
  address2Insurance4CompanyAddressCity: z.string().optional(),
  address2Insurance4CompanyAddressState: z.string().optional(),
  address2Insurance4CompanyAddressZip: z.string().optional(),
  address2Insurance4PolicyNumber: z.string().optional(),
  address2Insurance4PolicyStart: DateString.optional(),
  address2Insurance4PolicyEnd: DateString.optional(),

  address2Insurance5Type: z.string().optional(),
  address2Insurance5IsEscrowed: Checkbox.optional(),
  address2Insurance5Amount: NonNegativeMonetaryValue.optional(),
  address2Insurance5AgentName: z.string().optional(),
  address2Insurance5AgentEmail: OptionalEmailString,
  address2Insurance5AgentPhone: OptionalPhoneNumber,
  address2Insurance5CompanyName: z.string().optional(),
  address2Insurance5CompanyAddressLine1: AddressLine.optional(),
  address2Insurance5CompanyAddressLine2: AddressLine.optional(),
  address2Insurance5CompanyAddressCity: z.string().optional(),
  address2Insurance5CompanyAddressState: z.string().optional(),
  address2Insurance5CompanyAddressZip: z.string().optional(),
  address2Insurance5PolicyNumber: z.string().optional(),
  address2Insurance5PolicyStart: DateString.optional(),
  address2Insurance5PolicyEnd: DateString.optional(),

  address2Insurance6Type: z.string().optional(),
  address2Insurance6IsEscrowed: Checkbox.optional(),
  address2Insurance6Amount: NonNegativeMonetaryValue.optional(),
  address2Insurance6AgentName: z.string().optional(),
  address2Insurance6AgentEmail: OptionalEmailString,
  address2Insurance6AgentPhone: OptionalPhoneNumber,
  address2Insurance6CompanyName: z.string().optional(),
  address2Insurance6CompanyAddressLine1: AddressLine.optional(),
  address2Insurance6CompanyAddressLine2: AddressLine.optional(),
  address2Insurance6CompanyAddressCity: z.string().optional(),
  address2Insurance6CompanyAddressState: z.string().optional(),
  address2Insurance6CompanyAddressZip: z.string().optional(),
  address2Insurance6PolicyNumber: z.string().optional(),
  address2Insurance6PolicyStart: DateString.optional(),
  address2Insurance6PolicyEnd: DateString.optional(),

  // ADDRESS 3
  address3Insurance1Type: z.string().optional(),
  address3Insurance1IsEscrowed: Checkbox.optional(),
  address3Insurance1Amount: NonNegativeMonetaryValue.optional(),
  address3Insurance1AgentName: z.string().optional(),
  address3Insurance1AgentEmail: OptionalEmailString,
  address3Insurance1AgentPhone: OptionalPhoneNumber,
  address3Insurance1CompanyName: z.string().optional(),
  address3Insurance1CompanyAddressLine1: AddressLine.optional(),
  address3Insurance1CompanyAddressLine2: AddressLine.optional(),
  address3Insurance1CompanyAddressCity: z.string().optional(),
  address3Insurance1CompanyAddressState: z.string().optional(),
  address3Insurance1CompanyAddressZip: z.string().optional(),
  address3Insurance1PolicyNumber: z.string().optional(),
  address3Insurance1PolicyStart: DateString.optional(),
  address3Insurance1PolicyEnd: DateString.optional(),

  address3Insurance2Type: z.string().optional(),
  address3Insurance2IsEscrowed: Checkbox.optional(),
  address3Insurance2Amount: NonNegativeMonetaryValue.optional(),
  address3Insurance2AgentName: z.string().optional(),
  address3Insurance2AgentEmail: OptionalEmailString,
  address3Insurance2AgentPhone: OptionalPhoneNumber,
  address3Insurance2CompanyName: z.string().optional(),
  address3Insurance2CompanyAddressLine1: AddressLine.optional(),
  address3Insurance2CompanyAddressLine2: AddressLine.optional(),
  address3Insurance2CompanyAddressCity: z.string().optional(),
  address3Insurance2CompanyAddressState: z.string().optional(),
  address3Insurance2CompanyAddressZip: z.string().optional(),
  address3Insurance2PolicyNumber: z.string().optional(),
  address3Insurance2PolicyStart: DateString.optional(),
  address3Insurance2PolicyEnd: DateString.optional(),

  address3Insurance3Type: z.string().optional(),
  address3Insurance3IsEscrowed: Checkbox.optional(),
  address3Insurance3Amount: NonNegativeMonetaryValue.optional(),
  address3Insurance3AgentName: z.string().optional(),
  address3Insurance3AgentEmail: OptionalEmailString,
  address3Insurance3AgentPhone: OptionalPhoneNumber,
  address3Insurance3CompanyName: z.string().optional(),
  address3Insurance3CompanyAddressLine1: AddressLine.optional(),
  address3Insurance3CompanyAddressLine2: AddressLine.optional(),
  address3Insurance3CompanyAddressCity: z.string().optional(),
  address3Insurance3CompanyAddressState: z.string().optional(),
  address3Insurance3CompanyAddressZip: z.string().optional(),
  address3Insurance3PolicyNumber: z.string().optional(),
  address3Insurance3PolicyStart: DateString.optional(),
  address3Insurance3PolicyEnd: DateString.optional(),

  address3Insurance4Type: z.string().optional(),
  address3Insurance4IsEscrowed: Checkbox.optional(),
  address3Insurance4Amount: NonNegativeMonetaryValue.optional(),
  address3Insurance4AgentName: z.string().optional(),
  address3Insurance4AgentEmail: OptionalEmailString,
  address3Insurance4AgentPhone: OptionalPhoneNumber,
  address3Insurance4CompanyName: z.string().optional(),
  address3Insurance4CompanyAddressLine1: AddressLine.optional(),
  address3Insurance4CompanyAddressLine2: AddressLine.optional(),
  address3Insurance4CompanyAddressCity: z.string().optional(),
  address3Insurance4CompanyAddressState: z.string().optional(),
  address3Insurance4CompanyAddressZip: z.string().optional(),
  address3Insurance4PolicyNumber: z.string().optional(),
  address3Insurance4PolicyStart: DateString.optional(),
  address3Insurance4PolicyEnd: DateString.optional(),

  address3Insurance5Type: z.string().optional(),
  address3Insurance5IsEscrowed: Checkbox.optional(),
  address3Insurance5Amount: NonNegativeMonetaryValue.optional(),
  address3Insurance5AgentName: z.string().optional(),
  address3Insurance5AgentEmail: OptionalEmailString,
  address3Insurance5AgentPhone: OptionalPhoneNumber,
  address3Insurance5CompanyName: z.string().optional(),
  address3Insurance5CompanyAddressLine1: AddressLine.optional(),
  address3Insurance5CompanyAddressLine2: AddressLine.optional(),
  address3Insurance5CompanyAddressCity: z.string().optional(),
  address3Insurance5CompanyAddressState: z.string().optional(),
  address3Insurance5CompanyAddressZip: z.string().optional(),
  address3Insurance5PolicyNumber: z.string().optional(),
  address3Insurance5PolicyStart: DateString.optional(),
  address3Insurance5PolicyEnd: DateString.optional(),

  address3Insurance6Type: z.string().optional(),
  address3Insurance6IsEscrowed: Checkbox.optional(),
  address3Insurance6Amount: NonNegativeMonetaryValue.optional(),
  address3Insurance6AgentName: z.string().optional(),
  address3Insurance6AgentEmail: OptionalEmailString,
  address3Insurance6AgentPhone: OptionalPhoneNumber,
  address3Insurance6CompanyName: z.string().optional(),
  address3Insurance6CompanyAddressLine1: AddressLine.optional(),
  address3Insurance6CompanyAddressLine2: AddressLine.optional(),
  address3Insurance6CompanyAddressCity: z.string().optional(),
  address3Insurance6CompanyAddressState: z.string().optional(),
  address3Insurance6CompanyAddressZip: z.string().optional(),
  address3Insurance6PolicyNumber: z.string().optional(),
  address3Insurance6PolicyStart: DateString.optional(),
  address3Insurance6PolicyEnd: DateString.optional(),

  // ADDRESS 4
  address4Insurance1Type: z.string().optional(),
  address4Insurance1IsEscrowed: Checkbox.optional(),
  address4Insurance1Amount: NonNegativeMonetaryValue.optional(),
  address4Insurance1AgentName: z.string().optional(),
  address4Insurance1AgentEmail: OptionalEmailString,
  address4Insurance1AgentPhone: OptionalPhoneNumber,
  address4Insurance1CompanyName: z.string().optional(),
  address4Insurance1CompanyAddressLine1: AddressLine.optional(),
  address4Insurance1CompanyAddressLine2: AddressLine.optional(),
  address4Insurance1CompanyAddressCity: z.string().optional(),
  address4Insurance1CompanyAddressState: z.string().optional(),
  address4Insurance1CompanyAddressZip: z.string().optional(),
  address4Insurance1PolicyNumber: z.string().optional(),
  address4Insurance1PolicyStart: DateString.optional(),
  address4Insurance1PolicyEnd: DateString.optional(),

  address4Insurance2Type: z.string().optional(),
  address4Insurance2IsEscrowed: Checkbox.optional(),
  address4Insurance2Amount: NonNegativeMonetaryValue.optional(),
  address4Insurance2AgentName: z.string().optional(),
  address4Insurance2AgentEmail: OptionalEmailString,
  address4Insurance2AgentPhone: OptionalPhoneNumber,
  address4Insurance2CompanyName: z.string().optional(),
  address4Insurance2CompanyAddressLine1: AddressLine.optional(),
  address4Insurance2CompanyAddressLine2: AddressLine.optional(),
  address4Insurance2CompanyAddressCity: z.string().optional(),
  address4Insurance2CompanyAddressState: z.string().optional(),
  address4Insurance2CompanyAddressZip: z.string().optional(),
  address4Insurance2PolicyNumber: z.string().optional(),
  address4Insurance2PolicyStart: DateString.optional(),
  address4Insurance2PolicyEnd: DateString.optional(),

  address4Insurance3Type: z.string().optional(),
  address4Insurance3IsEscrowed: Checkbox.optional(),
  address4Insurance3Amount: NonNegativeMonetaryValue.optional(),
  address4Insurance3AgentName: z.string().optional(),
  address4Insurance3AgentEmail: OptionalEmailString,
  address4Insurance3AgentPhone: OptionalPhoneNumber,
  address4Insurance3CompanyName: z.string().optional(),
  address4Insurance3CompanyAddressLine1: AddressLine.optional(),
  address4Insurance3CompanyAddressLine2: AddressLine.optional(),
  address4Insurance3CompanyAddressCity: z.string().optional(),
  address4Insurance3CompanyAddressState: z.string().optional(),
  address4Insurance3CompanyAddressZip: z.string().optional(),
  address4Insurance3PolicyNumber: z.string().optional(),
  address4Insurance3PolicyStart: DateString.optional(),
  address4Insurance3PolicyEnd: DateString.optional(),

  address4Insurance4Type: z.string().optional(),
  address4Insurance4IsEscrowed: Checkbox.optional(),
  address4Insurance4Amount: NonNegativeMonetaryValue.optional(),
  address4Insurance4AgentName: z.string().optional(),
  address4Insurance4AgentEmail: OptionalEmailString,
  address4Insurance4AgentPhone: OptionalPhoneNumber,
  address4Insurance4CompanyName: z.string().optional(),
  address4Insurance4CompanyAddressLine1: AddressLine.optional(),
  address4Insurance4CompanyAddressLine2: AddressLine.optional(),
  address4Insurance4CompanyAddressCity: z.string().optional(),
  address4Insurance4CompanyAddressState: z.string().optional(),
  address4Insurance4CompanyAddressZip: z.string().optional(),
  address4Insurance4PolicyNumber: z.string().optional(),
  address4Insurance4PolicyStart: DateString.optional(),
  address4Insurance4PolicyEnd: DateString.optional(),

  address4Insurance5Type: z.string().optional(),
  address4Insurance5IsEscrowed: Checkbox.optional(),
  address4Insurance5Amount: NonNegativeMonetaryValue.optional(),
  address4Insurance5AgentName: z.string().optional(),
  address4Insurance5AgentEmail: OptionalEmailString,
  address4Insurance5AgentPhone: OptionalPhoneNumber,
  address4Insurance5CompanyName: z.string().optional(),
  address4Insurance5CompanyAddressLine1: AddressLine.optional(),
  address4Insurance5CompanyAddressLine2: AddressLine.optional(),
  address4Insurance5CompanyAddressCity: z.string().optional(),
  address4Insurance5CompanyAddressState: z.string().optional(),
  address4Insurance5CompanyAddressZip: z.string().optional(),
  address4Insurance5PolicyNumber: z.string().optional(),
  address4Insurance5PolicyStart: DateString.optional(),
  address4Insurance5PolicyEnd: DateString.optional(),

  address4Insurance6Type: z.string().optional(),
  address4Insurance6IsEscrowed: Checkbox.optional(),
  address4Insurance6Amount: NonNegativeMonetaryValue.optional(),
  address4Insurance6AgentName: z.string().optional(),
  address4Insurance6AgentEmail: OptionalEmailString,
  address4Insurance6AgentPhone: OptionalPhoneNumber,
  address4Insurance6CompanyName: z.string().optional(),
  address4Insurance6CompanyAddressLine1: AddressLine.optional(),
  address4Insurance6CompanyAddressLine2: AddressLine.optional(),
  address4Insurance6CompanyAddressCity: z.string().optional(),
  address4Insurance6CompanyAddressState: z.string().optional(),
  address4Insurance6CompanyAddressZip: z.string().optional(),
  address4Insurance6PolicyNumber: z.string().optional(),
  address4Insurance6PolicyStart: DateString.optional(),
  address4Insurance6PolicyEnd: DateString.optional(),

  // ADDRESS 5
  address5Insurance1Type: z.string().optional(),
  address5Insurance1IsEscrowed: Checkbox.optional(),
  address5Insurance1Amount: NonNegativeMonetaryValue.optional(),
  address5Insurance1AgentName: z.string().optional(),
  address5Insurance1AgentEmail: OptionalEmailString,
  address5Insurance1AgentPhone: OptionalPhoneNumber,
  address5Insurance1CompanyName: z.string().optional(),
  address5Insurance1CompanyAddressLine1: AddressLine.optional(),
  address5Insurance1CompanyAddressLine2: AddressLine.optional(),
  address5Insurance1CompanyAddressCity: z.string().optional(),
  address5Insurance1CompanyAddressState: z.string().optional(),
  address5Insurance1CompanyAddressZip: z.string().optional(),
  address5Insurance1PolicyNumber: z.string().optional(),
  address5Insurance1PolicyStart: DateString.optional(),
  address5Insurance1PolicyEnd: DateString.optional(),

  address5Insurance2Type: z.string().optional(),
  address5Insurance2IsEscrowed: Checkbox.optional(),
  address5Insurance2Amount: NonNegativeMonetaryValue.optional(),
  address5Insurance2AgentName: z.string().optional(),
  address5Insurance2AgentEmail: OptionalEmailString,
  address5Insurance2AgentPhone: OptionalPhoneNumber,
  address5Insurance2CompanyName: z.string().optional(),
  address5Insurance2CompanyAddressLine1: AddressLine.optional(),
  address5Insurance2CompanyAddressLine2: AddressLine.optional(),
  address5Insurance2CompanyAddressCity: z.string().optional(),
  address5Insurance2CompanyAddressState: z.string().optional(),
  address5Insurance2CompanyAddressZip: z.string().optional(),
  address5Insurance2PolicyNumber: z.string().optional(),
  address5Insurance2PolicyStart: DateString.optional(),
  address5Insurance2PolicyEnd: DateString.optional(),

  address5Insurance3Type: z.string().optional(),
  address5Insurance3IsEscrowed: Checkbox.optional(),
  address5Insurance3Amount: NonNegativeMonetaryValue.optional(),
  address5Insurance3AgentName: z.string().optional(),
  address5Insurance3AgentEmail: OptionalEmailString,
  address5Insurance3AgentPhone: OptionalPhoneNumber,
  address5Insurance3CompanyName: z.string().optional(),
  address5Insurance3CompanyAddressLine1: AddressLine.optional(),
  address5Insurance3CompanyAddressLine2: AddressLine.optional(),
  address5Insurance3CompanyAddressCity: z.string().optional(),
  address5Insurance3CompanyAddressState: z.string().optional(),
  address5Insurance3CompanyAddressZip: z.string().optional(),
  address5Insurance3PolicyNumber: z.string().optional(),
  address5Insurance3PolicyStart: DateString.optional(),
  address5Insurance3PolicyEnd: DateString.optional(),

  address5Insurance4Type: z.string().optional(),
  address5Insurance4IsEscrowed: Checkbox.optional(),
  address5Insurance4Amount: NonNegativeMonetaryValue.optional(),
  address5Insurance4AgentName: z.string().optional(),
  address5Insurance4AgentEmail: OptionalEmailString,
  address5Insurance4AgentPhone: OptionalPhoneNumber,
  address5Insurance4CompanyName: z.string().optional(),
  address5Insurance4CompanyAddressLine1: AddressLine.optional(),
  address5Insurance4CompanyAddressLine2: AddressLine.optional(),
  address5Insurance4CompanyAddressCity: z.string().optional(),
  address5Insurance4CompanyAddressState: z.string().optional(),
  address5Insurance4CompanyAddressZip: z.string().optional(),
  address5Insurance4PolicyNumber: z.string().optional(),
  address5Insurance4PolicyStart: DateString.optional(),
  address5Insurance4PolicyEnd: DateString.optional(),

  address5Insurance5Type: z.string().optional(),
  address5Insurance5IsEscrowed: Checkbox.optional(),
  address5Insurance5Amount: NonNegativeMonetaryValue.optional(),
  address5Insurance5AgentName: z.string().optional(),
  address5Insurance5AgentEmail: OptionalEmailString,
  address5Insurance5AgentPhone: OptionalPhoneNumber,
  address5Insurance5CompanyName: z.string().optional(),
  address5Insurance5CompanyAddressLine1: AddressLine.optional(),
  address5Insurance5CompanyAddressLine2: AddressLine.optional(),
  address5Insurance5CompanyAddressCity: z.string().optional(),
  address5Insurance5CompanyAddressState: z.string().optional(),
  address5Insurance5CompanyAddressZip: z.string().optional(),
  address5Insurance5PolicyNumber: z.string().optional(),
  address5Insurance5PolicyStart: DateString.optional(),
  address5Insurance5PolicyEnd: DateString.optional(),

  address5Insurance6Type: z.string().optional(),
  address5Insurance6IsEscrowed: Checkbox.optional(),
  address5Insurance6Amount: NonNegativeMonetaryValue.optional(),
  address5Insurance6AgentName: z.string().optional(),
  address5Insurance6AgentEmail: OptionalEmailString,
  address5Insurance6AgentPhone: OptionalPhoneNumber,
  address5Insurance6CompanyName: z.string().optional(),
  address5Insurance6CompanyAddressLine1: AddressLine.optional(),
  address5Insurance6CompanyAddressLine2: AddressLine.optional(),
  address5Insurance6CompanyAddressCity: z.string().optional(),
  address5Insurance6CompanyAddressState: z.string().optional(),
  address5Insurance6CompanyAddressZip: z.string().optional(),
  address5Insurance6PolicyNumber: z.string().optional(),
  address5Insurance6PolicyStart: DateString.optional(),
  address5Insurance6PolicyEnd: DateString.optional(),

  // ADDRESS 6
  address6Insurance1Type: z.string().optional(),
  address6Insurance1IsEscrowed: Checkbox.optional(),
  address6Insurance1Amount: NonNegativeMonetaryValue.optional(),
  address6Insurance1AgentName: z.string().optional(),
  address6Insurance1AgentEmail: OptionalEmailString,
  address6Insurance1AgentPhone: OptionalPhoneNumber,
  address6Insurance1CompanyName: z.string().optional(),
  address6Insurance1CompanyAddressLine1: AddressLine.optional(),
  address6Insurance1CompanyAddressLine2: AddressLine.optional(),
  address6Insurance1CompanyAddressCity: z.string().optional(),
  address6Insurance1CompanyAddressState: z.string().optional(),
  address6Insurance1CompanyAddressZip: z.string().optional(),
  address6Insurance1PolicyNumber: z.string().optional(),
  address6Insurance1PolicyStart: DateString.optional(),
  address6Insurance1PolicyEnd: DateString.optional(),

  address6Insurance2Type: z.string().optional(),
  address6Insurance2IsEscrowed: Checkbox.optional(),
  address6Insurance2Amount: NonNegativeMonetaryValue.optional(),
  address6Insurance2AgentName: z.string().optional(),
  address6Insurance2AgentEmail: OptionalEmailString,
  address6Insurance2AgentPhone: OptionalPhoneNumber,
  address6Insurance2CompanyName: z.string().optional(),
  address6Insurance2CompanyAddressLine1: AddressLine.optional(),
  address6Insurance2CompanyAddressLine2: AddressLine.optional(),
  address6Insurance2CompanyAddressCity: z.string().optional(),
  address6Insurance2CompanyAddressState: z.string().optional(),
  address6Insurance2CompanyAddressZip: z.string().optional(),
  address6Insurance2PolicyNumber: z.string().optional(),
  address6Insurance2PolicyStart: DateString.optional(),
  address6Insurance2PolicyEnd: DateString.optional(),

  address6Insurance3Type: z.string().optional(),
  address6Insurance3IsEscrowed: Checkbox.optional(),
  address6Insurance3Amount: NonNegativeMonetaryValue.optional(),
  address6Insurance3AgentName: z.string().optional(),
  address6Insurance3AgentEmail: OptionalEmailString,
  address6Insurance3AgentPhone: OptionalPhoneNumber,
  address6Insurance3CompanyName: z.string().optional(),
  address6Insurance3CompanyAddressLine1: AddressLine.optional(),
  address6Insurance3CompanyAddressLine2: AddressLine.optional(),
  address6Insurance3CompanyAddressCity: z.string().optional(),
  address6Insurance3CompanyAddressState: z.string().optional(),
  address6Insurance3CompanyAddressZip: z.string().optional(),
  address6Insurance3PolicyNumber: z.string().optional(),
  address6Insurance3PolicyStart: DateString.optional(),
  address6Insurance3PolicyEnd: DateString.optional(),

  address6Insurance4Type: z.string().optional(),
  address6Insurance4IsEscrowed: Checkbox.optional(),
  address6Insurance4Amount: NonNegativeMonetaryValue.optional(),
  address6Insurance4AgentName: z.string().optional(),
  address6Insurance4AgentEmail: OptionalEmailString,
  address6Insurance4AgentPhone: OptionalPhoneNumber,
  address6Insurance4CompanyName: z.string().optional(),
  address6Insurance4CompanyAddressLine1: AddressLine.optional(),
  address6Insurance4CompanyAddressLine2: AddressLine.optional(),
  address6Insurance4CompanyAddressCity: z.string().optional(),
  address6Insurance4CompanyAddressState: z.string().optional(),
  address6Insurance4CompanyAddressZip: z.string().optional(),
  address6Insurance4PolicyNumber: z.string().optional(),
  address6Insurance4PolicyStart: DateString.optional(),
  address6Insurance4PolicyEnd: DateString.optional(),

  address6Insurance5Type: z.string().optional(),
  address6Insurance5IsEscrowed: Checkbox.optional(),
  address6Insurance5Amount: NonNegativeMonetaryValue.optional(),
  address6Insurance5AgentName: z.string().optional(),
  address6Insurance5AgentEmail: OptionalEmailString,
  address6Insurance5AgentPhone: OptionalPhoneNumber,
  address6Insurance5CompanyName: z.string().optional(),
  address6Insurance5CompanyAddressLine1: AddressLine.optional(),
  address6Insurance5CompanyAddressLine2: AddressLine.optional(),
  address6Insurance5CompanyAddressCity: z.string().optional(),
  address6Insurance5CompanyAddressState: z.string().optional(),
  address6Insurance5CompanyAddressZip: z.string().optional(),
  address6Insurance5PolicyNumber: z.string().optional(),
  address6Insurance5PolicyStart: DateString.optional(),
  address6Insurance5PolicyEnd: DateString.optional(),

  address6Insurance6Type: z.string().optional(),
  address6Insurance6IsEscrowed: Checkbox.optional(),
  address6Insurance6Amount: NonNegativeMonetaryValue.optional(),
  address6Insurance6AgentName: z.string().optional(),
  address6Insurance6AgentEmail: OptionalEmailString,
  address6Insurance6AgentPhone: OptionalPhoneNumber,
  address6Insurance6CompanyName: z.string().optional(),
  address6Insurance6CompanyAddressLine1: AddressLine.optional(),
  address6Insurance6CompanyAddressLine2: AddressLine.optional(),
  address6Insurance6CompanyAddressCity: z.string().optional(),
  address6Insurance6CompanyAddressState: z.string().optional(),
  address6Insurance6CompanyAddressZip: z.string().optional(),
  address6Insurance6PolicyNumber: z.string().optional(),
  address6Insurance6PolicyStart: DateString.optional(),
  address6Insurance6PolicyEnd: DateString.optional(),

  // ADDRESS 7
  address7Insurance1Type: z.string().optional(),
  address7Insurance1IsEscrowed: Checkbox.optional(),
  address7Insurance1Amount: NonNegativeMonetaryValue.optional(),
  address7Insurance1AgentName: z.string().optional(),
  address7Insurance1AgentEmail: OptionalEmailString,
  address7Insurance1AgentPhone: OptionalPhoneNumber,
  address7Insurance1CompanyName: z.string().optional(),
  address7Insurance1CompanyAddressLine1: AddressLine.optional(),
  address7Insurance1CompanyAddressLine2: AddressLine.optional(),
  address7Insurance1CompanyAddressCity: z.string().optional(),
  address7Insurance1CompanyAddressState: z.string().optional(),
  address7Insurance1CompanyAddressZip: z.string().optional(),
  address7Insurance1PolicyNumber: z.string().optional(),
  address7Insurance1PolicyStart: DateString.optional(),
  address7Insurance1PolicyEnd: DateString.optional(),

  address7Insurance2Type: z.string().optional(),
  address7Insurance2IsEscrowed: Checkbox.optional(),
  address7Insurance2Amount: NonNegativeMonetaryValue.optional(),
  address7Insurance2AgentName: z.string().optional(),
  address7Insurance2AgentEmail: OptionalEmailString,
  address7Insurance2AgentPhone: OptionalPhoneNumber,
  address7Insurance2CompanyName: z.string().optional(),
  address7Insurance2CompanyAddressLine1: AddressLine.optional(),
  address7Insurance2CompanyAddressLine2: AddressLine.optional(),
  address7Insurance2CompanyAddressCity: z.string().optional(),
  address7Insurance2CompanyAddressState: z.string().optional(),
  address7Insurance2CompanyAddressZip: z.string().optional(),
  address7Insurance2PolicyNumber: z.string().optional(),
  address7Insurance2PolicyStart: DateString.optional(),
  address7Insurance2PolicyEnd: DateString.optional(),

  address7Insurance3Type: z.string().optional(),
  address7Insurance3IsEscrowed: Checkbox.optional(),
  address7Insurance3Amount: NonNegativeMonetaryValue.optional(),
  address7Insurance3AgentName: z.string().optional(),
  address7Insurance3AgentEmail: OptionalEmailString,
  address7Insurance3AgentPhone: OptionalPhoneNumber,
  address7Insurance3CompanyName: z.string().optional(),
  address7Insurance3CompanyAddressLine1: AddressLine.optional(),
  address7Insurance3CompanyAddressLine2: AddressLine.optional(),
  address7Insurance3CompanyAddressCity: z.string().optional(),
  address7Insurance3CompanyAddressState: z.string().optional(),
  address7Insurance3CompanyAddressZip: z.string().optional(),
  address7Insurance3PolicyNumber: z.string().optional(),
  address7Insurance3PolicyStart: DateString.optional(),
  address7Insurance3PolicyEnd: DateString.optional(),

  address7Insurance4Type: z.string().optional(),
  address7Insurance4IsEscrowed: Checkbox.optional(),
  address7Insurance4Amount: NonNegativeMonetaryValue.optional(),
  address7Insurance4AgentName: z.string().optional(),
  address7Insurance4AgentEmail: OptionalEmailString,
  address7Insurance4AgentPhone: OptionalPhoneNumber,
  address7Insurance4CompanyName: z.string().optional(),
  address7Insurance4CompanyAddressLine1: AddressLine.optional(),
  address7Insurance4CompanyAddressLine2: AddressLine.optional(),
  address7Insurance4CompanyAddressCity: z.string().optional(),
  address7Insurance4CompanyAddressState: z.string().optional(),
  address7Insurance4CompanyAddressZip: z.string().optional(),
  address7Insurance4PolicyNumber: z.string().optional(),
  address7Insurance4PolicyStart: DateString.optional(),
  address7Insurance4PolicyEnd: DateString.optional(),

  address7Insurance5Type: z.string().optional(),
  address7Insurance5IsEscrowed: Checkbox.optional(),
  address7Insurance5Amount: NonNegativeMonetaryValue.optional(),
  address7Insurance5AgentName: z.string().optional(),
  address7Insurance5AgentEmail: OptionalEmailString,
  address7Insurance5AgentPhone: OptionalPhoneNumber,
  address7Insurance5CompanyName: z.string().optional(),
  address7Insurance5CompanyAddressLine1: AddressLine.optional(),
  address7Insurance5CompanyAddressLine2: AddressLine.optional(),
  address7Insurance5CompanyAddressCity: z.string().optional(),
  address7Insurance5CompanyAddressState: z.string().optional(),
  address7Insurance5CompanyAddressZip: z.string().optional(),
  address7Insurance5PolicyNumber: z.string().optional(),
  address7Insurance5PolicyStart: DateString.optional(),
  address7Insurance5PolicyEnd: DateString.optional(),

  address7Insurance6Type: z.string().optional(),
  address7Insurance6IsEscrowed: Checkbox.optional(),
  address7Insurance6Amount: NonNegativeMonetaryValue.optional(),
  address7Insurance6AgentName: z.string().optional(),
  address7Insurance6AgentEmail: OptionalEmailString,
  address7Insurance6AgentPhone: OptionalPhoneNumber,
  address7Insurance6CompanyName: z.string().optional(),
  address7Insurance6CompanyAddressLine1: AddressLine.optional(),
  address7Insurance6CompanyAddressLine2: AddressLine.optional(),
  address7Insurance6CompanyAddressCity: z.string().optional(),
  address7Insurance6CompanyAddressState: z.string().optional(),
  address7Insurance6CompanyAddressZip: z.string().optional(),
  address7Insurance6PolicyNumber: z.string().optional(),
  address7Insurance6PolicyStart: DateString.optional(),
  address7Insurance6PolicyEnd: DateString.optional(),

  // ADDRESS 8
  address8Insurance1Type: z.string().optional(),
  address8Insurance1IsEscrowed: Checkbox.optional(),
  address8Insurance1Amount: NonNegativeMonetaryValue.optional(),
  address8Insurance1AgentName: z.string().optional(),
  address8Insurance1AgentEmail: OptionalEmailString,
  address8Insurance1AgentPhone: OptionalPhoneNumber,
  address8Insurance1CompanyName: z.string().optional(),
  address8Insurance1CompanyAddressLine1: AddressLine.optional(),
  address8Insurance1CompanyAddressLine2: AddressLine.optional(),
  address8Insurance1CompanyAddressCity: z.string().optional(),
  address8Insurance1CompanyAddressState: z.string().optional(),
  address8Insurance1CompanyAddressZip: z.string().optional(),
  address8Insurance1PolicyNumber: z.string().optional(),
  address8Insurance1PolicyStart: DateString.optional(),
  address8Insurance1PolicyEnd: DateString.optional(),

  address8Insurance2Type: z.string().optional(),
  address8Insurance2IsEscrowed: Checkbox.optional(),
  address8Insurance2Amount: NonNegativeMonetaryValue.optional(),
  address8Insurance2AgentName: z.string().optional(),
  address8Insurance2AgentEmail: OptionalEmailString,
  address8Insurance2AgentPhone: OptionalPhoneNumber,
  address8Insurance2CompanyName: z.string().optional(),
  address8Insurance2CompanyAddressLine1: AddressLine.optional(),
  address8Insurance2CompanyAddressLine2: AddressLine.optional(),
  address8Insurance2CompanyAddressCity: z.string().optional(),
  address8Insurance2CompanyAddressState: z.string().optional(),
  address8Insurance2CompanyAddressZip: z.string().optional(),
  address8Insurance2PolicyNumber: z.string().optional(),
  address8Insurance2PolicyStart: DateString.optional(),
  address8Insurance2PolicyEnd: DateString.optional(),

  address8Insurance3Type: z.string().optional(),
  address8Insurance3IsEscrowed: Checkbox.optional(),
  address8Insurance3Amount: NonNegativeMonetaryValue.optional(),
  address8Insurance3AgentName: z.string().optional(),
  address8Insurance3AgentEmail: OptionalEmailString,
  address8Insurance3AgentPhone: OptionalPhoneNumber,
  address8Insurance3CompanyName: z.string().optional(),
  address8Insurance3CompanyAddressLine1: AddressLine.optional(),
  address8Insurance3CompanyAddressLine2: AddressLine.optional(),
  address8Insurance3CompanyAddressCity: z.string().optional(),
  address8Insurance3CompanyAddressState: z.string().optional(),
  address8Insurance3CompanyAddressZip: z.string().optional(),
  address8Insurance3PolicyNumber: z.string().optional(),
  address8Insurance3PolicyStart: DateString.optional(),
  address8Insurance3PolicyEnd: DateString.optional(),

  address8Insurance4Type: z.string().optional(),
  address8Insurance4IsEscrowed: Checkbox.optional(),
  address8Insurance4Amount: NonNegativeMonetaryValue.optional(),
  address8Insurance4AgentName: z.string().optional(),
  address8Insurance4AgentEmail: OptionalEmailString,
  address8Insurance4AgentPhone: OptionalPhoneNumber,
  address8Insurance4CompanyName: z.string().optional(),
  address8Insurance4CompanyAddressLine1: AddressLine.optional(),
  address8Insurance4CompanyAddressLine2: AddressLine.optional(),
  address8Insurance4CompanyAddressCity: z.string().optional(),
  address8Insurance4CompanyAddressState: z.string().optional(),
  address8Insurance4CompanyAddressZip: z.string().optional(),
  address8Insurance4PolicyNumber: z.string().optional(),
  address8Insurance4PolicyStart: DateString.optional(),
  address8Insurance4PolicyEnd: DateString.optional(),

  address8Insurance5Type: z.string().optional(),
  address8Insurance5IsEscrowed: Checkbox.optional(),
  address8Insurance5Amount: NonNegativeMonetaryValue.optional(),
  address8Insurance5AgentName: z.string().optional(),
  address8Insurance5AgentEmail: OptionalEmailString,
  address8Insurance5AgentPhone: OptionalPhoneNumber,
  address8Insurance5CompanyName: z.string().optional(),
  address8Insurance5CompanyAddressLine1: AddressLine.optional(),
  address8Insurance5CompanyAddressLine2: AddressLine.optional(),
  address8Insurance5CompanyAddressCity: z.string().optional(),
  address8Insurance5CompanyAddressState: z.string().optional(),
  address8Insurance5CompanyAddressZip: z.string().optional(),
  address8Insurance5PolicyNumber: z.string().optional(),
  address8Insurance5PolicyStart: DateString.optional(),
  address8Insurance5PolicyEnd: DateString.optional(),

  address8Insurance6Type: z.string().optional(),
  address8Insurance6IsEscrowed: Checkbox.optional(),
  address8Insurance6Amount: NonNegativeMonetaryValue.optional(),
  address8Insurance6AgentName: z.string().optional(),
  address8Insurance6AgentEmail: OptionalEmailString,
  address8Insurance6AgentPhone: OptionalPhoneNumber,
  address8Insurance6CompanyName: z.string().optional(),
  address8Insurance6CompanyAddressLine1: AddressLine.optional(),
  address8Insurance6CompanyAddressLine2: AddressLine.optional(),
  address8Insurance6CompanyAddressCity: z.string().optional(),
  address8Insurance6CompanyAddressState: z.string().optional(),
  address8Insurance6CompanyAddressZip: z.string().optional(),
  address8Insurance6PolicyNumber: z.string().optional(),
  address8Insurance6PolicyStart: DateString.optional(),
  address8Insurance6PolicyEnd: DateString.optional(),

  // ADDRESS 9
  address9Insurance1Type: z.string().optional(),
  address9Insurance1IsEscrowed: Checkbox.optional(),
  address9Insurance1Amount: NonNegativeMonetaryValue.optional(),
  address9Insurance1AgentName: z.string().optional(),
  address9Insurance1AgentEmail: OptionalEmailString,
  address9Insurance1AgentPhone: OptionalPhoneNumber,
  address9Insurance1CompanyName: z.string().optional(),
  address9Insurance1CompanyAddressLine1: AddressLine.optional(),
  address9Insurance1CompanyAddressLine2: AddressLine.optional(),
  address9Insurance1CompanyAddressCity: z.string().optional(),
  address9Insurance1CompanyAddressState: z.string().optional(),
  address9Insurance1CompanyAddressZip: z.string().optional(),
  address9Insurance1PolicyNumber: z.string().optional(),
  address9Insurance1PolicyStart: DateString.optional(),
  address9Insurance1PolicyEnd: DateString.optional(),

  address9Insurance2Type: z.string().optional(),
  address9Insurance2IsEscrowed: Checkbox.optional(),
  address9Insurance2Amount: NonNegativeMonetaryValue.optional(),
  address9Insurance2AgentName: z.string().optional(),
  address9Insurance2AgentEmail: OptionalEmailString,
  address9Insurance2AgentPhone: OptionalPhoneNumber,
  address9Insurance2CompanyName: z.string().optional(),
  address9Insurance2CompanyAddressLine1: AddressLine.optional(),
  address9Insurance2CompanyAddressLine2: AddressLine.optional(),
  address9Insurance2CompanyAddressCity: z.string().optional(),
  address9Insurance2CompanyAddressState: z.string().optional(),
  address9Insurance2CompanyAddressZip: z.string().optional(),
  address9Insurance2PolicyNumber: z.string().optional(),
  address9Insurance2PolicyStart: DateString.optional(),
  address9Insurance2PolicyEnd: DateString.optional(),

  address9Insurance3Type: z.string().optional(),
  address9Insurance3IsEscrowed: Checkbox.optional(),
  address9Insurance3Amount: NonNegativeMonetaryValue.optional(),
  address9Insurance3AgentName: z.string().optional(),
  address9Insurance3AgentEmail: OptionalEmailString,
  address9Insurance3AgentPhone: OptionalPhoneNumber,
  address9Insurance3CompanyName: z.string().optional(),
  address9Insurance3CompanyAddressLine1: AddressLine.optional(),
  address9Insurance3CompanyAddressLine2: AddressLine.optional(),
  address9Insurance3CompanyAddressCity: z.string().optional(),
  address9Insurance3CompanyAddressState: z.string().optional(),
  address9Insurance3CompanyAddressZip: z.string().optional(),
  address9Insurance3PolicyNumber: z.string().optional(),
  address9Insurance3PolicyStart: DateString.optional(),
  address9Insurance3PolicyEnd: DateString.optional(),

  address9Insurance4Type: z.string().optional(),
  address9Insurance4IsEscrowed: Checkbox.optional(),
  address9Insurance4Amount: NonNegativeMonetaryValue.optional(),
  address9Insurance4AgentName: z.string().optional(),
  address9Insurance4AgentEmail: OptionalEmailString,
  address9Insurance4AgentPhone: OptionalPhoneNumber,
  address9Insurance4CompanyName: z.string().optional(),
  address9Insurance4CompanyAddressLine1: AddressLine.optional(),
  address9Insurance4CompanyAddressLine2: AddressLine.optional(),
  address9Insurance4CompanyAddressCity: z.string().optional(),
  address9Insurance4CompanyAddressState: z.string().optional(),
  address9Insurance4CompanyAddressZip: z.string().optional(),
  address9Insurance4PolicyNumber: z.string().optional(),
  address9Insurance4PolicyStart: DateString.optional(),
  address9Insurance4PolicyEnd: DateString.optional(),

  address9Insurance5Type: z.string().optional(),
  address9Insurance5IsEscrowed: Checkbox.optional(),
  address9Insurance5Amount: NonNegativeMonetaryValue.optional(),
  address9Insurance5AgentName: z.string().optional(),
  address9Insurance5AgentEmail: OptionalEmailString,
  address9Insurance5AgentPhone: OptionalPhoneNumber,
  address9Insurance5CompanyName: z.string().optional(),
  address9Insurance5CompanyAddressLine1: AddressLine.optional(),
  address9Insurance5CompanyAddressLine2: AddressLine.optional(),
  address9Insurance5CompanyAddressCity: z.string().optional(),
  address9Insurance5CompanyAddressState: z.string().optional(),
  address9Insurance5CompanyAddressZip: z.string().optional(),
  address9Insurance5PolicyNumber: z.string().optional(),
  address9Insurance5PolicyStart: DateString.optional(),
  address9Insurance5PolicyEnd: DateString.optional(),

  address9Insurance6Type: z.string().optional(),
  address9Insurance6IsEscrowed: Checkbox.optional(),
  address9Insurance6Amount: NonNegativeMonetaryValue.optional(),
  address9Insurance6AgentName: z.string().optional(),
  address9Insurance6AgentEmail: OptionalEmailString,
  address9Insurance6AgentPhone: OptionalPhoneNumber,
  address9Insurance6CompanyName: z.string().optional(),
  address9Insurance6CompanyAddressLine1: AddressLine.optional(),
  address9Insurance6CompanyAddressLine2: AddressLine.optional(),
  address9Insurance6CompanyAddressCity: z.string().optional(),
  address9Insurance6CompanyAddressState: z.string().optional(),
  address9Insurance6CompanyAddressZip: z.string().optional(),
  address9Insurance6PolicyNumber: z.string().optional(),
  address9Insurance6PolicyStart: DateString.optional(),
  address9Insurance6PolicyEnd: DateString.optional(),

  // ADDRESS 10
  address10Insurance1Type: z.string().optional(),
  address10Insurance1IsEscrowed: Checkbox.optional(),
  address10Insurance1Amount: NonNegativeMonetaryValue.optional(),
  address10Insurance1AgentName: z.string().optional(),
  address10Insurance1AgentEmail: OptionalEmailString,
  address10Insurance1AgentPhone: OptionalPhoneNumber,
  address10Insurance1CompanyName: z.string().optional(),
  address10Insurance1CompanyAddressLine1: AddressLine.optional(),
  address10Insurance1CompanyAddressLine2: AddressLine.optional(),
  address10Insurance1CompanyAddressCity: z.string().optional(),
  address10Insurance1CompanyAddressState: z.string().optional(),
  address10Insurance1CompanyAddressZip: z.string().optional(),
  address10Insurance1PolicyNumber: z.string().optional(),
  address10Insurance1PolicyStart: DateString.optional(),
  address10Insurance1PolicyEnd: DateString.optional(),

  address10Insurance2Type: z.string().optional(),
  address10Insurance2IsEscrowed: Checkbox.optional(),
  address10Insurance2Amount: NonNegativeMonetaryValue.optional(),
  address10Insurance2AgentName: z.string().optional(),
  address10Insurance2AgentEmail: OptionalEmailString,
  address10Insurance2AgentPhone: OptionalPhoneNumber,
  address10Insurance2CompanyName: z.string().optional(),
  address10Insurance2CompanyAddressLine1: AddressLine.optional(),
  address10Insurance2CompanyAddressLine2: AddressLine.optional(),
  address10Insurance2CompanyAddressCity: z.string().optional(),
  address10Insurance2CompanyAddressState: z.string().optional(),
  address10Insurance2CompanyAddressZip: z.string().optional(),
  address10Insurance2PolicyNumber: z.string().optional(),
  address10Insurance2PolicyStart: DateString.optional(),
  address10Insurance2PolicyEnd: DateString.optional(),

  address10Insurance3Type: z.string().optional(),
  address10Insurance3IsEscrowed: Checkbox.optional(),
  address10Insurance3Amount: NonNegativeMonetaryValue.optional(),
  address10Insurance3AgentName: z.string().optional(),
  address10Insurance3AgentEmail: OptionalEmailString,
  address10Insurance3AgentPhone: OptionalPhoneNumber,
  address10Insurance3CompanyName: z.string().optional(),
  address10Insurance3CompanyAddressLine1: AddressLine.optional(),
  address10Insurance3CompanyAddressLine2: AddressLine.optional(),
  address10Insurance3CompanyAddressCity: z.string().optional(),
  address10Insurance3CompanyAddressState: z.string().optional(),
  address10Insurance3CompanyAddressZip: z.string().optional(),
  address10Insurance3PolicyNumber: z.string().optional(),
  address10Insurance3PolicyStart: DateString.optional(),
  address10Insurance3PolicyEnd: DateString.optional(),

  address10Insurance4Type: z.string().optional(),
  address10Insurance4IsEscrowed: Checkbox.optional(),
  address10Insurance4Amount: NonNegativeMonetaryValue.optional(),
  address10Insurance4AgentName: z.string().optional(),
  address10Insurance4AgentEmail: OptionalEmailString,
  address10Insurance4AgentPhone: OptionalPhoneNumber,
  address10Insurance4CompanyName: z.string().optional(),
  address10Insurance4CompanyAddressLine1: AddressLine.optional(),
  address10Insurance4CompanyAddressLine2: AddressLine.optional(),
  address10Insurance4CompanyAddressCity: z.string().optional(),
  address10Insurance4CompanyAddressState: z.string().optional(),
  address10Insurance4CompanyAddressZip: z.string().optional(),
  address10Insurance4PolicyNumber: z.string().optional(),
  address10Insurance4PolicyStart: DateString.optional(),
  address10Insurance4PolicyEnd: DateString.optional(),

  address10Insurance5Type: z.string().optional(),
  address10Insurance5IsEscrowed: Checkbox.optional(),
  address10Insurance5Amount: NonNegativeMonetaryValue.optional(),
  address10Insurance5AgentName: z.string().optional(),
  address10Insurance5AgentEmail: OptionalEmailString,
  address10Insurance5AgentPhone: OptionalPhoneNumber,
  address10Insurance5CompanyName: z.string().optional(),
  address10Insurance5CompanyAddressLine1: AddressLine.optional(),
  address10Insurance5CompanyAddressLine2: AddressLine.optional(),
  address10Insurance5CompanyAddressCity: z.string().optional(),
  address10Insurance5CompanyAddressState: z.string().optional(),
  address10Insurance5CompanyAddressZip: z.string().optional(),
  address10Insurance5PolicyNumber: z.string().optional(),
  address10Insurance5PolicyStart: DateString.optional(),
  address10Insurance5PolicyEnd: DateString.optional(),

  address10Insurance6Type: z.string().optional(),
  address10Insurance6IsEscrowed: Checkbox.optional(),
  address10Insurance6Amount: NonNegativeMonetaryValue.optional(),
  address10Insurance6AgentName: z.string().optional(),
  address10Insurance6AgentEmail: OptionalEmailString,
  address10Insurance6AgentPhone: OptionalPhoneNumber,
  address10Insurance6CompanyName: z.string().optional(),
  address10Insurance6CompanyAddressLine1: AddressLine.optional(),
  address10Insurance6CompanyAddressLine2: AddressLine.optional(),
  address10Insurance6CompanyAddressCity: z.string().optional(),
  address10Insurance6CompanyAddressState: z.string().optional(),
  address10Insurance6CompanyAddressZip: z.string().optional(),
  address10Insurance6PolicyNumber: z.string().optional(),
  address10Insurance6PolicyStart: DateString.optional(),
  address10Insurance6PolicyEnd: DateString.optional(),
});
