import { z } from 'zod';

import { WriteoffId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseUpsertTransactionSideEffect } from './BaseUpsertTransactionSideEffect';

export const UpsertWriteoffSideEffectContext = BaseUpsertTransactionSideEffect.extend({
  writeoffId: zodBrandedUuid<WriteoffId>(),
});
export const UpsertWriteoffSideEffect = UpsertWriteoffSideEffectContext.extend({
  type: z.literal('upsertWriteoff'),
});
export type UpsertWriteoffSideEffect = z.infer<typeof UpsertWriteoffSideEffect>;
