import { z } from 'zod';

import { PaymentId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const RepayMarkAsCancelledEvent = BaseLoanEvent.extend({
  type: z.literal('repayMarkAsCancelled'),
  paymentId: zodBrandedUuid<PaymentId>(),
});
export type RepayMarkAsCancelledEvent = z.infer<typeof RepayMarkAsCancelledEvent>;
