import { z } from 'zod';

import { PaymentDueId, PaymentId } from '../BrandedIds';
import { InvestorRemittanceSplit } from '../loan/InvestorRemittanceSplit';
import { PaymentBreakdown } from '../loan/PaymentBreakdown';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const FeeType = z.enum([
  'LATE',
  'DRAW',
  'EXTENSION',
  'PAYOFF',
  'PREPAYMENT_PENALTY',
  'LIEN_RELEASE',
  'PENDING_PAYMENT_AT_PAYOFF',
  'OTHER',
  'NON_SUFFICIENT_FUNDS',
]);
export type FeeType = z.infer<typeof FeeType>;

export const FeeStatus = z.enum(['CHARGED', 'CANCELLED', 'PAID', 'WAIVED']);
export type FeeStatus = z.infer<typeof FeeStatus>;

export const AdvanceType = z.enum(['CORPORATE']);
export type AdvanceType = z.infer<typeof AdvanceType>;

export const FeeLineItemInput = z.object({
  amount: z.number(),
  type: FeeType,
  name: z.string().optional(),
  investorRemittanceSplit: InvestorRemittanceSplit.optional(),
  paymentId: zodBrandedUuid<PaymentId>().optional(),
  paymentBreakdowns: PaymentBreakdown.array().optional(),
});
export type FeeLineItemInput = z.infer<typeof FeeLineItemInput>;

export const AdvanceConfigInput = z.object({
  interestRate: z.number(),
  advanceType: AdvanceType,
});
export type AdvanceConfigInput = z.infer<typeof AdvanceConfigInput>;

export const ChargeBaseEvent = BaseLoanEvent.extend({
  isDeferred: z.boolean(),
  comment: z.string().optional(),
});

export const CreateFeeEvent = ChargeBaseEvent.extend({
  type: z.literal('createFee'),
  paymentDueId: zodBrandedUuid<PaymentDueId>().optional(), // used to track automated fees
  lineItems: z.array(FeeLineItemInput),
});

export const CreateAdvanceEvent = ChargeBaseEvent.extend({
  type: z.literal('createAdvance'),
  advanceConfig: AdvanceConfigInput.optional(),
  advanceInterestUpdatedAt: zodDateOrString.optional(),
  recipient: z.string().optional(),
  chargeDate: zodDateOrString,
  advancePrincipal: z.number(),
  advanceInterest: z.number().optional(), // TODO REMOVE AFTER CONSTRUCTION MIGRATION IS DONE 1/19/2023
  status: FeeStatus.optional(), // TODO make required once migration is done 1/21/2023
  investorRemittanceSplit: InvestorRemittanceSplit.optional(),
});

export type CreateFeeEvent = z.infer<typeof CreateFeeEvent>;

export type CreateAdvanceEvent = z.infer<typeof CreateAdvanceEvent>;
