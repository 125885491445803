import { DateTime } from 'luxon';

import { CompanyReportType } from '../../CompanyReport';
import { RecurringCompanyReport } from '../../RecurringCompanyReport';
import { Timezone } from '../../Time';

export const FANNIE_MAE_TIMEZONE: Timezone = 'America/New_York' as const;
export const FANNIE_MAE_HOLIDAYS: DateTime[] = [
  DateTime.fromObject({ year: 2025, month: 1, day: 1 }),
  DateTime.fromObject({ year: 2025, month: 1, day: 20 }),
  DateTime.fromObject({ year: 2025, month: 2, day: 17 }),
  DateTime.fromObject({ year: 2025, month: 5, day: 26 }),
  DateTime.fromObject({ year: 2025, month: 6, day: 19 }),
  DateTime.fromObject({ year: 2025, month: 7, day: 4 }),
  DateTime.fromObject({ year: 2025, month: 9, day: 1 }),
  DateTime.fromObject({ year: 2025, month: 11, day: 11 }),
  DateTime.fromObject({ year: 2025, month: 11, day: 27 }),
  DateTime.fromObject({ year: 2025, month: 12, day: 25 }),
] as const;

export const FANNIE_MAE_DEFAULT_RECURRING_REPORT_VALUES: Record<
  | CompanyReportType.FANNIE_MAE_LAR_96_DAILY
  | CompanyReportType.FANNIE_MAE_LAR_96_MONTHLY
  | CompanyReportType.FANNIE_MAE_LAR_96_TRAILING
  | CompanyReportType.FANNIE_MAE_CRS_DRAFT_REQUEST,
  Pick<RecurringCompanyReport, 'deliveryHour' | 'deliveryTimeZone' | 'title'> & { deliveryDay: number }
> = {
  FANNIE_MAE_LAR_96_DAILY: {
    deliveryHour: 4,
    deliveryTimeZone: FANNIE_MAE_TIMEZONE,
    deliveryDay: 0,
    title: 'Fannie Mae LAR 96 Daily',
  },
  FANNIE_MAE_LAR_96_MONTHLY: {
    deliveryHour: 5,
    deliveryTimeZone: FANNIE_MAE_TIMEZONE,
    deliveryDay: 22,
    title: 'Fannie Mae LAR 96 Monthly',
  },
  FANNIE_MAE_LAR_96_TRAILING: {
    deliveryHour: 5,
    deliveryTimeZone: FANNIE_MAE_TIMEZONE,
    deliveryDay: 1,
    title: 'Fannie Mae LAR 96 Trailing',
  },
  FANNIE_MAE_CRS_DRAFT_REQUEST: {
    deliveryHour: 5,
    deliveryTimeZone: FANNIE_MAE_TIMEZONE,
    deliveryDay: 0,
    title: 'Fannie Mae CRS Draft Request',
  },
} as const;
