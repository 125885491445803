import * as z from 'zod';

import { LosId } from '../../BrandedIds';
import { zodBrandedString } from '../../utils';
import { dateIsOnFirstOfMonth, DateString, NonNegativeFloat } from '../fields';
import { unsafeCreateCastedSchema } from '../utils';

export const UpdateIdsAndRemittanceDataRow = z.object({
  loanId: zodBrandedString<LosId>(),
  effectiveDate: DateString.and(dateIsOnFirstOfMonth).optional(),
  soldDate: DateString.optional(),

  // External ids
  poolId: z.string().optional(),
  previousServicerId: z.string().optional(),
  capitalPartnerId: z.string().optional(),
  fullId: z.string().optional(),
  otherId1: z.string().optional(),
  otherId2: z.string().optional(),
  otherId3: z.string().optional(),
  ownerId: z.string().optional(),

  // Investor remittance fields
  investorRemittanceRate: NonNegativeFloat.optional(),
  investor2RemittanceRate: NonNegativeFloat.optional(),
  servicerRemittanceRate: NonNegativeFloat.optional(),
  investorOwnershipPercent: NonNegativeFloat.optional(),
  investor2OwnershipPercent: NonNegativeFloat.optional(),

  removeConcurrentAndFutureData: z.boolean().optional(),
});
export type UpdateIdsAndRemittanceDataRow = z.infer<typeof UpdateIdsAndRemittanceDataRow>;

const validateIdFields: z.SuperRefinement<UpdateIdsAndRemittanceDataRow> = (data, ctx) => {
  const keys = [
    'ownerId',
    'poolId',
    'previousServicerId',
    'capitalPartnerId',
    'fullId',
    'otherId1',
    'otherId2',
    'otherId3',
  ] as const;

  for (const key of keys) {
    // Note: excel is sometimes changing id fields to scientific format (ie 20+15243)
    if (data[key] && (data[key] as string).indexOf('+') >= 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'ID contains invalid "+" character',
        path: [key],
      });
    }

    if (data[key] && !data.effectiveDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'ID updates require an effective date',
        path: [key],
      });
    }
  }
};

export const UpdateIdsAndRemittanceDataRowRefinements =
  unsafeCreateCastedSchema(UpdateIdsAndRemittanceDataRow).superRefine(validateIdFields);

export const UPDATE_IDS_AND_REMITTANCE_DATA_REQUIRED_FIELDS = Object.keys(UpdateIdsAndRemittanceDataRow.shape).filter(
  (key) => !UpdateIdsAndRemittanceDataRow.shape[key as keyof typeof UpdateIdsAndRemittanceDataRow.shape].isOptional(),
);
