import * as z from 'zod';

import {
  CompanyId,
  EscrowSettingId,
  LoanId,
  LoanImportId,
  QcDocumentId,
  RecurringCompanyReportId,
  SftpSiteId,
  SideEffectId,
  UserId,
} from './BrandedIds';
import { CompanyReportFilters, CompanyReportType } from './CompanyReport';
import { LoanDashboardColumnKey, LoanDashboardQuery, LoanFilter } from './loanFilter/LoanFilter';
import { LoanTransactionColumnKey, LoanTransactionFilter } from './loanTransactionFilter/LoanTransactionFilter';
import { Form1098Year } from './qcDocument/documents/Form1098';
import { CreateForm1098CorrectionTypeInput } from './qcDocument/QcDocument';
import { SortBy } from './search/SortBy';
import { TaskFilter } from './tasks';
import { zodBrandedUuid } from './utils/Zod';

export interface ProcessFileUploadInput {
  companyId: CompanyId;
  importId: LoanImportId;
  source: 'csv' | 'csvV2';
}

export interface SendNewUserEmailInput {
  email: string;
  isSsoUser: boolean;
}

export interface RunSideEffect {
  sideEffectId: SideEffectId;
}

export type ExportDataFile = ExportLoansFile | ExportTransactionsFile | ExportTasksFile;

export interface ExportLoansFile {
  type: 'loans';
  companyId: CompanyId;
  userId: UserId;
  filters: LoanFilter[];
  sortBy?: SortBy;
  columns: LoanDashboardColumnKey[];
  query: LoanDashboardQuery;
  email?: string;
}

export interface ExportTransactionsFile {
  type: 'transactions';
  companyId: CompanyId;
  userId: UserId;
  filters: LoanTransactionFilter[];
  columns: LoanTransactionColumnKey[];
  query: string;
  email?: string;
}

export interface ExportTasksFile {
  type: 'tasks';
  companyId: CompanyId;
  userId: UserId;
  filters: TaskFilter[];
  query: string;
  sortBy?: SortBy;
  email?: string;
}

interface RebuildAccountsDashboard {
  companyId: CompanyId;
  type: 'rebuildAccountsDashboard';
}

interface RebuildMetricsDashboard {
  companyId: CompanyId;
  type: 'rebuildMetricsDashboard';
}

interface RebuildTransactionsDashboard {
  companyId: CompanyId;
  type: 'rebuildTransactionsDashboard';
  incrementChangeId: boolean;
}

export interface CreateCompanyReport {
  type: CompanyReportType;
  emails?: string[];
  sftpSiteId?: SftpSiteId;
  companyId: CompanyId;
  userId: UserId;
  filters: CompanyReportFilters;
  title?: string;
  recurringCompanyReportId?: RecurringCompanyReportId;
}

export type PerformAdminAction = RebuildAccountsDashboard | RebuildTransactionsDashboard | RebuildMetricsDashboard;

export interface GenerateQcDocs {
  companyId: CompanyId;
  qcDocumentIds: QcDocumentId[];
}

const DraftQcDocsBase = z.object({
  userId: zodBrandedUuid<UserId>(),
  companyId: zodBrandedUuid<CompanyId>(),
  loanIds: zodBrandedUuid<LoanId>().array().optional(),
});

const Draft1098Docs = DraftQcDocsBase.extend({
  type: z.literal('form1098'),
  year: Form1098Year,
  correctionType: CreateForm1098CorrectionTypeInput,
});

// TODO: Add escrow analysis to this. Make this discriminated union.
export const DraftQcDocs = Draft1098Docs;
export type DraftQcDocs = z.infer<typeof DraftQcDocs>;

const BaseExportMiscFile = z.object({
  userId: zodBrandedUuid<UserId>(),
  companyId: zodBrandedUuid<CompanyId>(),
  email: z.string().optional(),
});

const ExportMiscFileQcDocs = BaseExportMiscFile.extend({
  type: z.literal('qcDocs'),
  qcDocumentIds: zodBrandedUuid<QcDocumentId>().array(),
});

const ExportMiscFileEscrowHistory = BaseExportMiscFile.extend({
  type: z.literal('escrowHistory'),
  loanId: zodBrandedUuid<LoanId>(),
  escrowSettingId: zodBrandedUuid<EscrowSettingId>(),
});

const ExportMiscFile = z.discriminatedUnion('type', [ExportMiscFileQcDocs, ExportMiscFileEscrowHistory]);
export type ExportMiscFile = z.infer<typeof ExportMiscFile>;
