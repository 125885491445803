import { z } from 'zod';

import { WriteoffInput } from '../loan/Writeoff';
import { BaseLoanEvent } from './BaseLoanEvent';

export const RecordWriteoffEvent = BaseLoanEvent.extend({
  type: z.literal('recordWriteoff'),
  writeoff: WriteoffInput,
});

export type RecordWriteoffEvent = z.infer<typeof RecordWriteoffEvent>;
