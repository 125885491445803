import { z } from 'zod';

import { PaymentId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const RepayMarkAsProcessedEvent = BaseLoanEvent.extend({
  type: z.literal('repayMarkAsProcessed'),
  paymentId: zodBrandedUuid<PaymentId>(),
  transactionDate: zodDateOrString,
  postDate: zodDateOrString,
  effectiveDate: zodDateOrString,
});
export type RepayMarkAsProcessedEvent = z.infer<typeof RepayMarkAsProcessedEvent>;
