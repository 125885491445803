import { z } from 'zod';

import { Address } from '../Address';
import { PropertyId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';
import { NonNegativeMonetaryValue } from '../validations/fields';
import { AppraisalInput } from './Appraisal';

export const PropertyFlood = z.object({
  zone: z.string().optional(),
  zoneFlag: z.boolean().optional(),
  partialZoneIndicator: z.string().optional(),
  contractType: z.string().optional(),
  contractNumber: z.string().optional(),
  program: z.string().optional(),
  nfipDateRevisionType: z.string().optional(),
  nfipCommunityIdentifier: z.string().optional(),
  nfipMapIdentifier: z.string().optional(),
  nfipMapPanelDate: zodDateOrString.optional(),
  certificateProviderName: z.string().optional(),
  certificateNumber: z.string().optional(),
  determinationDate: zodDateOrString.optional(),
});
export type PropertyFlood = z.infer<typeof PropertyFlood>;

/**
 * Contracts that will be transferred to the next servicer
 */
export const TransferableMonitoringContracts = z.object({
  flood: z.boolean().optional(),
  tax: z.boolean().optional(),
});
export type TransferableMonitoringContracts = z.infer<typeof TransferableMonitoringContracts>;

export const Property = z.object({
  id: zodBrandedUuid<PropertyId>(),
  address: Address,
  isReleased: z.boolean(),
  coverageAmount: NonNegativeMonetaryValue.optional(),
  replacementCostValue: NonNegativeMonetaryValue.optional(),
  propertyType: z.string().optional(),
  propertySubType: z.string().optional(),
  flood: PropertyFlood.optional(),
  purchasePrice: NonNegativeMonetaryValue.optional(),
  numberOfUnits: z.number().positive().int().optional(),
  propertyValuationMethodType: z.string().optional(),
  transferableMonitoringContracts: TransferableMonitoringContracts.optional(),
});

export type Property = z.infer<typeof Property>;

export const PropertyInput = Property.omit({ id: true, isReleased: true }).extend({
  appraisal: AppraisalInput.optional(),
});
export type PropertyInput = z.infer<typeof PropertyInput>;
