import * as z from 'zod';

import { LosId } from '../BrandedIds';

export namespace GeneralLedgerCodesReport {
  export type RowDate = Date; // TODO PlainDate or `${number}-${number}-${number}` or... what? These probably aren't timestamps (but maybe they are)

  export interface BaseRow {
    transactionDate: RowDate;
    losId: LosId;
    productId: string;
    activityDescription: string;
  }
  export interface Row extends BaseRow {
    amount: number;
    drCode: string;
    crCode: string;
  }

  export type DoubleEntryRow = DoubleEntryCreditRow | DoubleEntryDebitRow;

  export interface DoubleEntryCreditRow extends BaseRow {
    account: string;
    debitAmount: undefined;
    creditAmount: number;
  }
  export interface DoubleEntryDebitRow extends BaseRow {
    account: string;
    debitAmount: number;
    creditAmount: undefined;
  }

  export type TransactionTypeSettings = z.infer<typeof TransactionTypeSettings>;
  export const TransactionTypeSettings = z.object({
    drCode: z.string(),
    crCode: z.string(),
  });

  export type Settings = z.infer<typeof Settings>;
  export const Settings = z.object({
    billedFeeLate: TransactionTypeSettings,
    billedFeeLienRelease: TransactionTypeSettings,
    billedFeeNsf: TransactionTypeSettings,
    billedFeeOther: TransactionTypeSettings,
    billedInterest: TransactionTypeSettings,
    billedPrincipal: TransactionTypeSettings,
    boardedPrincipal: TransactionTypeSettings,
    disbursedPrincipal: TransactionTypeSettings,
    disbursedSuspense: TransactionTypeSettings,
    paidFeeLate: TransactionTypeSettings,
    paidFeeLienRelease: TransactionTypeSettings,
    paidFeeNsf: TransactionTypeSettings,
    paidFeeOther: TransactionTypeSettings,
    paidInterest: TransactionTypeSettings,
    paidPrincipal: TransactionTypeSettings,
    paidSuspense: TransactionTypeSettings,
    prepayPrincipal: TransactionTypeSettings,
    prepaySuspense: TransactionTypeSettings,
    wroteOffFeeLate: TransactionTypeSettings,
    wroteOffFeeLienRelease: TransactionTypeSettings,
    wroteOffFeeNsf: TransactionTypeSettings,
    wroteOffFeeOther: TransactionTypeSettings,
    wroteOffPrincipal: TransactionTypeSettings,
    wroteOffSuspense: TransactionTypeSettings,
  }) satisfies z.ZodType<Record<EventType, TransactionTypeSettings>>;

  const emptyTransactionTypeSettings = (): TransactionTypeSettings => ({
    drCode: '',
    crCode: '',
  });

  /**
   * Populates the settings with empty values for any missing categories.
   */
  export const settingsFromPartialSettings = (partialSettings: Partial<Settings>): Settings => ({
    billedFeeLate: emptyTransactionTypeSettings(),
    billedFeeLienRelease: emptyTransactionTypeSettings(),
    billedFeeNsf: emptyTransactionTypeSettings(),
    billedFeeOther: emptyTransactionTypeSettings(),
    billedInterest: emptyTransactionTypeSettings(),
    billedPrincipal: emptyTransactionTypeSettings(),
    boardedPrincipal: emptyTransactionTypeSettings(),
    disbursedPrincipal: emptyTransactionTypeSettings(),
    disbursedSuspense: emptyTransactionTypeSettings(),
    paidFeeLate: emptyTransactionTypeSettings(),
    paidFeeLienRelease: emptyTransactionTypeSettings(),
    paidFeeNsf: emptyTransactionTypeSettings(),
    paidFeeOther: emptyTransactionTypeSettings(),
    paidInterest: emptyTransactionTypeSettings(),
    paidPrincipal: emptyTransactionTypeSettings(),
    paidSuspense: emptyTransactionTypeSettings(),
    prepayPrincipal: emptyTransactionTypeSettings(),
    prepaySuspense: emptyTransactionTypeSettings(),
    wroteOffFeeLate: emptyTransactionTypeSettings(),
    wroteOffFeeLienRelease: emptyTransactionTypeSettings(),
    wroteOffFeeNsf: emptyTransactionTypeSettings(),
    wroteOffFeeOther: emptyTransactionTypeSettings(),
    wroteOffPrincipal: emptyTransactionTypeSettings(),
    wroteOffSuspense: emptyTransactionTypeSettings(),
    ...partialSettings,
  });

  export type TransactionType = 'boarded' | 'billed' | 'disbursed' | 'paid' | 'prepay' | 'wroteOff';

  export type ValidTransactionTypeBySettingsCategory = typeof ValidTransactionTypeBySettingsCategory;
  export const ValidTransactionTypeBySettingsCategory = {
    principal: ['boarded', 'billed', 'disbursed', 'paid', 'prepay', 'wroteOff'],
    interest: ['billed', 'paid'],
    suspense: ['disbursed', 'paid', 'prepay', 'wroteOff'],
    feeNsf: ['billed', 'paid', 'wroteOff'],
    feeLate: ['billed', 'paid', 'wroteOff'],
    feeLienRelease: ['billed', 'paid', 'wroteOff'],
    feeOther: ['billed', 'paid', 'wroteOff'],
  } as const satisfies Record<SettingCategory, TransactionType[]>;

  export type EventTypeBySettingsCategory = {
    [key in keyof ValidTransactionTypeBySettingsCategory]: `${ValidTransactionTypeBySettingsCategory[key][number] extends string
      ? ValidTransactionTypeBySettingsCategory[key][number]
      : never}${Capitalize<key>}`[];
  };

  export type EventType = EventTypeBySettingsCategory[keyof ValidTransactionTypeBySettingsCategory][number];

  export type SettingCategory = z.infer<typeof SettingCategory>;
  export const SettingCategory = z.enum([
    'principal',
    'interest',
    'suspense',
    'feeNsf',
    'feeLate',
    'feeLienRelease',
    'feeOther',
  ]);
}
