import { z } from 'zod';

import { OptionalPhoneNumber } from '../../../Phone';
import { OptionalEmailString } from '../../email';
import { AddressLine, Checkbox } from '../../fields';

export const AuthorizedContactFields = z.object({
  builderCompanyName: z
    .string()
    .optional()
    .describe(
      'Provide fields if the loan has a builder. Set builderIsResponsibleForPayment to true if the builder should be sent statements for the loan.\nAll fields are required when builderCompanyName field is present.',
    ), // required to create contact
  builderAddressLine1: AddressLine.optional(),
  builderAddressLine2: AddressLine.optional(),
  builderAddressCity: z.string().optional(),
  builderAddressState: z.string().optional(),
  builderAddressZip: z.string().optional(),
  builderEmail: OptionalEmailString,
  builderPhone: OptionalPhoneNumber,
  builderEmailConsent: Checkbox.optional(),
  builderIsResponsibleForPayment: Checkbox.optional(), // loan type validation. defaults false
});
