import * as z from 'zod';

import { FeeId, PaymentDueId, PaymentId, PayoffId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { Payoff } from './Payoffs';

export const StatementPaymentBreakdownType = z.literal('statement');
export const SuspensePaymentBreakdownType = z.literal('suspense');
export const PrincipalPrepaymentPaymentBreakdownType = z.literal('principalPrepayment');
export const FeePaymentBreakdownType = z.literal('fee');
export const AdvancePaymentBreakdownType = z.literal('advance');
export const PayoffPaymentBreakdownType = z.literal('payoff');
export const ThirdPartyDepositBreakdownType = z.literal('thirdParty');
export const BalanceReallocationBreakdownType = z.literal('balanceReallocation');

export const PaymentBreakdownType = z.union([
  StatementPaymentBreakdownType,
  SuspensePaymentBreakdownType,
  PrincipalPrepaymentPaymentBreakdownType,
  FeePaymentBreakdownType,
  AdvancePaymentBreakdownType,
  PayoffPaymentBreakdownType,
  ThirdPartyDepositBreakdownType,
  BalanceReallocationBreakdownType,
]);
export type PaymentBreakdownType = z.infer<typeof PaymentBreakdownType>;

const BaseBreakdown = z.object({
  paymentId: zodBrandedUuid<PaymentId>(),
  amount: z.number(),
  expectedPayment: z.number(),
});

const StatementBreakdown = BaseBreakdown.extend({
  type: StatementPaymentBreakdownType,
  paymentDueId: zodBrandedUuid<PaymentDueId>(),
  principal: z.number(),
  interest: z.number(),
  escrow: z.number(),
  additionalPrincipal: z.number(),
  additionalEscrow: z.number(),
  suspense: z.number(),
  reserve: z.number().optional(),
});
export type StatementBreakdown = z.infer<typeof StatementBreakdown>;

const SuspenseBreakdown = BaseBreakdown.extend({
  type: SuspensePaymentBreakdownType,
  suspense: z.number(),
});
export type SuspenseBreakdown = z.infer<typeof SuspenseBreakdown>;

const PrincipalPrepaymentBreakdown = BaseBreakdown.extend({
  type: PrincipalPrepaymentPaymentBreakdownType,
  additionalPrincipal: z.number(),
  reserve: z.number().optional(),
});
export type PrincipalPrepaymentBreakdown = z.infer<typeof PrincipalPrepaymentBreakdown>;

const FeeBreakdown = BaseBreakdown.extend({
  type: FeePaymentBreakdownType,
  feeId: zodBrandedUuid<FeeId>(),
});
export type FeeBreakdown = z.infer<typeof FeeBreakdown>;

const AdvanceBreakdown = BaseBreakdown.extend({
  type: AdvancePaymentBreakdownType,
  feeId: zodBrandedUuid<FeeId>(),
  advance: z.number(),
  advanceInterest: z.number(),
});
export type AdvanceBreakdown = z.infer<typeof AdvanceBreakdown>;

const PayoffBreakdown = BaseBreakdown.extend({
  type: PayoffPaymentBreakdownType,
  payoffId: zodBrandedUuid<PayoffId>(),
  principal: z.number(),
  interest: z.number(),
  escrow: z.number(),
  suspense: z.number(),
  reserve: z.number(),
  fees: z.number(),
  additionalPrincipal: z.number().optional(),
  additionalEscrow: z.number().optional(),
});
export type PayoffBreakdown = z.infer<typeof PayoffBreakdown>;

const ThirdPartyBreakdown = BaseBreakdown.extend({
  type: ThirdPartyDepositBreakdownType,
  escrow: z.number(),
  holdback: z.number(),
  suspense: z.number().optional(), // TODO #6889
  principal: z.number(),
});
export type ThirdPartyBreakdown = z.infer<typeof ThirdPartyBreakdown>;

const BalanceReallocationBreakdown = BaseBreakdown.extend({
  type: BalanceReallocationBreakdownType,
  escrow: z.number(),
  holdback: z.number(),
  suspense: z.number().optional(), // TODO #6889
  principal: z.number(),
});
export type BalanceReallocationBreakdown = z.infer<typeof BalanceReallocationBreakdown>;

export const PaymentBreakdown = z.discriminatedUnion('type', [
  StatementBreakdown,
  SuspenseBreakdown,
  PrincipalPrepaymentBreakdown,
  FeeBreakdown,
  AdvanceBreakdown,
  PayoffBreakdown,
  ThirdPartyBreakdown,
  BalanceReallocationBreakdown,
]);
export type PaymentBreakdown = z.infer<typeof PaymentBreakdown>;

export const ChargeBreakdown = z.discriminatedUnion('type', [FeeBreakdown, AdvanceBreakdown]);
export type ChargeBreakdown = z.infer<typeof ChargeBreakdown>;

export function isStatementBreakdown(item: PaymentBreakdown): item is StatementBreakdown {
  return item.type === 'statement';
}
export function isSuspenseBreakdown(item: PaymentBreakdown): item is SuspenseBreakdown {
  return item.type === 'suspense';
}
export function isPrincipalPrepaymentBreakdown(item: PaymentBreakdown): item is PrincipalPrepaymentBreakdown {
  return item.type === 'principalPrepayment';
}
export function isFeeBreakdown(item: PaymentBreakdown): item is FeeBreakdown {
  return item.type === 'fee';
}
export function isThirdPartyBreakdown(item: PaymentBreakdown): item is ThirdPartyBreakdown {
  return item.type === 'thirdParty';
}
export function isBalanceReallocationBreakdown(item: PaymentBreakdown): item is BalanceReallocationBreakdown {
  return item.type === 'balanceReallocation';
}

export function isAdvanceBreakdown(item: PaymentBreakdown): item is AdvanceBreakdown {
  return item.type === 'advance';
}

export function isChargeBreakdown(item: PaymentBreakdown): item is ChargeBreakdown {
  return item.type === 'fee' || item.type === 'advance';
}

export function isPayoffBreakdown(item: PaymentBreakdown): item is PayoffBreakdown {
  return item.type === 'payoff';
}

export function isFullPayoffBreakdown(item: PaymentBreakdown, payoffs: Payoff[]): item is PayoffBreakdown {
  const fullPayoffIds = payoffs.filter((p) => p.isPartial !== true).map((p) => p.id);
  return item.type === 'payoff' && fullPayoffIds.includes(item.payoffId);
}

export function isPartialPayoffBreakdown(item: PaymentBreakdown, payoffs: Payoff[]): item is PayoffBreakdown {
  const partialPayoffIds = payoffs.filter((p) => p.isPartial === true).map((p) => p.id);
  return item.type === 'payoff' && partialPayoffIds.includes(item.payoffId);
}
