import { z } from 'zod';

import { MERSMIN, MERSOrgId } from '../../../BrandedIds';
import { MERSMINStatus } from '../../../loan/MERS';
import { zodBrandedString, zodDateOrString } from '../../../utils/Zod';
import { Checkbox, DateString } from '../../fields';

export const MERSCreateFields = z.object({
  registrationDate: DateString.optional(),
  MIN: z.string().optional(),
  MINStatus: MERSMINStatus.optional(),
  orgId: z.string().optional(),
  momFlag: Checkbox.optional(),
});

// Transform to branded IDs + Date
export const MERSImportFields = z.object({
  ...MERSCreateFields.shape,
  registrationDate: zodDateOrString.optional(),
  MIN: zodBrandedString<MERSMIN>().optional(),
  orgId: zodBrandedString<MERSOrgId>().optional(),
});
