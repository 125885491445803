import * as z from 'zod';

import { ApiKeyId, CompanyId, UserId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';

export type ApiCRUD = 'GET' | 'PUT' | 'POST' | 'DELETE';

export const ApiKeyScopes = z.object({
  transactions: z.array(z.enum(['GET', 'POST'])).optional(),
  loans: z.array(z.enum(['GET', 'POST', 'PUT'])).optional(),
  loanUpdates: z.array(z.literal('PUT')).optional(), // @deprecated, remove in favor of loan scope
  documents: z.array(z.literal('GET')).optional(),
  transfers: z.array(z.literal('POST')).optional(),
  loanTransactions: z.array(z.literal('GET')).optional(),
  escrowDisbursements: z.array(z.literal('POST')).optional(),
  constructionToPermanent: z.array(z.literal('POST')).optional(),
  pointOfContact: z.array(z.literal('POST')).optional(),
  borrowerACHDetails: z.array(z.literal('POST')).optional(),
});
export type ApiKeyScopes = z.infer<typeof ApiKeyScopes>;

export const ApiKey = z.object({
  id: zodBrandedUuid<ApiKeyId>(),
  userId: zodBrandedUuid<UserId>(),
  companyId: zodBrandedUuid<CompanyId>(),
  scopes: ApiKeyScopes,
  description: z.string().min(1),
  createdAt: zodDateOrString,
  updatedAt: zodDateOrString,
  deletedAt: zodDateOrString.optional(),
});
export type ApiKey = z.infer<typeof ApiKey>;

export const ApiKeyInput = ApiKey.pick({ userId: true, companyId: true, scopes: true, description: true });
export type ApiKeyInput = z.infer<typeof ApiKeyInput>;
