import { EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import { isEqual, omit } from 'lodash';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import { EditBorrowerDetailsDocument, PortalSelfDocument } from '@willow/graphql-iso/src/portal';
import { formatGqlDayAndTimeRange, phoneNumberFormat } from '@willow/shared-iso';
import {
  Button,
  ControlledInput,
  ControlledPreferredContactTimes,
  DayAndTimeRange,
  PORTAL_LOAN_PATHS,
  Sentry,
  useZodHookForm,
  z,
} from '@willow/shared-web';
import { CompanyId, OptionalPhoneNumber } from '@willow/types-iso';

import { PortalSelectedLoan } from '../../App';
import { EmptyPortalUser, PortalUser } from '../context/AppContexts';

interface Props {
  companyId: CompanyId;
  user: PortalUser | EmptyPortalUser;
  loan: PortalSelectedLoan;
}

const FormShape = z.object({
  phoneHome: OptionalPhoneNumber,
  phoneWork: OptionalPhoneNumber,
  phoneCell: OptionalPhoneNumber,
  preferredContactTimes: z.array(DayAndTimeRange).optional(),
});
type TFormShape = z.infer<typeof FormShape>;

export const AccountInformation = ({ user, companyId, loan }: Props) => {
  const [isEditForm, setIsEditForm] = useState(false);
  const [editBorrowerDetails] = useMutation(EditBorrowerDetailsDocument, {
    refetchQueries: [PortalSelfDocument],
  });

  const borrowerUser = [loan.primaryBorrower, ...loan.additionalBorrowers].find((b) => b.id === user.borrowerId);
  if (!borrowerUser) {
    Sentry.captureException(
      `User's borrowerId does not exist on loan. borrowerId=${user.borrowerId}, loanId=${loan.id}, company=${loan.company.id}`,
    );
    return <></>;
  }

  const DEFAULT_VALUES: TFormShape = {
    phoneHome: borrowerUser.phoneNumbers.home ? phoneNumberFormat(borrowerUser.phoneNumbers.home) : undefined,
    phoneWork: borrowerUser.phoneNumbers.work ? phoneNumberFormat(borrowerUser.phoneNumbers.work) : undefined,
    phoneCell: borrowerUser.phoneNumbers.cell ? phoneNumberFormat(borrowerUser.phoneNumbers.cell) : undefined,
    preferredContactTimes: borrowerUser.preferredContactTimes.map((pct) => omit(pct, '__typename')),
  };

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors, isSubmitting, isDirty },
  } = useZodHookForm(FormShape, {
    defaultValues: DEFAULT_VALUES,
  });
  const preferredContactTimes = watch('preferredContactTimes');

  const onSubmit = handleSubmit(async (data) => {
    // Note: isDirty isn't handling deep comparisons
    const hasContactTimeChanges = !isEqual(
      data.preferredContactTimes,
      borrowerUser.preferredContactTimes.map((pct) => omit(pct, '__typename')),
    );

    if (!isDirty && !hasContactTimeChanges) {
      toast.error('No changes detected to your account information. Please try again');
      return;
    }

    try {
      await editBorrowerDetails({
        variables: {
          companyId,
          loanId: loan.id,
          input: data,
        },
      });

      toast.success('Your account information has been successfully updated.');
      setIsEditForm(false);
      reset({
        phoneHome: data.phoneHome,
        phoneCell: data.phoneCell,
        phoneWork: data.phoneWork,
        preferredContactTimes: data.preferredContactTimes,
      });
    } catch (err) {
      toast.error('Something went wrong updating your account information, please try again.');
    }
  });

  return (
    <>
      <div className={classNames({ 'u-bg-bark00': isEditForm })}>
        <div className="mb-2 u-border-1 u-border-color-bark1 u-border-r-3">
          <div className="px-4 py-3 d-flex align-items-center justify-content-between u-border-b-1 u-border-color-bark1">
            <h3 className="fw-bold">Full Name</h3>
            <p className="u-color-bark3">
              {user.firstName} {user.lastName}
            </p>
          </div>

          <div className="px-4 py-3 d-flex align-items-center justify-content-between u-border-b-1 u-border-color-bark1">
            <h3 className="fw-bold">Email Address</h3>
            <p className="u-color-bark3">{user.email}</p>
          </div>

          <div className="px-4 py-3 d-flex align-items-center justify-content-between u-border-b-1 u-border-color-bark1">
            <h3 className="fw-bold">Phone (home)</h3>
            {isEditForm ? (
              <div>
                <ControlledInput fieldName="phoneHome" control={control} placeholder="(555) 555-5555" />
              </div>
            ) : (
              <p className="u-color-bark3">
                {borrowerUser.phoneNumbers.home && phoneNumberFormat(borrowerUser.phoneNumbers.home)}
              </p>
            )}
          </div>
          <div className="px-4 py-3 d-flex align-items-center justify-content-between u-border-b-1 u-border-color-bark1">
            <h3 className="fw-bold">Phone (cell)</h3>
            {isEditForm ? (
              <div>
                <ControlledInput fieldName="phoneCell" control={control} placeholder="(555) 555-5555" />
              </div>
            ) : (
              <p className="u-color-bark3">
                {borrowerUser.phoneNumbers.cell && phoneNumberFormat(borrowerUser.phoneNumbers.cell)}
              </p>
            )}
          </div>
          <div className="px-4 py-3 d-flex align-items-center justify-content-between u-border-b-1 u-border-color-bark1">
            <h3 className="fw-bold">Phone (work)</h3>
            {isEditForm ? (
              <div>
                <ControlledInput fieldName="phoneWork" control={control} placeholder="(555) 555-5555" />
              </div>
            ) : (
              <p className="u-color-bark3">
                {borrowerUser.phoneNumbers.work && phoneNumberFormat(borrowerUser.phoneNumbers.work)}
              </p>
            )}
          </div>

          <div
            className={classNames('px-4 py-3', {
              'd-flex align-items-top justify-content-between': !isEditForm,
            })}
          >
            <h3 className="fw-bold">Preferred Contact Time</h3>

            {isEditForm ? (
              <div className="mt-3">
                <ControlledPreferredContactTimes
                  control={control}
                  preferredContactTimes={preferredContactTimes}
                  setValue={setValue}
                  errors={errors}
                />
              </div>
            ) : (
              <div>
                {borrowerUser.preferredContactTimes?.map((t, index) => {
                  return (
                    <div className="u-color-bark3" key={`pt-${index}`}>
                      {formatGqlDayAndTimeRange(t)}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end mt-3">
        {isEditForm ? (
          <>
            {!isSubmitting && (
              <Button
                type="button"
                variant="secondary"
                size="sm"
                className="me-2"
                onClick={() => {
                  setIsEditForm(false);
                  reset();
                }}
              >
                Cancel
              </Button>
            )}

            <Button type="submit" variant="primary" size="sm" onClick={onSubmit} loading={isSubmitting}>
              Save
            </Button>
          </>
        ) : (
          <Button type="button" variant="secondary" size="sm" onClick={() => setIsEditForm(true)}>
            <EditOutlined className="me-2" /> Edit
          </Button>
        )}
      </div>

      <div className="mt-5 mx-auto text-center" style={{ maxWidth: '300px' }}>
        If your account information has changed
        <br />
        <NavLink
          to={PORTAL_LOAN_PATHS.manageLoan.contactUs({ losId: loan.losId, companyId })}
          exact={false}
          className="u-color-blue1 u-bold"
        >
          contact us for assistance.
        </NavLink>
      </div>
    </>
  );
};
