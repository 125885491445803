import * as z from 'zod';

import { BorrowerId, EntityId, EventId, LoanId } from '../BrandedIds';
import { PaymentDue } from '../PaymentDue';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';
import { NonNegativeFloat } from '../validations/fields';
import { Appraisal } from './Appraisal';
import { AutopaySettings } from './AutoPaySettings';
import { BillSetting } from './BillSettings';
import { Charge } from './Charges';
import { Disbursement } from './Disbursement';
import { LoanDocument } from './Document';
import { Draw } from './Draw';
import { EscrowCompany } from './EscrowCompany';
import { EscrowDue } from './EscrowDue';
import { EscrowPaymentHistoryItem } from './EscrowPaymentHistoryItem';
import { EscrowSetting } from './EscrowSetting';
import { InitialBalances } from './InitialBalances';
import { LoanInvestorRemittanceSplit } from './InvestorRemittanceSplit';
import { AmortizationType, ConstructionToPermanentStatus } from './Loan';
import { LoanStatus } from './LoanStatus';
import { LoanTransferData } from './LoanTransfer';
import { MaturityConfig } from './MaturityConfig';
import { NonPerformanceData } from './NonPerformance';
import { OriginationBalances } from './OriginationBalances';
import { PaymentBreakdown } from './PaymentBreakdown';
import { PaymentConfig } from './PaymentConfig';
import { Payment } from './Payments';
import { PaidOffRecord, Payoff } from './Payoffs';
import { PrincipalModification } from './PrincipalModification';
import { Property } from './Property';
import { BalanceSnapshot, PaymentSnapshot } from './Snapshots';
import { StopSettings } from './StopSettings';
import { WorkoutPlan } from './WorkoutPlan';
import { Writeoff } from './Writeoff';

export const LoanPaymentStatus = z.enum(['CURRENT', 'PASTDUE']);
export type LoanPaymentStatus = z.infer<typeof LoanPaymentStatus>;

export const LoanSnapshotBorrowerContacts = z.object({
  primaryBorrowerId: zodBrandedUuid<BorrowerId>(),
  additionalBorrowerIds: z.array(zodBrandedUuid<BorrowerId>()),
});
export type LoanSnapshotBorrowerContacts = z.infer<typeof LoanSnapshotBorrowerContacts>;

export const ConstructionConfig = z.object({
  constructionToPermanentStatus: ConstructionToPermanentStatus,
  constructionModificationEffectiveDate: zodDateOrString.optional(),
  postModificationAmortizationType: AmortizationType.optional(),
});

export type ConstructionConfig = z.infer<typeof ConstructionConfig>;

export const LoanSnapshotConfig = z.object({
  amortizationMonth: z.number(),
  nextStatementDate: zodDateOrString,
  productDescription: z.string(),
  contacts: z
    .object({
      borrowers: LoanSnapshotBorrowerContacts,
      entity: zodBrandedUuid<EntityId>().optional(),
    })
    .optional(),
  escrowCompanies: z.array(EscrowCompany),
  properties: z.array(Property),
  transferData: LoanTransferData.optional(),
  documents: z.array(LoanDocument),
  principalPurchased: NonNegativeFloat.optional(),

  // balances
  initialBalances: InitialBalances,
  // used for loans boarding as pendingActivation
  originationBalances: OriginationBalances.optional(),
  balanceHistory: z.array(BalanceSnapshot),

  // status and flags
  loanStatus: LoanStatus,
  flagged: z.boolean(),
  nonPerformanceData: NonPerformanceData.optional(),
  /**
   * @deprecated
   * use function getStopSettings instead when validating loan actions
   */
  stopSettings: StopSettings,

  // payments
  billSettings: z.array(BillSetting),
  snapshot: PaymentSnapshot,
  paystring: z.string().optional(),
  paymentsDue: z.array(PaymentDue),
  paymentsMade: z.array(Payment),
  paymentToPaymentDue: z.array(PaymentBreakdown),
  fees: z.array(Charge), // TODO migrate to charges
  draws: z.array(Draw),
  disbursements: z.array(Disbursement),
  paymentConfigHistory: z.array(PaymentConfig),
  investorRemittanceSplit: z.array(LoanInvestorRemittanceSplit),

  autopaySettings: AutopaySettings.optional(),

  maturityConfig: MaturityConfig,

  constructionConfig: ConstructionConfig.optional(),
  principalModifications: z.array(PrincipalModification),

  // escrow
  escrowSettings: z.array(EscrowSetting),
  escrowDues: z.array(EscrowDue),
  escrowPaymentHistory: z.array(EscrowPaymentHistoryItem),

  // appraisal
  appraisals: z.array(Appraisal),

  // payoffs
  payoffs: z.array(Payoff),
  paidOffRecord: PaidOffRecord.optional(),
  writeoffs: z.array(Writeoff).optional(), // TODO remove optional when migration has run

  // email tracking
  sentEmails: z.object({
    welcome: z.boolean(),
    gracePastDue: z.boolean(),
    escrowCompany: z.boolean(),
    finalPayment: z.boolean(),
  }),

  workoutPlans: z.array(WorkoutPlan),

  // report generation settings
  reportSettings: z.object({
    tax1098: z.boolean().default(true),
  }),

  nextServicerCollectsLatePayments: z.boolean().optional(),
});
export type LoanSnapshotConfig = z.infer<typeof LoanSnapshotConfig>;

export interface LoanSnapshotInput {
  loanId: LoanId;
  version: number;
  configuration: LoanSnapshotConfig;
}

export interface LoanSnapshot extends LoanSnapshotInput {
  eventId: EventId;
  createdAt: Date;
  updatedAt: Date;
}
