import { z } from 'zod';

import {
  DisbursementId,
  DrawId,
  FeeId,
  LoanId,
  PaymentId,
  PayoffId,
  PrincipalModificationId,
  WorkoutPlanId,
  WriteoffId,
} from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';

export const LoanPaymentStatus = z.enum(['CURRENT', 'PASTDUE']);
export type LoanPaymentStatus = z.infer<typeof LoanPaymentStatus>;

export const PaymentSnapshotBalances = z.object({
  suspenseBalance: z.number(),
  escrowBalance: z.number(),
  netEscrowBalance: z.number(),
  reserveBalance: z.number().optional(),
  feeBalance: z.number().optional(),
  holdbackBalance: z.number().optional(), // TODO make required
  feesOutstanding: z.number().optional(), // excludes deferred fees
  feesOutstandingIncludingDeferred: z.number().optional(),
  advancesPrincipalOutstanding: z.number().optional(),
  principalPrepaymentBalance: z.number(), // this is not a real balance, more of a total
});
export type PaymentSnapshotBalances = z.infer<typeof PaymentSnapshotBalances>;

export const PaymentSnapshotTotalPaid = z.object({
  sum: z.number(),
  principal: z.number(),
  interest: z.number(),
  escrow: z.number(),
  fee: z.number(),
  advance: z.number(),
  advancePrincipal: z.number().optional(),
  advanceInterest: z.number().optional(),
  holdback: z.number().optional(),
});
export type PaymentSnapshotTotalPaid = z.infer<typeof PaymentSnapshotTotalPaid>;

export const PaymentSnapshot = z.object({
  outstandingBalance: z.number(),
  totalPaid: PaymentSnapshotTotalPaid,
  balances: PaymentSnapshotBalances,
  status: LoanPaymentStatus,
});
export type PaymentSnapshot = z.infer<typeof PaymentSnapshot>;

export const BalancePaymentSnapshot = PaymentSnapshot.omit({ status: true }).extend({
  previousOutstandingBalance: z.number(),
});
export type BalancePaymentSnapshot = z.infer<typeof BalancePaymentSnapshot>;

const BoardingEvent = z.object({
  type: z.literal('boarding'),
  id: zodBrandedUuid<LoanId>(),
});

const PaymentEvent = z.object({
  type: z.literal('payment'),
  id: zodBrandedUuid<PaymentId>(),
});

const DisbursementEvent = z.object({
  type: z.literal('disbursement'),
  id: zodBrandedUuid<DisbursementId>(),
});

const DrawEvent = z.object({
  type: z.literal('draw'),
  id: zodBrandedUuid<DrawId>(),
});

const PayoffEvent = z.object({
  type: z.literal('payoff'),
  id: zodBrandedUuid<PayoffId>(),
});

const AdvanceEvent = z.object({
  type: z.literal('advance'),
  id: zodBrandedUuid<FeeId>(),
});

const FeeEvent = z.object({
  type: z.literal('fee'),
  id: zodBrandedUuid<FeeId>(),
});

const ContructionToPermanentEvent = z.object({
  type: z.literal('constructionToPermanent'),
  id: zodBrandedUuid<LoanId>(),
});

const WorkoutPlanEvent = z.object({
  type: z.literal('workoutPlan'),
  id: zodBrandedUuid<WorkoutPlanId>(),
});

const PrincipalModificationEvent = z.object({
  type: z.literal('principalModification'),
  id: zodBrandedUuid<PrincipalModificationId>(),
});

const WriteoffEvent = z.object({
  type: z.literal('writeoff'),
  id: zodBrandedUuid<WriteoffId>(),
});

const BalanceEvent = z.discriminatedUnion('type', [
  BoardingEvent,
  PaymentEvent,
  DisbursementEvent,
  DrawEvent,
  PayoffEvent,
  AdvanceEvent,
  FeeEvent,
  ContructionToPermanentEvent,
  WorkoutPlanEvent,
  PrincipalModificationEvent,
  WriteoffEvent,
]);
export type BalanceEvent = z.infer<typeof BalanceEvent>;

export const BalanceSnapshot = z.object({
  transactionDate: zodDateOrString,
  snapshot: BalancePaymentSnapshot,
  event: BalanceEvent,
});
export type BalanceSnapshot = z.infer<typeof BalanceSnapshot>;
