import { z } from 'zod';

import { WriteoffId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils';

export const Writeoff = z.object({
  id: zodBrandedUuid<WriteoffId>(),
  effectiveDate: zodDateOrString,
  status: z.enum(['active']), // we don't currently support cancelled or reversed writeoffs but should in future so adding status here spares us a migration.
  amounts: z.object({
    escrow: z.number(),
    suspense: z.number(),
  }),
  comment: z.string().optional(),
  createdAt: zodDateOrString,
  updatedAt: zodDateOrString,
});

export type Writeoff = z.infer<typeof Writeoff>;

export const WriteoffInput = Writeoff.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
});

export type WriteoffInput = z.infer<typeof WriteoffInput>;
