import { isEmpty } from 'lodash';
import * as z from 'zod';

import { StrictAddress } from '../../Address';
import { BankAccountNumber, BankRoutingNumber } from '../../BankAccount';
import { LosId } from '../../BrandedIds';
import { typedSafeParse, zodBrandedString } from '../../utils';
import { UsioEmailString } from '../email';
import { AddressLine, Checkbox, NonNegativeInteger, StateAbbreviation, Zipcode } from '../fields';
import { unsafeCreateCastedSchema } from '../utils';

export const BankAccountTypeInput = z.enum(['checking', 'savings']);
export const BankAccountTypeEnum = z.preprocess((val) => {
  if (typeof val === 'string') {
    return val.toLowerCase();
  }
  return val;
}, BankAccountTypeInput);

export const UpdateBorrowerAchDetailsRow = z.object({
  loanId: zodBrandedString<LosId>(),
  bankAccountType: BankAccountTypeEnum,
  accountNumber: BankAccountNumber,
  routingNumber: BankRoutingNumber,
  businessAccountName: z.string().optional(),
  businessAccountType: z.string().optional(),
  borrowerEmailAddress: UsioEmailString,
  mailingAddressLine1: AddressLine.optional(),
  mailingAddressLine2: AddressLine.optional(),
  mailingAddressCity: z.string().optional(),
  mailingAddressState: StateAbbreviation.optional().or(z.literal('')),
  mailingAddressZip: Zipcode.optional(),
  autoDraftEnabled: Checkbox.optional(),
  autoDraftDate: NonNegativeInteger.optional(),
});
export type UpdateBorrowerAchDetailsRow = z.infer<typeof UpdateBorrowerAchDetailsRow>;

const checkFullMailingAddress: z.SuperRefinement<UpdateBorrowerAchDetailsRow> = (data, ctx) => {
  const hasMailingAddress = [
    data.mailingAddressLine1,
    data.mailingAddressLine2,
    data.mailingAddressCity,
    data.mailingAddressState,
    data.mailingAddressZip,
  ].some((field) => !isEmpty(field));

  if (!hasMailingAddress) return;

  const address = typedSafeParse(StrictAddress, {
    line1: data.mailingAddressLine1 ?? '',
    line2: data.mailingAddressLine2,
    line3: undefined,
    line4: undefined,
    locality: data.mailingAddressCity ?? '',
    region: data.mailingAddressState ?? '',
    postcode: data.mailingAddressZip ?? '',
    country: 'USA',
  });

  if (address.success) return;

  ctx.addIssue({
    code: z.ZodIssueCode.custom,
    path: ['loanId'],
    message: 'Mailing address is incomplete. Must be complete or omitted.',
  });
};

const checkAutopayDayOfMonth: z.SuperRefinement<UpdateBorrowerAchDetailsRow> = (data, ctx) => {
  const { autoDraftDate } = data;
  if (!autoDraftDate) return;

  if (autoDraftDate < 1 || autoDraftDate > 10)
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['autoDraftDate'],
      message: 'Auto draft date must be an integer between 1 and 10.',
    });
};

export const UpdateBorrowerAchDetailsRowRefinements = unsafeCreateCastedSchema(UpdateBorrowerAchDetailsRow)
  .superRefine(checkFullMailingAddress)
  .superRefine(checkAutopayDayOfMonth);

export const UPDATE_BORROWER_ACH_DETAILS_ROW_REQUIRED_FIELDS = Object.keys(UpdateBorrowerAchDetailsRow.shape).filter(
  (key) => !UpdateBorrowerAchDetailsRow.shape[key as keyof typeof UpdateBorrowerAchDetailsRow.shape].isOptional(),
);
