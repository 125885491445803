import { Opaque } from 'type-fest';

import { NonNegativeMonetaryValue } from './fields';

export type FormattedFlatfileMonetaryValue = Opaque<string, 'FormattedFlatfileMonetaryValue'>;
export type FormattedFlatfileDate = Opaque<string, 'FormattedFlatfileDate'>;
export type IsoDate = Opaque<string, 'IsoDate'>;

export const FlatfileMonetaryValueTransformer = NonNegativeMonetaryValue.transform(
  (value) => value.toFixed(2) as FormattedFlatfileMonetaryValue,
);
