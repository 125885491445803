import { useMutation, useQuery } from '@apollo/client';
import { AnimatePresence } from 'framer-motion';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { matchPath, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params';

import { LogoutDocument, PortalSelfDocument, PortalSelfQuery } from '@willow/graphql-iso/src/portal';
import { AppContext as AppContextEnv, timeout } from '@willow/shared-iso';
import {
  getPortalCss,
  NamedMemo,
  PORTAL_LOAN_BASE_ROUTE,
  PORTAL_LOAN_PATHS,
  PORTAL_LOAN_ROUTES,
  PortalLoanParams,
  useUniversalLoader,
} from '@willow/shared-web';
import { UnpackArray } from '@willow/types-iso';

import { AcceptTermsModal } from './components/accept-terms/AcceptTermsModal';
import { AppContext, EmptyPortalUser, PortalUser } from './components/context/AppContexts';
import { Header } from './components/header/header/Header';
import { InactiveUser } from './components/inactive-user/InactiveUser';
import { Layout } from './components/layout/Layout';
import { PageTransition } from './components/layout/page-transition/PageTransition';
import { setMetaDefaults } from './components/metadata/Defaults';
import { ViewingAs } from './components/viewing-as/ViewingAs';
import { useStyleCustomization } from './hooks/style-customization/useStyleCustomization';
import { useGetImpersonatedUserId } from './hooks/useGetImpersonatedUserId';
import { ApplicationsPage } from './pages/applications/Applications';
import { DocumentsPage } from './pages/documents/Documents';
import { FAQPage } from './pages/faq/FAQ';
import { LoanOverviewPage } from './pages/loan-overview/LoanOverview';
import { MakePaymentPage } from './pages/make-payment/MakePayment';
import { ManageLoanPage } from './pages/manage-loan/ManageLoan';
import { MyAccount } from './pages/my-account/MyAccount';
import { SelectLoanPage } from './pages/select-loan/SelectLoan';
import { TransactionsPage } from './pages/transactions/Transactions';

import '@willow/shared-web/scss/global.scss';
import '@willow/shared-web/scss/utilities.scss';
// TODO toast/typography are probably only things that need to be extracted
import './Global.scss';

export type PortalSelectedLoan = UnpackArray<PortalSelfQuery['portalSelf']['loans']>;

interface Props {
  appContextEnv: AppContextEnv;
}

export const App = NamedMemo<Props>('App', ({ appContextEnv }) => {
  const loader = useUniversalLoader();
  const location = useLocation();
  const history = useHistory();

  const [selectedLoan, setSelectedLoan] = useState<PortalSelectedLoan>();
  const [queryParams] = useQueryParams({
    userId: StringParam,
    adminImpersonate: BooleanParam,
  });
  const { loading, error, data, refetch, startPolling, stopPolling } = useQuery(PortalSelfDocument, {
    variables: {
      userId: useGetImpersonatedUserId(),
    },
  });

  const refetchLoan = useCallback(
    async (poll?: Boolean) => {
      if (!poll) return refetch();
      startPolling(1_000);
      await timeout(10_000);
      stopPolling();
    },
    [refetch, startPolling, stopPolling],
  );

  const [gqlLogout] = useMutation(LogoutDocument);

  useEffect(() => {
    if (error) {
      return history.replace('/');
    }
  }, [error, history]);

  useEffect(() => {
    // Scroll to top of page on route changes
    document.body.scrollTo({ top: 0 });
  }, [location.pathname]);

  const user = useMemo<PortalUser | EmptyPortalUser>(() => {
    if (data) {
      const {
        portalSelf: { id, borrowerId, email, phoneNumbers, firstName, lastName, acceptedTerms, isAdmin },
      } = data;
      return {
        id,
        borrowerId,
        email,
        phoneNumbers,
        firstName,
        lastName,
        acceptedTerms,
        isAdmin,
      } as PortalUser;
    }
    return {
      id: undefined,
      borrowerId: undefined,
      email: undefined,
      phoneNumbers: { home: undefined, work: undefined, cell: undefined },
      firstName: undefined,
      lastName: undefined,
      acceptedTerms: false,
      isAdmin: false,
    };
  }, [data]);

  const loans = useMemo(() => {
    if (data) {
      const {
        portalSelf: { loans },
      } = data;
      return loans ?? [];
    }
    return undefined;
  }, [data]);

  useEffect(() => {
    if (loans) loader.setLoading(false);
  }, [loans, loader]);

  const updateMetadata = useCallback((loan: PortalSelectedLoan | undefined) => {
    setMetaDefaults(loan?.company?.name || '');
  }, []);

  const handleLoanSwitch = useCallback(() => {
    setSelectedLoan(undefined);
    updateMetadata(undefined);
  }, [updateMetadata]);

  useEffect(() => {
    if (loans?.length === 1) {
      // If user only has a single loan, select that loan to display
      setSelectedLoan(loans[0]);
      updateMetadata(loans[0]);
    } else if (loans?.length) {
      const match = matchPath<PortalLoanParams>(location.pathname, {
        path: PORTAL_LOAN_BASE_ROUTE,
      });

      if (match?.params.losId) {
        const pathLoan = loans.find((loan) => {
          if (match.params.companyId) {
            return loan.losId === match.params.losId && loan.company.id === match.params.companyId;
          }
          return loan.losId === match.params.losId;
        });

        if (pathLoan) {
          setSelectedLoan(pathLoan);
          updateMetadata(pathLoan);
        }
      }
    }
  }, [loans, location.pathname, updateMetadata]);

  const { primaryColor, secondaryColor } = useStyleCustomization(selectedLoan?.company);

  return (
    <>
      <Helmet>{getPortalCss(primaryColor, secondaryColor)}</Helmet>

      <AppContext
        user={user}
        allCompanySettings={loans?.map((l) => l.company.adminSettings)}
        selectedLoan={selectedLoan}
        environment={appContextEnv}
      >
        {!loading && (
          <Header
            loan={selectedLoan}
            hideSideNav={Boolean(!loading && loans?.length && !selectedLoan)}
            logout={gqlLogout}
          />
        )}

        {!loading ? <AcceptTermsModal userId={user.id} acceptedTerms={user.acceptedTerms} /> : undefined}

        {selectedLoan ? (
          <Layout loan={selectedLoan} hasMultipleLoans={loans && loans.length > 1} onLoanSwitch={handleLoanSwitch}>
            {queryParams.adminImpersonate && <ViewingAs portalUser={data?.portalSelf} />}
            {/* AnimatePresence wrapper around the routers allows us to have page transitions (see framer motion docs) */}
            <AnimatePresence exitBeforeEnter={false}>
              <Switch location={location} key={location.pathname}>
                <Route path={PORTAL_LOAN_ROUTES.transactions}>
                  <PageTransition>
                    <TransactionsPage loan={selectedLoan} />
                  </PageTransition>
                </Route>
                <Route path={PORTAL_LOAN_ROUTES.documents}>
                  <PageTransition>
                    <DocumentsPage loan={selectedLoan} />
                  </PageTransition>
                </Route>
                <Route path={PORTAL_LOAN_ROUTES.faq}>
                  <PageTransition>
                    <FAQPage loan={selectedLoan} />
                  </PageTransition>
                </Route>
                <Route path={PORTAL_LOAN_ROUTES.loanOverview}>
                  <PageTransition>
                    <LoanOverviewPage loan={selectedLoan} company={selectedLoan.company} />
                  </PageTransition>
                </Route>
                <Route path={PORTAL_LOAN_ROUTES.manageLoan}>
                  <ManageLoanPage loan={selectedLoan} />
                </Route>
                {selectedLoan.company.adminSettings.applicationsEnabled && (
                  <Route path={PORTAL_LOAN_ROUTES.applications}>
                    <PageTransition>
                      <ApplicationsPage loan={selectedLoan} />
                    </PageTransition>
                  </Route>
                )}
                <Route path={PORTAL_LOAN_ROUTES.myAccount}>
                  <MyAccount loan={selectedLoan} user={user} company={selectedLoan.company} />
                </Route>
                <Route path={PORTAL_LOAN_BASE_ROUTE}>
                  <PageTransition>
                    {(user.acceptedTerms || queryParams.adminImpersonate) && (
                      <MakePaymentPage loan={selectedLoan} refetch={(poll) => refetchLoan(poll)} />
                    )}
                  </PageTransition>
                </Route>
                <Route path="*">
                  <PageTransition>
                    <Redirect
                      to={PORTAL_LOAN_PATHS.root({ losId: selectedLoan.losId, companyId: selectedLoan.company.id })}
                    />
                  </PageTransition>
                </Route>
              </Switch>
            </AnimatePresence>
          </Layout>
        ) : (
          <>{loans?.length ? <SelectLoanPage loans={loans} /> : <InactiveUser />}</>
        )}

        {/* All toast messages should be displayed using this single container instance so there aren't multiple containers in the dom */}
        <ToastContainer autoClose={10000} pauseOnHover />
      </AppContext>
    </>
  );
});
