import * as z from 'zod';

import { UserId } from '../BrandedIds';
import { AmortizationType } from '../loan/Loan';
import {
  AutoPaymentType,
  ConstructionStatus,
  EmailStatus,
  LoanReportingPaymentStatus,
  LoanReportingWorkoutPlanType,
} from '../loan/LoanReporting';
import { LoanStatus } from '../loan/LoanStatus';
import { NonPerformingReportStatus } from '../loan/NonPerformance';
import { DateFilter } from '../loanTransactionFilter/LoanTransactionFilter';
import { zodBrandedUuid } from '../utils';

export const StatusFilter = z.object({
  type: z.literal('enum'),
  key: z.literal('status'),
  filter: z.object({
    values: z.array(LoanStatus),
  }),
});
export type StatusFilter = z.infer<typeof StatusFilter>;

export const NonPerformingStatusFilter = z.object({
  type: z.literal('enum'),
  key: z.literal('nonPerformingStatus'),
  filter: z.object({
    values: z.array(NonPerformingReportStatus),
  }),
});
export type NonPerformingStatusFilter = z.infer<typeof NonPerformingStatusFilter>;

export const AutoPaymentTypeFilter = z.object({
  type: z.literal('enum'),
  key: z.literal('autoPaymentType'),
  filter: z.object({
    values: z.array(AutoPaymentType),
  }),
});
export type AutoPaymentTypeFilter = z.infer<typeof AutoPaymentTypeFilter>;

export const AutoPaymentDayOfMonthFilter = z.object({
  type: z.literal('enum'),
  key: z.literal('autoPaymentDayOfMonth'),
  filter: z.object({
    // 0 means no autopay, 1-15 and 25 are valid values
    values: z.array(z.coerce.number().min(0).max(25)),
  }),
});
export type AutoPaymentDayOfMonthFilter = z.infer<typeof AutoPaymentDayOfMonthFilter>;

export const PaymentFrequencyFilter = z.object({
  type: z.literal('enum'),
  key: z.literal('paymentFrequency'),
  filter: z.object({
    values: z.array(z.union([z.literal(1), z.literal(12)])),
  }),
});

export type PaymentFrequencyFilter = z.infer<typeof PaymentFrequencyFilter>;

export const PaymentStatusFilter = z.object({
  type: z.literal('enum'),
  key: z.literal('paymentStatus'),
  filter: z.object({
    values: z.array(LoanReportingPaymentStatus),
  }),
});
export type PaymentStatusFilter = z.infer<typeof PaymentStatusFilter>;

export const FlaggedFilter = z.object({
  type: z.literal('boolean'),
  key: z.literal('flagged'),
  filter: z.object({
    values: z.array(z.boolean()),
  }),
});
export type FlaggedFilter = z.infer<typeof FlaggedFilter>;

export const AmortizationTypeFilter = z.object({
  type: z.literal('enum'),
  key: z.literal('amortizationType'),
  filter: z.object({
    values: z.array(AmortizationType),
  }),
});
export type AmortizationTypeFilter = z.infer<typeof AmortizationTypeFilter>;

export const GoodbyeEmailStatusFilter = z.object({
  type: z.literal('enum'),
  key: z.literal('goodbyeEmailStatus'),
  filter: z.object({
    values: z.array(EmailStatus),
  }),
});
export type GoodbyeEmailStatusFilter = z.infer<typeof GoodbyeEmailStatusFilter>;

export const MortgageeEmailStatusFilter = z.object({
  type: z.literal('enum'),
  key: z.literal('mortgageeEmailStatus'),
  filter: z.object({
    values: z.array(EmailStatus),
  }),
});
export type MortgageeEmailStatusFilter = z.infer<typeof MortgageeEmailStatusFilter>;

export const ConstructionStatusFilter = z.object({
  type: z.literal('enum'),
  key: z.literal('constructionStatus'),
  filter: z.object({
    values: z.array(ConstructionStatus),
  }),
});
export type ConstructionStatusFilter = z.infer<typeof ConstructionStatusFilter>;

export const WorkoutPlanTypeFilter = z.object({
  type: z.literal('enum'),
  key: z.literal('workoutPlanType'),
  filter: z.object({
    values: z.array(LoanReportingWorkoutPlanType),
  }),
});

export type WorkoutPlanTypeFilter = z.infer<typeof WorkoutPlanTypeFilter>;

export const CreateDateRangeFilter = z.object({
  type: z.literal('date'),
  key: z.literal('createdAt'),
  filter: DateFilter,
});
export const TransferDateRangeFilter = z.object({
  type: z.literal('date'),
  key: z.literal('transferDate'),
  filter: DateFilter,
});
export const NextPaymentDateRangeFilter = z.object({
  type: z.literal('date'),
  key: z.literal('nextPaymentDate'),
  filter: DateFilter,
});
export const PaidOffDateRangeFilter = z.object({
  type: z.literal('date'),
  key: z.literal('paidOffDate'),
  filter: DateFilter,
});
export type DateRangeFilter = z.infer<
  | typeof CreateDateRangeFilter
  | typeof TransferDateRangeFilter
  | typeof NextPaymentDateRangeFilter
  | typeof PaidOffDateRangeFilter
>;

const PointOfContactIdFilter = z.object({
  type: z.literal('userId'),
  key: z.literal('pointOfContactId'),
  filter: z.object({
    values: z.array(z.union([zodBrandedUuid<UserId>(), z.literal('none')])),
  }),
});

export const LoanFilter = z.discriminatedUnion('key', [
  StatusFilter,
  NonPerformingStatusFilter,
  AutoPaymentTypeFilter,
  PaymentStatusFilter,
  FlaggedFilter,
  AmortizationTypeFilter,
  GoodbyeEmailStatusFilter,
  CreateDateRangeFilter,
  TransferDateRangeFilter,
  NextPaymentDateRangeFilter,
  PaidOffDateRangeFilter,
  MortgageeEmailStatusFilter,
  ConstructionStatusFilter,
  WorkoutPlanTypeFilter,
  AutoPaymentDayOfMonthFilter,
  PointOfContactIdFilter,
  PaymentFrequencyFilter,
]);
export type LoanFilter = z.infer<typeof LoanFilter>;

export const LoanDashboardColumnKey = z.union([
  z.literal('losId'),
  z.literal('name'),
  z.literal('address'),
  z.literal('status'),
  z.literal('nonPerformingStatus'),
  z.literal('flagged'),
  z.literal('interestRate'),
  z.literal('buydownRate'),
  z.literal('createdAt'),
  z.literal('productDescription'),
  z.literal('amortizationType'),
  z.literal('productType'),
  z.literal('originalAmount'),
  z.literal('escrowBalance'),
  z.literal('suspenseBalance'),
  z.literal('principalPrepaymentBalance'),
  z.literal('outstandingBalance'),
  z.literal('servicerName'),
  z.literal('transferDate'),
  z.literal('goodbyeEmailStatus'),
  z.literal('mortgageeEmailStatus'),
  z.literal('autoPaymentType'),
  z.literal('autoPaymentDayOfMonth'),
  z.literal('paymentStatus'),
  z.literal('lastStatementFulfilled'),
  z.literal('paymentFrequency'),
  z.literal('nextPaymentDate'),
  z.literal('paystring'),
  z.literal('paidOffDate'),
  z.literal('monthlyPaymentAmount'),
  z.literal('firstPaymentDueDate'),
  z.literal('remainingCredit'),
  z.literal('daysPastDue'),
  z.literal('daysPastGrace'),
  z.literal('reserveBalance'),
  z.literal('poolId'),
  z.literal('previousServicerId'),
  z.literal('capitalPartnerId'),
  z.literal('fullId'),
  z.literal('ownerId'),
  z.literal('fundingDate'),
  z.literal('maturityDate'),
  z.literal('loanAddressState'),
  z.literal('constructionStatus'),
  z.literal('workoutPlanType'),
  z.literal('entityName'),
  z.literal('otherId1'),
  z.literal('otherId2'),
  z.literal('otherId3'),
  z.literal('pointOfContactId'),
]);
export type LoanDashboardColumnKey = z.infer<typeof LoanDashboardColumnKey>;

export const LoanDashboardColumnKeys = z.array(LoanDashboardColumnKey);
export type LoanDashboardColumnKeys = z.infer<typeof LoanDashboardColumnKeys>;

export const LoanDashboardQueryByKey = z.union([z.literal('losIdOrName'), z.literal('address')]);
export type LoanDashboardQueryByKey = z.infer<typeof LoanDashboardQueryByKey>;

export const LoanDashboardQuery = z.object({
  query: z.string(),
  queryBy: LoanDashboardQueryByKey,
});
export type LoanDashboardQuery = z.infer<typeof LoanDashboardQuery>;
