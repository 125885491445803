import { startCase } from 'lodash';
import * as z from 'zod';

import { IndexRateType } from '../../indexRate';
import {
  ArmRoundingType,
  BuydownType,
  LoanInvestorName,
  LoanLateFeeType,
  PaymentFrequency,
  PrepaymentPenaltyPlanType,
} from '../../loan/Loan';
import { LoanStatusActive } from '../../loan/LoanStatus';
import { OptionalPhoneNumber } from '../../Phone';
import { OptionalEmailString } from '../email';
import {
  AddressLine,
  Checkbox,
  dateIsTodayOrInThePast,
  dateIsWithinRange,
  DateString,
  InterestRate,
  isValidPaymentDate,
  MonetaryValue,
  NonNegativeFloat,
  NonNegativeInteger,
  NonNegativeMonetaryValue,
  NonNegativePercentage,
  PersonNameString,
  SocialSecurityNumber,
  StateAbbreviation,
  Zipcode,
} from '../fields';
import { CreateLoanFields, CreateLoanMultipleAddressFields } from './createLoanFields';
import { BorrowerDetailFields, BorrowerPhoneFields } from './createLoanFields/BorrowerFields';
import { AddressFields } from './sharedFields/AddressFields';
import { MERSCreateFields } from './sharedFields/MERSImportFields';

// Reference these inputs in client, not preprocessed types below
export const AmortizationTypeInput = z.enum(['arm', 'fixed']);
export const LoanTypeInput = z.enum(['arm', 'fixed', 'heloc']);
export const EscrowWaivedInput = z.enum(['Waived', 'Not Waived']);
export const InterestTypeInput = z.enum(['30-360', 'actual-365', 'actual-360']);
export const ProductTypeInput = z.enum([
  'fha',
  'va',
  'usda',
  'pih',
  'conv',
  'heloc',
  'bridge', // FOR VONTIVE
  'businessPurpose', // FOR VONTIVE
  'jumbo',
]);
export const LoanPurposeInput = z.enum(['purchase', 'refinance']);
export const OccupancyTypeInput = z.enum(['primary', 'second', 'investment']);
export const LoanStatusInput = z.enum([LoanStatusActive.value]);

// These are only for use in this file
export const LoanTypeEnum = z.preprocess((val) => {
  if (typeof val === 'string') {
    return val.toLowerCase();
  }
  return val;
}, LoanTypeInput);
export const AmortizationTypeEnum = z.preprocess((val) => {
  if (typeof val === 'string') {
    return val.toLowerCase();
  }
  return val;
}, AmortizationTypeInput);
export const EscrowWaivedEnum = z.preprocess((val) => {
  if (typeof val === 'string') {
    return startCase(val.toLowerCase());
  }
  return val;
}, EscrowWaivedInput);
export const InterestTypeEnum = z.preprocess((val) => {
  if (typeof val === 'string') {
    return val.toLowerCase();
  }
  return val;
}, InterestTypeInput);

export const ProductTypeEnum = z.preprocess((val) => {
  if (typeof val === 'string') {
    if (val.toLowerCase() === 'businesspurpose') {
      return 'businessPurpose';
    }
    return val.toLowerCase();
  }
  return val;
}, ProductTypeInput);

export const LoanPurposeEnum = z.preprocess((val) => {
  if (typeof val === 'string') {
    return val.toLowerCase();
  }
  return val;
}, LoanPurposeInput);

export const OccupancyTypeEnum = z.preprocess((val) => {
  if (typeof val === 'string') {
    return val.toLowerCase();
  }
  return val;
}, OccupancyTypeInput);

export const ConstructionToPermanentStatusInput = z.enum(['active', 'permanent']);

export const ConstructionToPermanentStatusEnum = z.preprocess((val) => {
  if (typeof val === 'string') {
    return val.toLowerCase();
  }
  return val;
}, ConstructionToPermanentStatusInput);

export const ConstructionLoanTypeInput = z.enum(['constructionOnly', 'constructionToPermanent']);

export const ConstructionLoanTypeEnum = z.preprocess((val) => {
  if (typeof val === 'string') {
    if (val.toLowerCase() === 'constructiononly') {
      return 'constructionOnly';
    }
    if (val.toLowerCase() === 'constructiontopermanent') {
      return 'constructionToPermanent';
    }
  }
  return val;
}, ConstructionLoanTypeInput);

export const InvestorNameEnum = z.preprocess((val) => {
  if (typeof val === 'string') {
    val = val.toLowerCase().replace(/[_ ]/g, '');
  }
  switch (val) {
    case 'fanniemae':
      return LoanInvestorName.enum.FANNIE_MAE;
    case 'freddiemac':
      return LoanInvestorName.enum.FREDDIE_MAC;
    default:
      return val;
  }
}, LoanInvestorName);

export const NoteholderInput = z.enum(['investor', 'servicer', 'thirdParty']);
export const NoteholderEnum = z.preprocess((val) => {
  if (typeof val === 'number') {
    val = `${val}`;
  }
  if (typeof val === 'string') {
    val = val.toLowerCase();
  }

  switch (val) {
    case '0':
      return 'investor';
    case '1':
      return 'servicer';
    case '2':
    case 'thirdparty':
      return 'thirdParty';
    default:
      return val;
  }
}, NoteholderInput);

export const PaymentFrequencyEnum = z.preprocess((val) => {
  if (typeof val === 'number') {
    val = `${val}`;
  }
  if (typeof val === 'string') {
    val = val.toLowerCase();
  }

  switch (val) {
    case '1':
    case 'monthly':
      return 1;
    case '12':
    case 'annual':
      return 12;
    default:
      return val;
  }
}, PaymentFrequency);

// NOTE: If parsing data with CreateLoanRow, make sure
// you trim and strip the data beforehand with the `trimAndStrip` util
export const CreateLoanRow = z
  .object({
    loanId: z.string(),
    poolId: z.string().optional(),
    previousServicerId: z.string().optional(),
    capitalPartnerId: z.string().optional(),
    fullId: z.string().optional(),
    otherId1: z.string().optional(),
    otherId2: z.string().optional(),
    otherId3: z.string().optional(),
    ownerId: z.string().optional(),

    pointOfContact: OptionalEmailString.describe('Email address of internal user who is the primary point of contact'),

    fundingDate: DateString.and(dateIsTodayOrInThePast),
    isAcquired: Checkbox.optional(),
    acquisitionDate: DateString.optional(),

    brokerName: z.string().optional(),

    correspondentLender: z.string().optional().describe('Originating lender information'),
    correspondentLenderPhone: OptionalPhoneNumber.describe('Originating lender information'),
    correspondentLenderEmail: OptionalEmailString,

    loanMaturityDate: DateString.and(isValidPaymentDate),
    loanClosingDate: DateString.optional().describe('The date the loan closed'),
    incomingTransferEffectiveDate: DateString.optional(),
    loanTermMonths: NonNegativeInteger.optional(),
    loanTermYears: NonNegativeInteger.optional(),
    paymentFrequency: PaymentFrequencyEnum.optional(),
    loanType: LoanTypeEnum.optional(),
    amortizationType: AmortizationTypeEnum.optional(),
    productType: ProductTypeEnum.optional(),
    productDescription: z.string().optional(),
    loanPurpose: LoanPurposeEnum.optional(),
    constructionToPermanentStatus: ConstructionToPermanentStatusEnum.optional().openapi({
      description: 'Indicates if loan is in "active" construction or "permanent" status',
      default: 'active',
    }),
    constructionLoanType: ConstructionLoanTypeEnum.optional(),
    occupancyType: OccupancyTypeEnum.optional(),
    initialDiscountPointsAmount: NonNegativeMonetaryValue.optional().describe('Amount paid to purchase points'),
    ytdInterestPaid: NonNegativeMonetaryValue.optional(),
    legalDescription: z.string().optional(),
    numberOfProperties: NonNegativeInteger.optional().describe(
      'Number of properties the loan covers. Used for 1098 tax forms',
    ),
    create1098Form: Checkbox.optional().openapi({
      description: 'Default: true. Set to false to prevent 1098 from generating at year end',
      default: true,
    }),
    loanPrincipal: NonNegativeMonetaryValue.describe(
      'Starting principal balance. This value should exclude principal from  initial draws provided with the request.',
    ),
    loanInterestRateDecimal: InterestRate.optional().describe(
      'Must include either: loanInterestRateDecimal OR loanInterestRatePercent',
    ),
    loanInterestRatePercent: NonNegativePercentage.optional().describe(
      'Must include either: loanInterestRateDecimal OR loanInterestRatePercent',
    ),
    firstCollectedPaymentDate: DateString.and(isValidPaymentDate)
      .and(dateIsWithinRange({ years: 30 }))
      .optional()
      .describe(
        'Use this field if you are creating a loan with a firstPaymentDate in the past.\nThis is the date from which Willow will begin billing the consumer.\nIf you are retro-actively boarding a loan and you would like to collect all payments from the first payment date, simply set this to the same value as firstPaymentDate. \nIf you are boarding a loan that was repurchased, set this to the first date that you want to begin collecting payments.',
      ),
    minMonthlyPayment: NonNegativeMonetaryValue.optional(),
    currentOutstandingAmount: NonNegativeMonetaryValue.optional().describe(
      'Use this field if you are creating a loan with a firstPaymentDate in the past.\nIf any payments were already collected at the time of boarding, Willow must know the current outstanding principal balance of the loan.\nIf no payments were collected, simply set this to the same value as the loanPrincipal.',
    ),
    gracePeriod: NonNegativeInteger.optional().openapi({
      description:
        'The number of days that a loan can be past-due before the loan is considered late. Default: Global company setting, or 15 days.',
      example: 15,
    }),
    defaultInterestRateDecimal: NonNegativeFloat.optional(),
    defaultInterestRatePercent: NonNegativePercentage.optional(),
    defaultInterestEffectiveDate: DateString.optional(),
    lienPosition: NonNegativeInteger.optional(),
    noteDate: DateString.optional(),
    qualifiedMortgage: Checkbox.optional(),

    // primary borrower fields
    primaryBorrowerFirstName: PersonNameString,
    primaryBorrowerMiddleName: PersonNameString.optional(),
    primaryBorrowerLastName: PersonNameString,
    primaryBorrowerPhone: OptionalPhoneNumber, // home phone
    primaryBorrowerPhoneDetails: BorrowerPhoneFields.optional(),
    primaryBorrowerEmail: OptionalEmailString,
    primaryBorrowerEmailConsent: Checkbox.optional(),
    primaryBorrowerMailingAddressLine1: AddressLine.optional(),
    primaryBorrowerMailingAddressLine2: AddressLine.optional(),
    primaryBorrowerMailingAddressLine3: AddressLine.optional(),
    primaryBorrowerMailingAddressLine4: AddressLine.optional(),
    primaryBorrowerMailingAddressLocality: z.string().optional(),
    primaryBorrowerMailingAddressRegion: StateAbbreviation.optional().or(z.literal('')),
    primaryBorrowerMailingAddressPostcode: Zipcode.optional(),
    primaryBorrowerMailingAddressCountry: z.string().optional(),
    primaryBorrowerSSN: SocialSecurityNumber.optional(),
    primaryBorrowerDetails: BorrowerDetailFields.optional(),

    // investor info
    investorName: InvestorNameEnum.optional(),
    investorLoanId: z.string().optional(),
    investorMasterId: z.string().optional(),
    ginnieMaeLoanId: z.string().optional(),
    fhaCaseNumber: z.string().optional(),
    mers: MERSCreateFields.optional(),
    noteholder: NoteholderEnum.optional(),
    documentCustodian: z.string().optional(),

    // stop settings
    stopEmails: Checkbox.optional().openapi({
      description: 'If true, emails will not be sent to the borrowerfor the loan',
      example: true,
      default: false,
    }),
    stopPayments: Checkbox.optional().openapi({
      description: 'If true, payments will not be accepted for the loan',
      example: true,
      default: false,
    }),
    exactPaymentsOnly: Checkbox.optional(),

    // arm fields
    armIndex: IndexRateType.optional(),
    armFixedTermMonths: NonNegativeInteger.optional(),
    armFirstRateChangeDate: DateString.optional(),
    armMarginPercent: NonNegativePercentage.optional(),
    armMarginDecimal: NonNegativeFloat.optional(),
    armMaxIncreasePerPeriodPercent: NonNegativePercentage.optional(),
    armMaxIncreasePerPeriodDecimal: NonNegativeFloat.optional(),
    armMinIncreasePerPeriodPercent: NonNegativePercentage.optional(),
    armMinIncreasePerPeriodDecimal: NonNegativeFloat.optional(),
    armMaxIncreaseForFirstPeriodPercent: NonNegativePercentage.optional(),
    armMaxIncreaseForFirstPeriodDecimal: NonNegativeFloat.optional(),
    armMinIncreaseForFirstPeriodPercent: NonNegativePercentage.optional(),
    armMinIncreaseForFirstPeriodDecimal: NonNegativeFloat.optional(),
    armRateCeilingPercent: NonNegativePercentage.optional(),
    armRateCeilingDecimal: NonNegativeFloat.optional(),
    armRateFloorPercent: NonNegativePercentage.optional(),
    armRateFloorDecimal: NonNegativeFloat.optional(),
    armRateAdjustmentFrequency: NonNegativeInteger.optional().openapi({
      description: 'The number of months between rate adjustments',
      example: 6,
    }),
    armAdjustmentLeadCountDays: NonNegativeInteger.optional(),
    armRoundingType: ArmRoundingType.optional(),
    armRoundingPercent: NonNegativePercentage.optional(),
    armRoundingDecimal: NonNegativeFloat.optional(),

    // interest only fields
    interestOnlyTermMonths: NonNegativeInteger.optional(),
    interestOnlyTermYears: NonNegativeInteger.optional(),
    interestOnlyFlag: Checkbox.optional(),
    interestOnlyEndDate: DateString.optional(),

    // THIS IS AN OLD TODO: make `interestType` required but first need to reach out to loansnap
    interestType: InterestTypeEnum.optional(),
    firstPaymentPrincipal: NonNegativeMonetaryValue.optional().describe(
      'Must include either: firstPaymentPrincipalAndInterest OR firstPaymentPrincipal AND firstPaymentInterest',
    ),
    firstPaymentInterest: NonNegativeMonetaryValue.optional().describe(
      'Must include either: firstPaymentPrincipalAndInterest OR firstPaymentPrincipal AND firstPaymentInterest',
    ),
    firstPaymentPrincipalAndInterest: NonNegativeMonetaryValue.optional().describe(
      'Must include either: firstPaymentPrincipalAndInterest OR firstPaymentPrincipal AND firstPaymentInterest',
    ),
    firstPaymentTotal: NonNegativeMonetaryValue.optional().describe('P&I payment + escrow payment'),
    isEscrowed: Checkbox.optional(),
    escrowWaived: EscrowWaivedEnum.optional(),
    monthlyPaymentEscrow: NonNegativeMonetaryValue.optional().describe(
      'Note: This amount should include MI if applicable',
    ),
    firstPaymentDate: DateString.and(isValidPaymentDate).and(dateIsWithinRange({ years: 30 })),
    initialEscrowBalance: MonetaryValue.optional(),
    initialInterestBalance: MonetaryValue.optional().describe(
      'Required when either condition is met:\ncurrentOutstandingAmount > 0\nOR\nfirstCollectedPaymentDate > firstPaymentDate',
    ),
    initialHoldbackBalance: MonetaryValue.optional(),
    initialPrincipalPrepaymentBalance: NonNegativeMonetaryValue.optional(),
    initialInterestReserveBalance: NonNegativeMonetaryValue.optional(), // BUYDOWN TODO: Remove when finally deprecated
    initialMortgageInsuranceAmount: NonNegativeMonetaryValue.optional(),
    initialReserveBalance: NonNegativeMonetaryValue.optional(),
    buydownType: BuydownType.optional(),
    reserveBalanceFundedByThirdParty: Checkbox.optional(),
    isInterestReserve: Checkbox.optional(),
    isPendingActivation: Checkbox.optional().describe(
      'Boarding a loan as pending activation will prevent Willow from sending out communications to borrowers or accepting payments until the loan status is updated to active via the app or API',
    ),
    isDutch: Checkbox.optional(),

    // Draw fields
    drawsEnabled: Checkbox.optional(),
    maxLineOfCredit: NonNegativeMonetaryValue.optional(),
    drawTerm: NonNegativeInteger.optional().describe('Number of months draws are allowed'),
    drawExpirationDate: DateString.optional().describe('After this date, the ability to record a draw will be frozen'),
    repayBeginDate: DateString.optional().describe('The date when a loan starts amortizing'),
    draw1Date: DateString.optional().describe('Draw date prior to boarding in Willow.'),
    draw1Amount: NonNegativeMonetaryValue.optional().describe('Draw amount prior to boarding in Willow.'),
    draw2Date: DateString.optional().describe('Second draw date prior to boarding in Willow.'),
    draw2Amount: NonNegativeMonetaryValue.optional().describe('Second draw amount prior to boarding in Willow.'),
    draw3Date: DateString.optional().describe('Third draw date prior to boarding in Willow.'),
    draw3Amount: NonNegativeMonetaryValue.optional().describe('Third draw amount prior to boarding in Willow.'),

    // Entity fields
    entityCompanyId: z.string().optional(),
    entityName: z
      .string()
      .optional()
      .describe(
        'Provide entity fields if the loan belongs to an entity instead of, or in addition to, an individual.\nAll fields are required when either field is present: entityCompanyId OR entityName',
      ),
    entityEin: z.string().optional(),
    entityPhone: OptionalPhoneNumber,
    entityEmail: OptionalEmailString,
    entityAddressLine1: AddressLine.optional(),
    entityAddressLine2: AddressLine.optional(),
    entityAddressLocality: z.string().optional(),
    entityAddressRegion: StateAbbreviation.optional().or(z.literal('')),
    entityAddressPostcode: Zipcode.optional(),
    entityAddressCountry: z.string().optional(),

    // escrow fields
    escrow1Type: z.string().optional(),
    escrow1AgentName: z.string().optional(),
    escrow1AgentEmail: OptionalEmailString,
    escrow1AgentPhone: OptionalPhoneNumber,
    escrow1CompanyName: z.string().optional(),
    escrow1CompanyAddressLine1: AddressLine.optional(),
    escrow1CompanyAddressLine2: AddressLine.optional(),
    escrow1CompanyAddressCity: z.string().optional(),
    escrow1CompanyAddressState: StateAbbreviation.optional().or(z.literal('')),
    escrow1CompanyAddressZip: Zipcode.optional(),
    escrow1PolicyNumber: z.string().optional(),
    escrow1PolicyStart: DateString.optional(),
    escrow1PolicyEnd: DateString.optional(),
    escrow2Type: z.string().optional(),
    escrow2AgentName: z.string().optional(),
    escrow2AgentEmail: OptionalEmailString,
    escrow2AgentPhone: OptionalPhoneNumber,
    escrow2CompanyName: z.string().optional(),
    escrow2CompanyAddressLine1: AddressLine.optional(),
    escrow2CompanyAddressLine2: AddressLine.optional(),
    escrow2CompanyAddressCity: z.string().optional(),
    escrow2CompanyAddressState: StateAbbreviation.optional().or(z.literal('')),
    escrow2CompanyAddressZip: Zipcode.optional(),
    escrow2PolicyNumber: z.string().optional(),
    escrow2PolicyStart: DateString.optional(),
    escrow2PolicyEnd: DateString.optional(),
    escrow3Type: z.string().optional(),
    escrow3AgentName: z.string().optional(),
    escrow3AgentEmail: OptionalEmailString,
    escrow3AgentPhone: OptionalPhoneNumber,
    escrow3CompanyName: z.string().optional(),
    escrow3CompanyAddressLine1: AddressLine.optional(),
    escrow3CompanyAddressLine2: AddressLine.optional(),
    escrow3CompanyAddressCity: z.string().optional(),
    escrow3CompanyAddressState: StateAbbreviation.optional().or(z.literal('')),
    escrow3CompanyAddressZip: Zipcode.optional(),
    escrow3PolicyNumber: z.string().optional(),
    escrow3PolicyStart: DateString.optional(),
    escrow3PolicyEnd: DateString.optional(),
    monthlyPaymentMi: NonNegativeMonetaryValue.optional(), // TODO deprecate this from FF and the API
    beginningOfYearPrincipalBalance: NonNegativeMonetaryValue.optional().describe(
      'A loan that has been active since the beginning of the year should include this number for tax reporting. If it’s missing, the workflow to create a Form 1098 will flag it for review.',
    ),

    // fee fields
    lateFeePercent: NonNegativePercentage.optional(),
    lateFeeDecimal: NonNegativeFloat.optional(),
    lateFeeMin: NonNegativeMonetaryValue.optional(),
    lateFeeMax: NonNegativeMonetaryValue.optional(),
    lateFeeType: LoanLateFeeType.optional(),
    prepaymentPenaltyFlag: Checkbox.optional(),
    prepaymentPenaltyExpirationMonths: NonNegativeInteger.optional(),
    prepaymentPenaltyPlanDescription: z.string().optional(),
    prepaymentPenaltyPlanType: PrepaymentPenaltyPlanType.optional(),

    // NB spreading here avoids some "excessively deep and possibly infinite"
    // type instantiation errors. Zod's `.merge` does effectively the same
    // thing internally, but by spreading ourselves we avoid the redundant
    // evaluation of merged types that leads to the error.
    ...CreateLoanFields.shape,
    ...AddressFields.shape,
    ...CreateLoanMultipleAddressFields.shape,
  })
  .strict();
export type CreateLoanRow = z.infer<typeof CreateLoanRow>;

export const CREATE_LOAN_ROW_REQUIRED_FIELDS = Object.keys(CreateLoanRow.shape).filter(
  (key) => !CreateLoanRow.shape[key as keyof typeof CreateLoanRow.shape].isOptional(),
);
