import { z } from 'zod';

import { BankAccountNumber, BankRoutingNumber } from './BankAccount';

export const RepayPayAccountInput = z.object({
  accountNumber: BankAccountNumber,
  routingNumber: BankRoutingNumber,
  accountType: z.enum(['checking', 'savings']),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
});

export type RepayPayAccountInput = z.infer<typeof RepayPayAccountInput>;
