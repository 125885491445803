import { useMutation } from '@apollo/client';
import axios from 'axios';
import { useCallback } from 'react';

import { GetS3PresigneUrlPublicUploadDocument } from '@willow/graphql-iso/src/app';
import { CompanyId } from '@willow/types-iso';

import { Sentry } from '../../..';

interface UseS3UploaderOptions {
  customFilenameWithExtension?: string;
  headers?: Record<string, string>;
}

interface UseS3UploaderProps {
  getPresignedUrl: (file: File, options?: UseS3UploaderOptions) => Promise<{ signedUrl: string; destination: string }>;
}

export const useS3Uploader = ({ getPresignedUrl }: UseS3UploaderProps) => {
  const uploadToS3 = useCallback(async (signedUrl: string, file: File, options?: UseS3UploaderOptions) => {
    const customHeaders = options?.headers || {};
    const headers = {
      'Content-Type': file.type,
      ...customHeaders,
    };

    return axios.put(signedUrl, file, { headers });
  }, []);

  const upload = useCallback(
    async (file: File, options?: UseS3UploaderOptions) => {
      try {
        // 1. Get signedUrl
        const { signedUrl, destination } = await getPresignedUrl(file, options);

        // 2. Upload to S3
        await uploadToS3(signedUrl, file, options);

        return { destination };
      } catch (error) {
        Sentry.captureException(error);
        return { error };
      }
    },
    [getPresignedUrl, uploadToS3],
  );

  return { upload };
};

export const usePublicS3Uploader = (companyId: CompanyId) => {
  const [getPresignedUrl] = useMutation(GetS3PresigneUrlPublicUploadDocument);

  const getPublicS3SigningUrl = useCallback(
    async (file: File, options?: UseS3UploaderOptions) => {
      const fileName = options?.customFilenameWithExtension || file.name;

      return getPresignedUrl({
        variables: {
          companyId,
          fileName,
          fileType: file.type,
        },
      }).then(({ data, errors }) => {
        // Throw if GQL had an error.
        // TODO: If GQL errors throw already, remove this block.
        if (!data || errors) {
          throw new Error(JSON.stringify(errors));
        }

        // Get the presigned URL and destination
        const {
          getS3PresigneUrlPublicUpload: { presignedUploadUrl, destination },
        } = data;

        return {
          signedUrl: presignedUploadUrl,
          destination,
        };
      });
    },
    [getPresignedUrl, companyId],
  );

  return useS3Uploader({ getPresignedUrl: getPublicS3SigningUrl });
};
