import * as z from 'zod';

import { TeamId } from '../BrandedIds';
import { User } from '../User';
import { zodBrandedUuid, zodDateOrString } from '../utils';
import { TeamPermissions } from './TeamPermissions';

export const Team = z.object({
  id: zodBrandedUuid<TeamId>(),
  name: z.string(),
  permissions: TeamPermissions,
  users: User.array(),
  createdAt: zodDateOrString,
  updatedAt: zodDateOrString,
  deletedAt: zodDateOrString.optional(),

  lastUpdatedBy: User,
});
export type Team = z.infer<typeof Team>;
