import { z } from 'zod';

import { EscrowDueId, EscrowSettingId, PropertyId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';
import { DateString, MonetaryValue, NonNegativeMonetaryValue } from '../validations/fields';
import { EscrowSettingStatus, TaxEscrowType } from './EscrowSetting';

export const BaseEscrowDue = z.object({
  id: zodBrandedUuid<EscrowDueId>(),
  propertyId: zodBrandedUuid<PropertyId>(),
  escrowSettingId: zodBrandedUuid<EscrowSettingId>(),
  dueDate: zodDateOrString,
  estimatedAmount: NonNegativeMonetaryValue,
  amountPaid: NonNegativeMonetaryValue,
  escrowed: z.boolean(),
  fulfilled: z.boolean(),
  status: EscrowSettingStatus,
});

export const TaxEscrowDue = BaseEscrowDue.extend({
  type: z.literal('tax'),
  taxType: TaxEscrowType,
});

export type TaxEscrowDue = z.infer<typeof TaxEscrowDue>;

export const InsuranceEscrowDue = BaseEscrowDue.extend({
  type: z.literal('insurance'),
  insuranceName: z.string().optional(),
});

export type InsuranceEscrowDue = z.infer<typeof InsuranceEscrowDue>;

export const MortgageInsuranceEscrowDue = BaseEscrowDue.extend({
  type: z.literal('mortgageInsurance'),
});

export type MortgageInsuranceEscrowDue = z.infer<typeof MortgageInsuranceEscrowDue>;

export const EscrowDue = z.discriminatedUnion('type', [TaxEscrowDue, InsuranceEscrowDue, MortgageInsuranceEscrowDue]);
export type EscrowDue = z.infer<typeof EscrowDue>;

export function isTax(item: EscrowDue): item is TaxEscrowDue {
  return item.type === 'tax';
}

export function isInsurance(item: EscrowDue): item is InsuranceEscrowDue {
  return item.type === 'insurance';
}

export function isMortgageInsurance(item: EscrowDue): item is MortgageInsuranceEscrowDue {
  return item.type === 'mortgageInsurance';
}

export const EscrowDueInput = BaseEscrowDue.pick({ id: true, amountPaid: true, fulfilled: true });

export type EscrowDueInput = z.infer<typeof EscrowDueInput>;

// ESCROW ANALYSIS

export const EscrowPayOutItem = z.object({
  name: z.string(),
  dueDate: DateString,
  amount: MonetaryValue,
  estimated: z.boolean(),
  isCushioned: z.boolean().optional(), // Make required
  addressLine1: z.string().optional(),
});
export type EscrowPayOutItem = z.infer<typeof EscrowPayOutItem>;

export const EscrowPayInItem = z.object({
  name: z.literal('Deposit'),
  amount: MonetaryValue,
  estimated: z.boolean(),
});

export type EscrowPayInItem = z.infer<typeof EscrowPayInItem>;

export const EscrowBalanceLine = z.object({
  date: DateString.optional(),
  payInItems: EscrowPayInItem.array(),
  actualPayInItems: EscrowPayInItem.array(),
  payOut: MonetaryValue,
  actualPayOut: MonetaryValue,
  balance: MonetaryValue,
  actualBalance: MonetaryValue,
  payOutItems: EscrowPayOutItem.array(),
  actualPayOutItems: EscrowPayOutItem.array(),
});
export type EscrowBalanceLine = z.infer<typeof EscrowBalanceLine>;

export const InternalEscrowAnalysis = z.object({
  projectedEscrowDues: EscrowPayOutItem.array(),
  annualTotal: NonNegativeMonetaryValue,
  monthlyPaymentTotal: NonNegativeMonetaryValue,
  cushionAmountTotal: NonNegativeMonetaryValue,
  initialTrial: EscrowBalanceLine.array(),
  adjustedTrial: EscrowBalanceLine.array(),
  cushionTrial: EscrowBalanceLine.array(),
  shortageOrOverage: MonetaryValue,
  oldMonthlyPaymentTotal: MonetaryValue,
  monthlyPaymentTotalWithSpread: NonNegativeMonetaryValue,
  history: EscrowBalanceLine.array(),
});

export type InternalEscrowAnalysis = z.infer<typeof InternalEscrowAnalysis>;
