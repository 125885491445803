import { z } from 'zod';

import { zodDateOrString } from '../utils';
import { BaseLoanEvent } from './BaseLoanEvent';

export const LegacyFromAccount = z.enum(['suspense', 'holdback']);
export const LegacyToAccount = z.enum(['escrow', 'holdback', 'suspense', 'principal']);

export const FromAccount = z.enum(['holdback']);
export const ToAccount = z.enum(['escrow', 'holdback', 'principal']);

export const CreateBalanceReallocationEvent = BaseLoanEvent.extend({
  type: z.literal('createBalanceReallocation'),
  fromAccount: FromAccount.or(LegacyFromAccount),
  toAccount: ToAccount.or(LegacyToAccount),
  total: z.number(),
  date: zodDateOrString,
  comment: z.string().optional(),
});
export type CreateBalanceReallocationEvent = z.infer<typeof CreateBalanceReallocationEvent>;
